import React from 'react'
import { ContextWrapper } from '../ContextWrapper'
import PageItem from './../components/PageItem/'

class UI extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <ContextWrapper>
        <div className="sss">
          {this.props.components &&
            this.props.components.map((component, index) => {
              return <PageItem key={index} {...component} />
            })}
        </div>
      </ContextWrapper>
    )
  }
}

export default UI
