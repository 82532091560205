import { Col, Row } from 'antd'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import s from './style.module.scss'

class Showoff extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { centerImage, leftFBUrl, rightFBUrl, title, centerText, leftImage, leftText, rightImage, rightText } =
      this.props
    return (
      <div className={cx(s.root)}>
        <div className="container">
          <Row>
            <Col span={2}></Col>
            <Col span={20}>
              <Row>
                <Col span={24}>
                  <div className={s.centerImage}>
                    {centerImage && <img className={s.image} src={centerImage[0].url} />}
                  </div>
                  <div className={s.centerContent}>
                    <h3 className={s.heading}>{title}</h3>
                    {centerText && (
                      <p className={cx(s.text, s.centerText)} dangerouslySetInnerHTML={{ __html: centerText }} />
                    )}
                    {leftFBUrl && (
                      <a className={cx(s.icon, 'f-facebook-icon')} href={leftFBUrl} target="_blank">
                        f
                      </a>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24} md={11}>
                  <div className={s.imageContainer}>
                    {leftImage && <img className={s.image} src={leftImage[0].url} />}
                  </div>
                  {leftText && <p className={cx(s.text, s.innerText)} dangerouslySetInnerHTML={{ __html: leftText }} />}
                </Col>
                <Col span={24} md={1}>
                  {(leftText && rightText) || (leftImage && rightImage) ? <div className={s.cardDivider}></div> : null}
                </Col>
                <Col span={24} md={11}>
                  <div className={s.imageContainer}>
                    {rightImage && <img className={s.image} src={rightImage[0].url} />}
                  </div>
                  {rightText && (
                    <p className={cx(s.text, s.innerText)} dangerouslySetInnerHTML={{ __html: rightText }} />
                  )}
                  {rightFBUrl && (
                    <div className={s.iconContainer}>
                      <a className={'f-facebook-icon'} href={rightFBUrl} target="_blank">
                        f
                      </a>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

Showoff.defaultProps = {
  title: 'Showoffing default title',
}

Showoff.propTypes = {
  title: PropTypes.string,
}

export default Showoff
