import { Col, DatePicker, Form, Input, Modal, notification, Row, Table } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { ContextWrapper } from '../ContextWrapper'
import __ from './../components/Common'
import ListIndex from './../components/ListIndex'

const { Column, ColumnGroup } = Table

class GuardianIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bookouts: null,
      modal: false,
      bookingId: null,
      submitting: false,
      event: null,
      startDate: null,
      endDate: null,
    }

    this.generateProfileList = this.generateProfileList.bind(this)
    this.bookoutCb = this.bookoutCb.bind(this)
    this.disabledDate = this.disabledDate.bind(this)
  }

  componentDidMount() {
    this.setState({
      bookouts: this.props.bookouts,
    })
  }

  resetForm() {
    this.setState({
      modal: false,
      bookingId: null,
      event: null,
      startDate: null,
      startDateMoment: null,
      endDate: null,
    })
  }

  handleOk = (e) => {
    const paths = this.props.paths
    this.setState({
      submitting: true,
    })
    const config = {
      headers: __.getHeaders(),
    }

    const body = {
      event: this.state.event,
      date_start: this.state.startDate,
      date_end: this.state.endDate,
      profile_id: this.state.bookingId,
    }

    if (body.event && body.date_start && body.date_end && body.profile_id) {
      axios
        .post(`/bookouts.json`, body, config)
        .then(
          (response) => {
            notification.open({ message: 'Bookout date saved.' })
            window.location.reload()
          },
          (err) => {
            this.resetForm()
            throw new Error(err.message)
          }
        )
        .catch((err) => {
          this.resetForm()
          throw new Error(err.message)
        })
    }
  }

  handleCancel = (e) => {
    this.resetForm()
  }

  bookoutCb(profileId) {
    this.setState({
      modal: true,
      bookingId: profileId,
    })
  }

  formUpdate(key, val) {
    this.setState({
      [key]: val,
    })
  }

  updateDate(date, num) {
    console.log('date: ', date)
    if (num == 0) {
      this.setState({
        startDate: date.toDate(),
      })
    } else if (num == 1) {
      this.setState({
        endDate: date.toDate(),
      })
    }
  }

  disabledDate(current) {
    return current && current.toDate() < this.state.startDate
  }

  getTableData() {
    const data = []
    if (this.state.bookingId) {
      let chosen = this.state.bookouts.filter((booking) => {
        return booking.profile_id === this.state.bookingId
      })
      if (chosen && chosen.length > 0) {
        chosen.forEach((booking) => {
          data.push({
            key: booking.id,
            event: booking.event,
            startDate: moment(booking.date_start).format('MMMM DD YYYY'),
            endDate: moment(booking.date_end).format('MMMM DD YYYY'),
          })
        })
      }
    }
    return data
  }

  deleteBooking(bookingId) {
    const config = {
      headers: __.getHeaders(),
    }

    axios
      .delete(`/bookouts/${bookingId}.json`, config)
      .then(
        (response) => {
          let newArray = this.state.bookouts.filter(function (booking) {
            return booking.id !== bookingId
          })
          this.setState({
            bookouts: newArray,
          })
          this.resetForm()
        },
        (err) => {
          this.resetForm()
          throw new Error(err.message)
        }
      )
      .catch((err) => {
        this.resetForm()
        throw new Error(err.message)
      })
  }

  render() {
    const tableData = this.getTableData()
    let notifications = this.generateNotifications()
    let profiles = this.generateProfileList()
    return (
      <ContextWrapper>
        <div style={{ marginTop: '55px' }} className="container">
          <Modal
            title="Create Bookout"
            visible={this.state.modal}
            onOk={this.state.submitting ? null : this.handleOk}
            onCancel={this.handleCancel}
            okText="Submit"
            cancelText="Cancel"
          >
            <Form layout="horizontal">
              <Form.Item label="Event">
                <Input
                  value={this.state.event ? this.state.event : ''}
                  placeholder="Describe Event"
                  onChange={(e) => this.formUpdate('event', e.target.value)}
                />
              </Form.Item>
              <Row>
                <Col span={12}>
                  <Form.Item label="Select Start Date">
                    <DatePicker onChange={(e) => this.updateDate(e, 0)} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Select End Date">
                    <DatePicker
                      onChange={(e) => this.updateDate(e, 1)}
                      disabled={!this.state.startDate}
                      disabledDate={this.disabledDate}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            {tableData.length > 0 ? (
              <div>
                <h4>Current Bookings: </h4>
                <Table dataSource={tableData}>
                  <Column title="Event" dataIndex="event" key="event" />
                  <Column title="Start Date" dataIndex="startDate" key="startDate" />
                  <Column title="End Date" dataIndex="endDate" key="endDate" />
                  <Column
                    title=""
                    key="action"
                    render={(text, record) => <a onClick={() => this.deleteBooking(record.key)}>Delete</a>}
                  />
                </Table>
              </div>
            ) : null}
          </Modal>

          <h1 className="noMargin">
            Hello {this.props.current_user && this.props.current_user.first_name && this.props.current_user.first_name}
            <br />
          </h1>
          <br />
          <Row gutter={16} type="flex" align="middle" justify="space-between">
            <Col span={8}>
              <a href={`${this.props.paths.editUser}`}>Guardian Account Settings</a>
            </Col>
            <Col span={6}>
              <div className="member-status">
                <i className="icon-info-triangle" style={{ fontSize: '20px' }}></i> &nbsp;&nbsp;&nbsp;{' '}
                <span>{`Member Since ${moment(this.props.current_user.created_at).format('MMMM DD, YYYY')}`}</span>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <p>
                This is your personal Guardian Account with Minor Details. This is how you will communicate with the
                agency. You can change stats, special interests, photographs, resumes etc. Minor Details has final say
                on approval of all changes you make. You'll receive an email and a notification on your My Account page
                regarding all changes. It is important to check in often to see if there are notifications pending on
                your account. Please note that each Guardian Account is designed to be for one family. Please do not
                submit children that are not your own with your login as they will appear as siblings on the website.{' '}
                <br />
                <br />
              </p>
              <p>
                It is essential that you input exact measurements when registering your child. To do this, we suggest
                you use a 'ribbon' measuring tape. These can be purchased at most corner stores, grocery stores or
                dollar stores. <br />
                <br />
              </p>
              <p>
                Make sure you press 'submit' at the end of the registration form for all changes and new applicants.
                Your child's name will be listed under Profiles on your My Account page, and it will say 'profile
                pending approval' under Notifications.
              </p>
            </Col>
          </Row>

          <div className="list-container">
            <ListIndex title="Profiles" closed={this.props.closed} list={profiles} />
          </div>
          <div className="page-divider"></div>
          <Row>
            {this.props.closed && (
              <Col>
                <p className="red-alert">
                  <br />
                  Registration is closed for new talent - we need some time to get through the applications.
                </p>
              </Col>
            )}
            <Col xs={20} sm={4}>
              <a
                style={{ marginTop: '15px' }}
                disabled={this.props.closed}
                href={`${this.props.paths.newProfile}`}
                className={`button secondary ${this.props.closed ? 'disabled-btn' : ''}`}
              >
                + add child
              </a>
            </Col>
          </Row>
          <br />
          <br />
          <div className="list-container">
            <ListIndex title="Notifications" list={notifications} />
          </div>
          <div className="page-divider"></div>
          {/* <div className="dashed-line"></div> */}
          <Row>
            <Col xs={20} sm={5} md={4}>
              <a style={{ marginTop: '30px' }} target="_blank" href="/Voucher.pdf" className="button">
                print voucher
              </a>
            </Col>
            { this.props.profileApproved && (
              <Col xs={20} sm={5} md={4} style={{ marginLeft: '20px' }}>
                <a style={{ marginTop: '30px' }} href="/tutorial_videos" className="button">
                  tutorials
                </a>
              </Col>
            )}
          </Row>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </ContextWrapper>
    )
  }

  generateNotifications() {
    let arr = []
    this.props.child_profiles.forEach((profile) => {
      if (profile.draft && profile.draft.event == 'update') {
        arr.push({
          name: '',
          id: profile.id,
          subtext: `${profile.first_name} ${profile.last_name.charAt(0)}'s profile updates are pending approval`,
        })
      } else if (moment(profile.updated_at).isAfter(moment().subtract(7, 'd'))) {
        if (profile.last_draft_approval == 'approved') {
          arr.push({
            name: '',
            id: profile.id,
            subtext: `${profile.first_name} ${profile.last_name.charAt(0)}'s profile updates were approved`,
          })
        } else if (profile.last_draft_approval == 'denied') {
          arr.push({
            name: '',
            id: profile.id,
            subtext: `${profile.first_name} ${profile.last_name.charAt(0)}'s profile updates were denied`,
          })
        }
      }

      if (profile.approved == 'approved') {
        if (moment(profile.updated_at).isAfter(moment().subtract(7, 'd'))) {
          arr.push({
            name: '',
            id: profile.id,
            subtext: `${profile.first_name} ${profile.last_name.charAt(0)}'s profile was approved on ${moment(
              profile.approval_time
            ).format('MMM DD')}`,
          })
        }
      } else if (profile.approved == 'denied') {
        if (moment(profile.updated_at).isAfter(moment().subtract(7, 'd'))) {
          arr.push({
            name: '',
            id: profile.id,
            subtext: `${profile.first_name} ${profile.last_name.charAt(0)}'s profile was denied`,
          })
        }
      } else {
        arr.push({
          name: '',
          id: profile.id,
          subtext: `${profile.first_name} ${profile.last_name.charAt(0)}'s profile approval pending`,
        })
      }

      if (profile.bookouts && profile.bookouts.length >= 1) {
        const bookouts = _.cloneDeep(profile.bookouts).reverse()
        bookouts.forEach((bookout) => {
          if (moment(bookout.date_end).isAfter(new Date())) {
            arr.push({
              name: '',
              id: profile.id,
              subtext: `Bookout date for ${profile.first_name} ${profile.last_name.charAt(0)}. ${moment(
                bookout.date_start
              ).format('MMM DD')} to ${moment(bookout.date_end).format('MMM DD')}. Event: ${bookout.event}.`,
            })
          }
        })
      }
    })
    if (arr.length >= 1) {
      return arr
    } else {
      return [
        {
          name: '',
          id: '',
          subtext: 'You currently have no notifications',
        },
      ]
    }
  }

  generateProfileList() {
    let arr = []
    if (this.props.child_profiles && this.props.child_profiles.length > 0) {
      this.props.child_profiles.forEach((profile) => {
        const name =
          profile.nick_name && profile.primary === 't'
            ? `${profile.first_name} / ${profile.nick_name} ${profile.last_name.charAt(0)}.`
            : `${profile.first_name} ${profile.last_name.charAt(0)}.`
        let links = [
          {
            name: 'Edit Profile',
            path: `profiles/${profile.id}/edit`,
          },
          {
            name: 'Add Pictures',
            path: `profiles/${profile.id}/edit?upload=true`,
          },
        ]
        if (profile.approved == 'approved') {
          links.push({
            name: 'Bookout Dates',
            callback: this.bookoutCb,
          })
        }
        if (profile.approved == 'unset') {
          links.push({
            name: '',
            path: '#',
          })
        }
        arr.push({
          name: name,
          id: profile.id,
          links: links,
        })
      })
    } else {
      arr = [
        {
          name: '',
          id: '',
          subtext: 'You currently have no profiles',
        },
      ]
    }

    return arr
  }
}

GuardianIndex.defaultProps = {
  title: 'Registration container title',
}

GuardianIndex.propTypes = {
  title: PropTypes.string,
}

export default GuardianIndex
