import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import logo from './logo.svg'
import s from './style.module.scss'

class Logo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div className={cx(s.root, this.props.className)}>
        <img src={logo} alt="Minor Details Logo" />
      </div>
    )
  }
}

Logo.defaultProps = {
  title: 'Testing default title',
}

Logo.propTypes = {
  title: PropTypes.string,
}

export default Logo
