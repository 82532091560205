import axios from 'axios'
import _ from 'lodash'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
const moment = extendMoment(Moment)
require('moment-precise-range-plugin')

const humanize = (str) => {
  return _.capitalize(_.trim(_.snakeCase(str).replace(/_id$/, '').replace(/_/g, ' ')))
}

const stringifyIfObject = (object) => {
  if (object && typeof object == 'object') {
    return readableJSON(JSON.stringify(object))
  } else if (object && typeof object == 'string') {
    return readableJSON(object)
  } else if (typeof object == 'boolean') {
    return String(object)
  } else {
    return object
  }
}

const arrayContainsArray = (superset, subset) => {
  return subset.every(function (value) {
    return superset.indexOf(value) >= 0
  })
}

const parseIfString = (v) => {
  return typeof v === 'string' && (v[0] === '[' || v[0] === '{') ? JSONparse(v) : v
}

const readableJSON = (jsonString) => {
  let update = jsonString && typeof jsonString == 'object' ? JSON.stringify(jsonString) : jsonString
  update = update
    .replace(/[\[\]']/g, '')
    .replace(/{|}/g, '')
    .replace(/"/g, '')
  let m = update.split(',').map((item, index) => {
    if (item.includes(':')) {
      return item
        .split(':')
        .map((subItem, index2) => {
          return _.startCase(subItem)
        })
        .join(': ')
    } else {
      return item
    }
  })
  return m.join(', ')
}

const hasActra = (profile) => {
  return (
    profile.actra_present &&
    (!_.isEmpty(profile.actra_apprentice_number) || !_.isEmpty(profile.actra_apprentice_full_number))
  )
}

const getParameterByName = (name, url) => {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const JSONparse = (str) => {
  let a = ''
  try {
    a = JSON.parse(str)
  } catch (e) {
    a = str
    console.log('The following string could not be parsed : ' + str)
  }
  return a
}

const cleanArray = (arr) => {
  let filtered = arr.filter(function (n) {
    return n != null && n != undefined
  })
  return filtered
}

const deepCapitalizeAndStringify = (original, exceptions) => {
  const deepCapitalize = (original, exceptions) => {
    for (var key in original) {
      exceptions = exceptions === undefined ? [key] : exceptions
      typeof original[key] == 'object'
        ? deepCapitalize(original[key], exceptions)
        : _.includes(exceptions, key)
        ? (original[key] = _.capitalize(original[key]))
        : false
    }
  }
  let _original = _.cloneDeep(original)
  deepCapitalize(_original, exceptions)
  return JSON.stringify(_original)
}

const displayAge = (age) => {
  const momentAge = moment(age)
  const currentTime = moment(new Date())
  if (currentTime.diff(momentAge, 'months') <= 35) {
    // if(currentTime.year() - momentAge.year() <= 3){
    let start = Number(momentAge.format('MM')) + Number(momentAge.format('YYYY')) * 12
    let end = Number(currentTime.format('MM')) + Number(currentTime.format('YYYY')) * 12
    return `${end - start} months`
  } else {
    var diff = moment.preciseDiff(momentAge, currentTime)
    let arr = diff.split(' ')
    let years = arr.splice(0, 2)
    return years.join(' ')
  }
}

const profileName = (profile) => {
  let first = profile.nick_name && profile.primary === '1' ? profile.nick_name : profile.first_name
  return `${first} ${profile.last_name.charAt(0)}`
}

const displayFullAge = (age) => {
  const momentAge = moment(age)
  const currentDate = new Date()
  const currentTime = moment(
    `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`,
    'M/D/YYYY'
  )
  const formattedAge = moment(`${momentAge.month() + 1}/${momentAge.date()}/${momentAge.year()}`, 'M/D/YYYY')
  const range = moment.range(formattedAge, currentTime)
  var diff = moment.preciseDiff(formattedAge, currentTime)

  const arr = diff.split(' ')
  if (_.includes(arr, 'hours') || _.includes(arr, 'hour')) {
    arr.splice(-2, 2)
  }
  return arr.join(' ')
}

const ageInDays = (age) => {
  const a = moment(age)
  const b = moment(new Date())
  return b.diff(a, 'days')
}

const ageInMonths = (age) => {
  const a = moment(age)
  const b = moment(new Date())
  return b.diff(a, 'months')
}

const ageInYears = (age) => {
  const a = moment(age)
  const b = moment(new Date())
  return b.diff(a, 'years')
}

const ageUnderThree = (y, m, d) => {
  if (d && m && y) {
    const day = d < 10 ? '0' + d : d
    const month = m < 10 ? '0' + m : m
    const year = y
    return __.ageInMonths(`${year}${month}${day}`) < 36
  }
  return false
}

const handleMethodClick = (event, url, method, query = false) => {
  event.preventDefault()
  const requestPath = url
  const requestMethod = method
  console.log('event, url, method, query = false: ', event, url, method, query)
  const config = {
    headers: __.getHeaders(),
  }
  if (query) {
    let r = confirm(query)
    if (r) {
      axios[requestMethod](requestPath, config)
        .then(
          (response) => {
            window.location.reload()
          },
          (err) => {
            throw new Error(err.message)
          }
        )
        .catch((err) => {
          throw new Error(err.message)
        })
    } else {
      console.log('cancelled!')
    }
  } else {
    axios[requestMethod](requestPath, config)
      .then(
        (response) => {
          window.location.reload()
        },
        (err) => {
          throw new Error(err.message)
        }
      )
      .catch((err) => {
        throw new Error(err.message)
      })
  }
}

const getHeaders = () => {
  const meta = document.querySelector('meta[name="csrf-token"]')
  return {
    'X-CSRF-Token': meta.content,
    'Content-Type': 'application/json',
  }
}

const deepDiff = (obj1, obj2) => {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key)
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key)
      result.splice(resultKeyIndex, 1)
    }
    return result
  }, Object.keys(obj2))
  return diff
}
const sentenceCase = (str) => {
  return str.replace(/.+?[\.\?\!](\s|$)/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

const sizeOrder = [
  'Newborn',
  '0-3 months',
  '3-6 months',
  '6-9 months',
  '9-12 months',
  '12 months',
  '12-18 months',
  '18 months',
  '18-24 months',
  '2X',
  '3',
  '3X',
  '4',
  '5',
  '6',
  '6X',
  '7/8',
  '10',
  '10-12',
  '12',
  '12/14',
  '16',
  'Ladies 0',
  'Ladies 2',
  'Ladies 4',
  'Ladies 6',
  'Ladies 8',
  'Ladies 10',
  'Ladies 12',
  'Men',
]

const __ = {
  deepDiff,
  profileName,
  displayAge,
  displayFullAge,
  sentenceCase,
  hasActra,
  arrayContainsArray,
  cleanArray,
  deepCapitalizeAndStringify,
  ageInDays,
  ageInMonths,
  ageInYears,
  getHeaders,
  handleMethodClick,
  humanize,
  stringifyIfObject,
  parseIfString,
  readableJSON,
  JSONparse,
  ageUnderThree,
  getParameterByName,
  sizeOrder,
}

export default __
