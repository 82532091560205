import { Checkbox, Col, Icon, Modal, notification, Row } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { GoogleMap, InfoWindow, Marker, withGoogleMap } from 'react-google-maps'
import { ContextWrapper } from '../ContextWrapper'
import __ from './../components/Common'
import DataTable from './../components/DataTable'

const COLORS = {
  pink: '#EE8ADA',
  yellow: '#F0E869',
  orange: '#F59D5B',
  lavender: '#E4B6F4',
  green: '#AED56A',
  babyBlue: '#7CC9FA',
  turquoise: '#B4FCF8',
}
const STATUS_FLAG_OPTIONS = {
  selfTapeRequest: {
    name: 'Self-tape Request',
    color: COLORS.pink,
  },
  babyToCall: {
    name: 'Baby to Call',
    color: COLORS.yellow,
  },
  acceptedNeedsHelen: {
    name: 'Accepted, Needs Helen',
    color: COLORS.orange,
  },
  acceptedUsingOwnPhotographer: {
    name: 'Accepted, Using own photographer',
    color: COLORS.lavender,
  },
  acceptedInvoice: {
    name: 'Accepted, Invoice',
    color: COLORS.green,
  },
  turned3: {
    name: 'Turned 3',
    color: COLORS.babyBlue,
  },
  currentTalent: {
    name: 'Current Talent',
    color: COLORS.turquoise,
  },
}

const InfoPanel = ({ info }) => {
  const otherParents = __.parseIfString(info.other_parents)
  let preferredPostalCode = _.find(otherParents, function (o) {
    return o.preferred === true
  })['postalCode']

  return (
    <div>
      <strong>Name:</strong> {info.first_name} {info.last_name} <br />
      <strong>Date of Birth:</strong> {moment(info.date_of_birth).format('MMM DD, YYYY')} <br />
      <strong>Postal Code:</strong> {otherParents.length > 1 ? preferredPostalCode : otherParents[0]['postalCode']}{' '}
      <br />
      <a target="_blank" href={`/profiles/${info.id}`}>
        Profile
      </a>
    </div>
  )
}

const ProfileComboGoogleMap = withGoogleMap((props) => (
  <GoogleMap
    ref={props.onMapLoad}
    defaultZoom={11}
    defaultCenter={{ lat: props.lat, lng: props.lang }}
    onClick={props.onMapClick}
    defaultOptions={{
      draggable: true,
      scrollwheel: true,
      streetViewControl: false,
      mapTypeControl: false,
      zoomControl: true,
    }}
  >
    {props.markers &&
      props.markers.length >= 1 &&
      props.markers.map((profileMarker, index) => {
        if (profileMarker && profileMarker.preferred_parent_lat && profileMarker.preferred_parent_long) {
          return (
            <Marker
              onClick={() => props.onMarkerClick(profileMarker)}
              key={`marker-${index}`}
              title={profileMarker.first_name}
              position={{ lat: profileMarker.preferred_parent_lat, lng: profileMarker.preferred_parent_long }}
            >
              {props.infoVisible && props.clickedId === profileMarker.id && (
                <InfoWindow>
                  <InfoPanel info={profileMarker} />
                </InfoWindow>
              )}
            </Marker>
          )
        }
      })}
  </GoogleMap>
))

class ProfileCombo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      totalCols: [],
      selectedCols: [],
      modalVisible: false,
      selectedProfiles: [],
      displayAllRows: false,
      clickedId: null,
      infoVisible: false,
      profileNotes: {},
      profileStatusFlags: {},
    }
    this.generateProfileTableData = this.generateProfileTableData.bind(this)
    this.handleMapLoad = this.handleMapLoad.bind(this)
    this.handleMarkerClick = this.handleMarkerClick.bind(this)
    this.defaultSorted = this.defaultSorted.bind(this)
  }

  componentDidMount() {
    // columns that won't be preselected:
    const nonDefaults = [
      'hair_texture',
      'baby_skills',
      'special_skills',
      'performance_skills',
      'experience',
      'about',
      'updated_at',
      'images',
      'approver_id',
      'submission_count',
      'missing_teeth',
      'has_twin',
      'twin_type',
      'allergies',
      'modeledFor',
      'hand_images',
      'hair_length',
      'special_needs',
      'parent_id',
      'rating',
      'citizenship',
      'audio_files',
    ]

    // remove nonDefaults from column props
    const defaultColumns = this.props.defaultColumns
      ? this.props.defaultColumns
      : _.difference(this.props.columns, nonDefaults)

    // additional columns (not on profile model) but with useful data to display:
    const additionalColumns = [
      'expiry_date',
      'postalCode',
      'city',
      'chest',
      'height',
      'inseam',
      'napeToAnkle',
      'napeToCrotch',
      'napeToWrist',
      'shoeSize',
      'size',
      'waist',
      'weight',
      'province',
      'headshotLastUpdated',
      'hasSiblings',
      'deleteUser',
      'connections',
    ]

    // clone propped columns, combine with additional and set total into state
    let profileCols = _.cloneDeep(this.props.columns)
    const totalCols = profileCols.concat(additionalColumns).sort()
    const profileNotes = this.props.profiles.reduce(
      (all, profile) => ({ ...all, [profile.id]: profile.notes ?? '' }),
      {}
    )
    const profileStatusFlags = this.props.profiles.reduce(
      (all, profile) => ({ ...all, [profile.id]: profile.status_flag ?? '' }),
      {}
    )

    this.setState({
      selectedCols: defaultColumns,
      totalCols: totalCols,
      profileNotes: profileNotes,
      profileStatusFlags,
    })
  }

  /***********
  MAP METHODS:
  ***********/

  handleMapLoad(map) {
    this._mapComponent = map
    if (map) {
      console.log('there is a map')
    }
  }

  handleMarkerClick(markerData) {
    let visible = this.state.infoVisible
    this.setState({
      infoVisible: !visible,
      clickedId: markerData.id,
    })
  }

  /***********
  MODAL METHODS:
  ***********/

  showModal = () => {
    this.setState({
      modalVisible: true,
    })
  }

  handleOk = (e) => {
    this.setState({
      modalVisible: false,
    })
  }

  handleCancel = (e) => {
    this.setState({
      modalVisible: false,
    })
  }

  changeSelection = (checked, column) => {
    if (column != 'id') {
      let _columns = _.clone(this.state.selectedCols)
      if (checked) {
        _columns.push(column)
      } else {
        _columns = _.difference(_columns, [column])
      }
      this.setState({
        selectedCols: _columns,
      })
    }
  }

  writeNote = (e, id) => {
    // update a profile note
    const _profileNotes = _.cloneDeep(this.state.profileNotes)
    _profileNotes[id] = e.target.value
    this.setState({
      profileNotes: _profileNotes,
    })
  }

  updateNote = (id) => {
    // post api call to update a profile note
    const body = {}
    const note = this.state.profileNotes[id]
    if (id && note) {
      const config = {
        headers: __.getHeaders(),
      }
      notification.open({
        message: 'Submitting',
        description: 'Submitting your updates.',
        icon: <Icon type="warning" style={{ color: 'red' }} />,
      })
      body.notes = note.trim()
      axios
        .post(`/admins/${this.props.current_user.id}/profile_notes/${id}.json`, body, config)
        .then(
          (response) => {
            notification.open({
              message: 'Success',
              description: 'Profile note has been updated.',
              icon: <Icon type="smile-circle" style={{ color: 'green' }} />,
            })
          },
          (err) => {
            notification.open({
              message: 'Error',
              description: 'Opps something went wrong. Please try again in a bit.',
              icon: <Icon type="warning" style={{ color: 'red' }} />,
            })
            throw new Error(err.message)
          }
        )
        .catch((err) => {
          notification.open({
            message: 'Error',
            description: 'Opps something went wrong. Please try again in a bit.',
            icon: <Icon type="warning" style={{ color: 'red' }} />,
          })
          throw new Error(err.message)
        })
    }
  }

  updateStatusFlag = (newStatus, id) => {
    axios
      .post(
        `/admins/${this.props.current_user.id}/set_profile_status_flag/${id}.json`,
        { status_flag: newStatus },
        {
          headers: __.getHeaders(),
        }
      )
      .then(() => {
        this.setState({
          profileStatusFlags: {
            ...this.state.profileStatusFlags,
            [id]: newStatus,
          },
        })
      })
      .catch((err) => {
        notification.open({
          message: 'Error',
          description: 'Opps something went wrong. Please try again in a bit.',
          icon: <Icon type="warning" style={{ color: 'red' }} />,
        })
        throw new Error(err.message)
      })
  }

  /***********
  TABLE METHODS:
  ***********/

  generateProfileTableData(index) {
    let stats
    // loop over selected columns to construct table
    let newColumns = this.state.selectedCols.map((item, index) => {
      switch (item) {
        case 'id':
          return {
            Header: 'Profile #',
            accessor: item,
            Cell: (row) => (
              <a className="admin-link" target="_blank" href={`/profiles/${row.value}`}>
                {row.value}
              </a>
            ),
            sortMethod: (a, b) => {
              return b > a ? 1 : -1
            },
          }
        case 'date_of_birth':
          return {
            Header: 'Age',
            accessor: item,
            sortMethod: (a, b) => {
              let aSafe =
                a.props &&
                a.props.children &&
                a.props.children[0] &&
                a.props.children[0].props &&
                a.props.children[0].props.children
              let bSafe =
                b.props &&
                b.props.children &&
                b.props.children[0] &&
                b.props.children[0].props &&
                b.props.children[0].props.children
              if (aSafe && bSafe) {
                a = a.props.children[0].props.children
                b = b.props.children[0].props.children
                return b > a ? 1 : -1
              }
            },
          }
        case 'notes':
          return {
            Header: 'Notes',
            accessor: item,
            width: 350,
          }
        case 'status_flag':
          return {
            Header: 'Status Flag',
            accessor: item,
            width: 220,
          }
        case 'waist':
          return {
            Header: 'Waist',
            accessor: item,
            sortMethod: (a, b) => {
              a = Number(a)
              b = Number(b)
              return b > a ? 1 : -1
            },
          }
        case 'weight':
          return {
            Header: 'Weight',
            accessor: item,
            sortMethod: (a, b) => {
              a = Number(a)
              b = Number(b)
              return b > a ? 1 : -1
            },
          }
        case 'updated_at':
          return {
            Header: 'Profile Updated at',
            accessor: item,
            sortMethod: (a, b) => {
              a = new Date(a).getTime()
              b = new Date(b).getTime()
              return b > a ? 1 : -1
            },
          }
        case 'stats_updated_at':
          return {
            Header: 'Stats Updated at',
            accessor: item,
            sortMethod: (a, b) => {
              a = new Date(a).getTime()
              b = new Date(b).getTime()
              return b > a ? 1 : -1
            },
          }
        case 'expiry_date':
          return {
            Header: 'Expiry Date',
            accessor: item,
            sortMethod: (a, b) => {
              a = new Date(a).getTime()
              b = new Date(b).getTime()
              return b > a ? 1 : -1
            },
          }
        case 'headshotLastUpdated':
          return {
            Header: 'Photos Updated',
            accessor: item,
            sortMethod: (a, b) => {
              a = new Date(a).getTime()
              b = new Date(b).getTime()
              return b > a ? 1 : -1
            },
          }
        case 'connections':
          return {
            Header: 'Connections',
            accessor: item,
            Cell: (row) => (
              <>
                {row.value?.map((id) => (
                  <span key={id}>
                    <a className="admin-link" target="_blank" href={`/profiles/${id}`}>
                      {id}
                    </a>
                    <br />
                  </span>
                ))}
              </>
            ),
            sortMethod: (a, b) => (a?.length > b?.length ? 1 : -1),
          }
        case 'created_at':
          return {
            Header: 'Date Created',
            accessor: item,
            sortMethod: (a, b) => {
              a = new Date(a).getTime()
              b = new Date(b).getTime()
              return b > a ? 1 : -1
            },
          }
        case 'approved':
          return {
            Header: 'Visible on Site',
            accessor: item,
          }
        case 'deleteUser':
          return {
            Header: 'Delete User',
            accessor: 'profile_no',
            Cell: (row) => (
              <div>
                <a
                  onClick={(e) =>
                    __.handleMethodClick(
                      e,
                      `/profiles/${row.value}.json`,
                      'delete',
                      'Are you sure you want to delete this profile? All associated bookouts, profile images, and message_histories will also be deleted.'
                    )
                  }
                >
                  Delete
                </a>
              </div>
            ),
          }
        default:
          return {
            Header: _.startCase(item),
            accessor: item,
          }
      }
    })

    // loop over profiles to generate rows in table.
    // some rows require data to be formatted in specific ways in switch statement
    let newRows = this.props.profiles.map((profile, index) => {
      let userObj = {}
      this.state.selectedCols.forEach((column, index) => {
        switch (column) {
          case 'headshotLastUpdated':
            let images = __.parseIfString(profile.images)
            let lastUpdated
            if (
              images.length > 0 &&
              images.find((item, index) => {
                return item.preferred == true
              })
            ) {
              lastUpdated = moment(
                images.find((item, index) => {
                  return item.preferred == true
                }).created
              )
            } else {
              images.forEach((image, index) => {
                if (image.created) {
                  if (moment.isMoment(lastUpdated)) {
                    lastUpdated = moment(image.created).isAfter(lastUpdated) ? moment(image.created) : lastUpdated
                  } else {
                    lastUpdated = moment(image.created)
                  }
                }
              })
            }
            userObj[column] = lastUpdated ? lastUpdated.format('MMM DD, YYYY') : ''
            break
          case 'connections':
            userObj['connections'] = profile.related_profile_ids
            break
          case 'deleteUser':
            userObj['profile_no'] = profile.id
            break
          case 'notes':
            userObj[column] = (
              <div style={{ width: '100%' }}>
                <input
                  style={{ width: '250px' }}
                  type="text"
                  value={this.state.profileNotes[profile.id]}
                  onChange={(e) => this.writeNote(e, profile.id)}
                />
                <button style={{ padding: '2px', marginLeft: '10px' }} onClick={() => this.updateNote(profile.id)}>
                  update note
                </button>
              </div>
            )
            break
          case 'status_flag':
            userObj[column] = (
              <div style={{ width: '100%', marginTop: '3px' }}>
                <select
                  style={{ width: '210px', height: '24px' }}
                  value={this.state.profileStatusFlags[profile.id]}
                  onChange={(e) => this.updateStatusFlag(e.target.value, profile.id)}
                >
                  <option value="">-- None --</option>
                  {Object.entries(STATUS_FLAG_OPTIONS).map(([key, { name }]) => (
                    <option value={key} key={key}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            )
            break
          case 'date_of_birth':
            userObj[column] = profile[column] ? (
              <div>
                <span style={{ display: 'none' }}>{__.ageInDays(profile[column])}</span>
                {__.displayFullAge(profile[column])}
              </div>
            ) : (
              ''
            )
            break
          case 'created_at':
          case 'updated_at':
          case 'stats_updated_at':
          case 'expiry_date':
            userObj[column] = profile[column] ? moment(profile[column]).format('MMM DD, YYYY HH:mm') : ''
            break
          case 'hasSiblings':
            userObj[column] =
              this.props.profiles.filter((item, index) => {
                return item.parent_id == profile.parent_id
              }).length > 1
                ? 'true'
                : 'false'
            break
          case 'baby_skills':
          case 'family_origin':
          case 'ethnicity':
            userObj[column] = profile[column] ? __.parseIfString(profile[column]).sort().join(', ') : ''
            break
          case 'languages':
          case 'special_skills':
          case 'performance_skills':
          case 'special_needs':
            userObj[column] = profile[column]
              ? Object.keys(__.parseIfString(profile[column]))
                  .map((subItem, index) => {
                    return `${_.startCase(subItem.toLowerCase())}${
                      __.parseIfString(profile[column])[subItem] || __.parseIfString(profile[column])[subItem] == 0
                        ? `${__.parseIfString(profile[column])[subItem]}`
                        : ''
                    }`
                  })
                  .join(', ')
              : ''
            break
          case 'hand_images':
          case 'audio_files':
            userObj[column] = profile[column] && __.parseIfString(profile[column]).length >= 1 ? 'true' : 'false'
            break
          case 'dual_citizen':
          case 'rating':
          case 'missing_teeth':
            userObj[column] = String(profile[column])
            break
          case 'has_braces':
            userObj[column] = String(profile[column])
            break
          case 'dual_citizenship':
            userObj[column] = _.isEmpty(profile[column]) ? 'false' : profile[column]
            break
          case 'approved':
            userObj[column] = profile[column] ? (profile[column] == 'approved' ? 'true' : 'false') : 'null'
            break
          case 'postalCode':
          case 'city':
          case 'province':
            let otherParents = __.parseIfString(profile['other_parents'])
            let preferred = _.find(otherParents, function (item) {
              return item.preferred === true
            })
            if (otherParents && otherParents.length >= 1) {
              try {
                userObj[column] = preferred && preferred[column] ? preferred[column] : otherParents[0][column]
              } catch (err) {
                console.log(err)
              }
            } else {
              userObj[column] = 'null'
            }
            break
          case 'height':
          case 'weight':
          case 'size':
          case 'inseam':
          case 'shoeSize':
          case 'waist':
          case 'napeToWrist':
          case 'napeToAnkle':
          case 'chest':
          case 'napeToCrotch':
            stats = __.parseIfString(profile['stats'])
            if (column == 'height') {
              userObj[column] = `${stats[column].ft} ft, ${stats[column].inches} inches`
            } else {
              userObj[column] = stats ? stats[column] : ''
            }
            break
          case 'actra_present':
            userObj[column] = __.hasActra(profile) ? 'true' : 'false'
            break
          case 'has_twin':
            userObj[column] = profile[column] ? 'true' : 'false'
            break
          case 'gender':
            if (profile[column].toLowerCase() == 'male') {
              userObj[column] = 'M'
            } else if (profile[column].toLowerCase() == 'female') {
              userObj[column] = 'F'
            }
            break
          default:
            userObj[column] = profile[column]
            break
        }
      })

      return userObj
    })
    // console.log('newRows: ', newRows)
    return [newColumns, newRows][index]
  }

  displayAll = () => {
    let current = this.state.displayAllRows
    this.setState({
      displayAllRows: !current,
    })
  }

  defaultSorted() {
    // determine if rows should be sorted by first name or created_at
    let sort
    if (this.props.defaultSort) {
      let defaultSort = this.props.defaultSort
      switch (defaultSort) {
        case 'created_at':
          sort = {
            id: 'created_at',
            desc: true,
          }

          break
        default:
          sort = {
            id: 'first_name',
            desc: false,
          }
          break
      }
    } else {
      sort = {
        id: 'first_name',
        desc: false,
      }
    }
    return [sort]
  }

  render() {
    const { current_user, profiles, drafts, title, text, showMap } = this.props
    // Toronto coordinates:
    const mapLatitude = '43.6414111'
    const mapLongitude = '-79.3776512'

    let rows = this.generateProfileTableData(1)
    let columns = this.generateProfileTableData(0)
    // console.log(columns)
    return (
      <ContextWrapper>
        <div>
          <div className="currentUserInfo">
            <p>
              <strong>
                Hello {current_user.first_name} {current_user.last_name}!
              </strong>
              <br />
              <br />
              {text}
            </p>
          </div>
          <br />
          <br />
          {profiles && (
            <div>
              <Row type="flex" justify="space-between">
                <Col>
                  <h2 className="admin">{title}</h2>
                </Col>
                <Col>
                  <a style={{ marginRight: '10px' }} href={this.props.paths.newProfile} target="_blank">
                    New Profile
                  </a>
                  <a style={{ marginRight: '10px' }} onClick={this.displayAll}>
                    {this.state.displayAllRows ? 'Display Limited Row' : 'Display All Rows'}
                  </a>
                  <a onClick={this.showModal}>Change Search Fields</a>
                </Col>
              </Row>
              <Modal
                title="Basic Modal"
                visible={this.state.modalVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okText="OK"
                cancelText="Cancel"
              >
                <p>Customize Search Fields: </p>
                {this.state.totalCols &&
                  this.state.totalCols.map((column, index) => {
                    return (
                      <div key={`check-${index}`}>
                        <Checkbox
                          checked={this.state.selectedCols.includes(column)}
                          disabled={column === 'id'}
                          onChange={(e) => this.changeSelection(e.target.checked, column)}
                        >
                          {_.startCase(column)}
                        </Checkbox>
                        <br />
                      </div>
                    )
                  })}
              </Modal>
              <DataTable
                defaultPageSize={this.state.displayAllRows ? rows.length : 15}
                onSortedChange={(sorted) => console.log(sorted)}
                defaultSorted={this.defaultSorted()}
                columns={columns}
                rows={rows}
                getTrProps={(_state, rowInfo, _instance) => {
                  const statusFlag = rowInfo?.row.id ? this.state.profileStatusFlags[rowInfo.row.id] : null
                  if (!statusFlag) return {}

                  return {
                    style: {
                      background: STATUS_FLAG_OPTIONS[statusFlag].color,
                    },
                  }
                }}
                filterable={true}
                children={(finalState, makeTable) => {
                  let dataIds = finalState.sortedData.map((item, index) => {
                    return item.id
                  })
                  let selectedProfiles = this.props.profiles.map((profile, index) => {
                    if (dataIds.includes(profile.id)) {
                      return profile
                    }
                  })
                  return (
                    <div>
                      <div>{makeTable()}</div>
                      <div>
                        {showMap && (
                          <div>
                            <ProfileComboGoogleMap
                              containerElement={<div style={{ height: `600px` }} />}
                              mapElement={<div style={{ height: `100%`, position: 'inherit' }} />}
                              onMapLoad={this.handleMapLoad}
                              lat={Number(mapLatitude)}
                              infoVisible={this.state.infoVisible}
                              clickedId={this.state.clickedId}
                              lang={Number(mapLongitude)}
                              markers={selectedProfiles}
                              onMarkerClick={this.handleMarkerClick}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )
                }}
              />
            </div>
          )}
        </div>
      </ContextWrapper>
    )
  }
}

export default ProfileCombo
