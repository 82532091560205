import { Button, Col, DatePicker, Form, Icon, Input, notification, Radio, Row, Select, Table } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import __ from '../components/Common'
import ProfileCompare from './../components/ProfileCompare'
import WysiwygInput from '../components/WysiwygInput'
import { RelatedProfilesForm } from '../components/RelatedProfilesForm/RelatedProfilesForm'
import { ContextWrapper } from '../ContextWrapper'

const { Column } = Table

class ProfileAdmin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      approved: null,
      expiry_date: null,
      preselectedMsg: null,
      approvalEmailMsg: null,
      updateApprovalMsg: null,
      preselectedUpdateMsg: null,
      updates: null,
      xhr: false,
    }
    this.onInputChange = this.onInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount() {
    this.setState({
      approved: this.props.profile.approved ? this.props.profile.approved : 'unset',
      expiry_date:
        this.props.profile && this.props.profile.expiry_date ? moment(this.props.profile.expiry_date).toDate() : null,
    })
  }

  setExpiryDate = (e) => {
    e.preventDefault()
    const meta = document.querySelector('meta[name="csrf-token"]')
    const paths = this.props.paths
    const body = {}
    const config = {
      headers: {
        'X-CSRF-Token': meta.content,
        'Content-Type': 'application/json',
      },
    }
    notification.open({
      message: 'Submitting',
      description: 'Submitting your updates.',
      icon: <Icon type="warning" style={{ color: 'red' }} />,
    })
    if (this.state.expiry_date) {
      body.expiry_date = this.state.expiry_date

      this.setState({ xhr: true }, () => {
        axios
          .post(`/admins/${this.props.current_user.id}/set_expiry/${this.props.profile.id}.json`, body, config)
          .then(
            (response) => {
              this.setState({ xhr: false })
              notification.open({
                message: 'Success',
                description: 'Your updates have been saved. Please wait for the page to reload.',
                icon: <Icon type="smile-circle" style={{ color: 'green' }} />,
              })
              setTimeout(() => {
                window.location.reload()
              }, 500)
            },
            (err) => {
              this.setState({ xhr: false })
              notification.open({
                message: 'Error',
                description: 'Opps something went wrong. Please try again in a bit.',
                icon: <Icon type="warning" style={{ color: 'red' }} />,
              })
              throw new Error(err.message)
            }
          )
          .catch((err) => {
            this.setState({ xhr: false })
            notification.open({
              message: 'Error',
              description: 'Opps something went wrong. Please try again in a bit.',
              icon: <Icon type="warning" style={{ color: 'red' }} />,
            })
            throw new Error(err.message)
          })
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const meta = document.querySelector('meta[name="csrf-token"]')
    const paths = this.props.paths
    const config = {
      headers: {
        'X-CSRF-Token': meta.content,
        'Content-Type': 'application/json',
      },
    }
    const body = {
      approved: this.state.approved,
    }
    if (this.state.expiry_date) {
      body.expiry_date = this.state.expiry_date
    }
    if (this.state.approvalEmailMsg) {
      body.email_message = this.state.approvalEmailMsg === '<p><br></p>' ? null : this.state.approvalEmailMsg
    }

    notification.open({
      message: 'Submitting',
      description: 'Submitting your updates.',
      icon: <Icon type="warning" style={{ color: 'red' }} />,
    })

    this.setState({ xhr: true }, () => {
      axios
        .post(`/admins/${this.props.current_user.id}/approve_profile/${this.props.profile.id}.json`, body, config)
        .then(
          (response) => {
            // window.location.replace(paths.redirectPath)
            this.setState({ xhr: false })
            notification.open({
              message: 'Success',
              description: 'Your updates have been saved. Please wait for the page to reload.',
              icon: <Icon type="smile-circle" style={{ color: 'green' }} />,
            })
            setTimeout(() => {
              window.location.reload()
            }, 500)
          },
          (err) => {
            this.setState({ xhr: false })
            notification.open({
              message: 'Error',
              description: 'Opps something went wrong. Please try again in a bit.',
              icon: <Icon type="warning" style={{ color: 'red' }} />,
            })
            throw new Error(err.message)
          }
        )
        .catch((err) => {
          this.setState({ xhr: false })
          notification.open({
            message: 'Error',
            description: 'Opps something went wrong. Please try again in a bit.',
            icon: <Icon type="warning" style={{ color: 'red' }} />,
          })
          throw new Error(err.message)
        })
    })
  }

  approveUpdates(approve) {
    const meta = document.querySelector('meta[name="csrf-token"]')
    const paths = this.props.paths
    const config = {
      headers: {
        'X-CSRF-Token': meta.content,
        'Content-Type': 'application/json',
      },
    }
    const body = {
      approve_updates: approve,
    }
    if (this.state.updateApprovalMsg) {
      body.email_message = this.state.updateApprovalMsg
    }
    if (this.state.preselectedUpdateMsg) {
      body.default_message = this.state.preselectedUpdateMsg
    }

    notification.open({
      message: 'Submitting',
      description: 'Submitting your updates.',
      icon: <Icon type="warning" style={{ color: 'red' }} />,
    })

    this.setState({ xhr: true }, () => {
      axios
        .post(
          `/admins/${this.props.current_user.id}/approve_profile_updates/${this.props.profile.id}.json`,
          body,
          config
        )
        .then(
          (response) => {
            // window.location.replace(paths.adminsUpdate)
            // window.location.reload();
            this.setState({ xhr: false })
            notification.open({
              message: 'Success',
              description: 'Your updates have been saved. Please wait for the page to reload.',
              icon: <Icon type="smile-circle" style={{ color: '#108ee9' }} />,
            })
            setTimeout(() => {
              window.location.reload()
            }, 500)
          },
          (err) => {
            this.setState({ xhr: false })
            notification.open({
              message: 'Error',
              description: 'Opps something went wrong. Please try again in a bit.',
              icon: <Icon type="warning" style={{ color: 'red' }} />,
            })
            throw new Error(err.message)
          }
        )
        .catch((err) => {
          this.setState({ xhr: false })
          notification.open({
            message: 'Error',
            description: 'Opps something went wrong. Please try again in a bit.',
            icon: <Icon type="warning" style={{ color: 'red' }} />,
          })
          throw new Error(err.message)
        })
    })
  }

  onInputChange(val, key) {
    console.log('onInputChange: ', val, key)
    this.setState({
      [key]: val,
    })
  }

  resetMsg(stateItem) {
    this.setState({
      [stateItem]: null,
    })
    this.setState({ approvalEmailMsg: null, updateApprovalMsg: null })
  }

  getTableData() {
    const data = []
    this.props.bookouts.forEach((booking) => {
      data.push({
        key: booking.id,
        event: booking.event,
        startDate: moment(booking.date_start).format('MMMM DD YYYY'),
        endDate: moment(booking.date_end).format('MMMM DD YYYY'),
      })
    })
    return data
  }

  msgHistoryData(msgs) {
    const columns = [
      {
        title: 'Date',
        dataIndex: 'datetime',
        key: 'datetime',
        width: 100,
      },
      {
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email',
        width: 200,
      },
      {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
        width: 250,
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: 200,
      },
    ]

    const rows = msgs.map((msg, index) => {
      return {
        key: msg.id,
        datetime: <span>{moment(msg.created_at).format('MM/DD/YYYY')}</span>,
        email: msg.email_address,
        action: msg.action,
        message: <span style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: msg.message }} />,
        subject: msg.subject,
      }
    })

    return [columns, rows]
  }

  updateStateFullText(text, statusMessage) {
    const {
      profile: { other_parents },
    } = this.props
    const fullMessageEmail = {
      'Handsome Boy Decline...': `<p>Thank you for your profile submission, ${other_parents[0].firstName}, </p>
      <p>Your child is a handsome boy, however we have to decline because we currently represent
      several boys with a similar look. Please feel free to re-submit the registration form in a few
      months with updated photos and stats to be reconsidered.  Thank you again for applying to
      Minor Details. Best of luck.</p>`,
      'Beautiful Girl Decline...': `<p>Thank you for your profile submission, ${other_parents[0].firstName}, </p>
      <p>Your child is a beautiful girl, however we have to decline because we currently represent
      several girls with a similar look. Please feel free to re-submit the registration form in a few
      months with updated photos and stats to be reconsidered.  Thank you again for applying to
      Minor Details. Best of luck.</p>`,
      'No Photo Decline...': `<p>Thank you for your profile submission, ${other_parents[0].firstName}, </p>
      <p>Unfortunately applications without photos can not be considered, and they have been
      declined. Please upload a photo (with no hats, bows, ribbons, food on the face, or nudity) to
      your child’s profile and press submit to be reconsidered, or if you’re having difficulty attaching
      a photo to their registration form, please forward a photo to <a href='mailto:lee@minordetails.ca'>lee@minordetails.ca</a> for consideration.</p>`,
      'Call The Office For Meeting...': `<p>Thank you for your profile submission, ${other_parents[0].firstName}, </p>
      <p>We’d like to set up an in-person interview. Please call our office at (416) 405-8755 during
      business hours to discuss representation for your child.</p>`,
      'Baby Acceptance...': `<p>Thank you for your profile submission, ${other_parents[0].firstName}, </p>
      <p>Congratulations!  Your child has been accepted to Minor Details.  Our baby agent will be in
      touch within 7-10 business days to discuss representation for your child.  Please have accurate
      measurements of their height, weight, nape to crotch/wrist/ankle as we will be reviewing your
      registration at that time.</p>`,
      'No Hats or Bows Decline...': `<p>Thank you for your profile submission, ${other_parents[0].firstName}, </p>
      <p>Unfortunately photos with hats or large bows can not be considered, and your child’s
      application has been declined.  Please upload a photo (with no hats, bows, ribbons, food on
      the face, or nudity) to your child’s profile and press submit to be reconsidered, or if you’rehaving difficulty attaching a photo to their registration form, please forward a photo to
      <a href='mailto:lee@minordetails.ca'>lee@minordetails.ca</a> for consideration.</p>`,
      'No Nudity Decline...': `<p>Thank you for your profile submission, ${other_parents[0].firstName},</p>
      <p>Photo’s with any nudity can not be considered, so your child’s application has been declined.
      The photo has also been deleted from the registration form. Please upload a photo (with no
      hats, bows, ribbons, food on the face, or nudity) to your child’s profile and press submit to be
      reconsidered, or if you’re having difficulty attaching a photo to their registration form, please
      forward a photo to <a href='mailto:./lee@minordetails.ca'>lee@minordetails.ca</a> for consideration.</p>`,
      'Photo Is Blurry Decline...': `<p>Thank you for your profile submission, ${other_parents[0].firstName}, </p>,
      <p>The photo you submitted is blurry, fuzzy and/or grainy so your child’s application has been
      declined. This could be due to file size (lower then 500KB), improper cropping, or the child or
      camera was moving when the photo was taken. Please upload a nice, clear photo from the
      waist up (with no hats, bows, ribbons, food on the face, or nudity) to your child’s profile and
      press submit to be reconsidered. If you’re having difficulty attaching a photo to their
      registration form, please forward a photo to <a href='mailto:lee@minordetails.ca'>lee@minordetails.ca</a> for consideration</p>`,
      'Photo is Unsuitable Decline...': `<p>Thank you for your profile submission, ${other_parents[0].firstName}, </p>
      <p>The photo you submitted is unsuitable for the website so your child’s application has been
      declined. Please upload a nice, clear photo from the waist up (with no hats, bows, ribbons,
      food on the face, or nudity) to your child’s profile and press submit to be reconsidered. If you’re
      having difficulty attaching a photo to their registration form, please forward a photo to
      <a href='mailto:lee@minordetails.ca'>lee@minordetails.ca</a> for consideration.</p>`,
      'Stats Can’t be Correct Decline...': `<p>Thank you for your profile submission, ${other_parents[0].firstName}, </p>
      <p>The stats you submitted for your child cannot be correct so your child’s application has been
      declined. Please refer to the “View Measurement Guide” on page 2 of the registration form.  It
      has sizing charts and illustrations on how to properly measure your child. Please correct the
      measurements on the registration form, and press submit on page 4 for us to reconsider their
      application.</p>`,
      'Height can’t be Correct Decline...': `<p>Thank you for your profile submission, ${other_parents[0].firstName}, </p>
      <p>The height you submitted for your child cannot be correct so your child’s application has been
      declined. Please refer to the “View Measurement Guide” on page 2 of the registration form.  It
      has sizing charts and illustrations on how to properly measure your child. Please correct the
      measurements on the registration form, and press submit on page 4 for us to reconsider their
      application.</p>`,
      'Weight Can’t be Correct Decline...': `<p>Thank you for your profile submission, ${other_parents[0].firstName}, </p>
      <p>The weight you submitted for your child cannot be correct so your child’s application has been
      declined. Please refer to the “View Measurement Guide” on page 2 of the registration form.  It
      has sizing charts and illustrations on how to properly measure your child. Please correct the
      measurements on the registration form, and press submit on page 4 for us to reconsider their
      application.</p>`,
      'Inseam Can’t be Correct Decline...': `<p>Thank you for your profile submission, ${other_parents[0].firstName}, </p>
      <p>The inseam you submitted for your child cannot be correct, so your application has been
      declined. Please refer to the “View Measurement Guide” on page 2 of the registration form.  It
      has sizing charts and illustrations on how to properly measure your child.  Please correct the
      measurements on the registration form, and press submit on page 4 for us to reconsider their
      application.</p>`,
      'Update Approved...': `<p>Thank you for your profile submission, ${other_parents[0].firstName}, </p>
      <p>Your updates have been approved for the website.</p>`,
      'Update Declined...': `<p>Thank you for your profile submission, ${other_parents[0].firstName}, </p>
      <p>Your updates not been approved for the website.</p>`,
    }
    switch (text) {
      case 'Handsome Boy Decline...':
        statusMessage === 'approvalEmailMsg'
          ? this.setState({ approvalEmailMsg: fullMessageEmail[text] })
          : this.setState({ updateApprovalMsg: fullMessageEmail[text] })
        break
      case 'Beautiful Girl Decline...':
        statusMessage === 'approvalEmailMsg'
          ? this.setState({ approvalEmailMsg: fullMessageEmail[text] })
          : this.setState({ updateApprovalMsg: fullMessageEmail[text] })
        break
      case 'No Photo Decline...':
        statusMessage === 'approvalEmailMsg'
          ? this.setState({ approvalEmailMsg: fullMessageEmail[text] })
          : this.setState({ updateApprovalMsg: fullMessageEmail[text] })
        break
      case 'Call The Office For Meeting...':
        statusMessage === 'approvalEmailMsg'
          ? this.setState({ approvalEmailMsg: fullMessageEmail[text] })
          : this.setState({ updateApprovalMsg: fullMessageEmail[text] })
        break
      case 'Baby Acceptance...':
        statusMessage === 'approvalEmailMsg'
          ? this.setState({ approvalEmailMsg: fullMessageEmail[text] })
          : this.setState({ updateApprovalMsg: fullMessageEmail[text] })
        break
      case 'No Hats or Bows Decline...':
        statusMessage === 'approvalEmailMsg'
          ? this.setState({ approvalEmailMsg: fullMessageEmail[text] })
          : this.setState({ updateApprovalMsg: fullMessageEmail[text] })
        break
      case 'No Nudity Decline...':
        statusMessage === 'approvalEmailMsg'
          ? this.setState({ approvalEmailMsg: fullMessageEmail[text] })
          : this.setState({ updateApprovalMsg: fullMessageEmail[text] })
        break
      case 'Photo Is Blurry Decline...':
        statusMessage === 'approvalEmailMsg'
          ? this.setState({ approvalEmailMsg: fullMessageEmail[text] })
          : this.setState({ updateApprovalMsg: fullMessageEmail[text] })
        break
      case 'Photo is Unsuitable Decline...':
        statusMessage === 'approvalEmailMsg'
          ? this.setState({ approvalEmailMsg: fullMessageEmail[text] })
          : this.setState({ updateApprovalMsg: fullMessageEmail[text] })
        break
      case 'Stats Can’t be Correct Decline...':
        statusMessage === 'approvalEmailMsg'
          ? this.setState({ approvalEmailMsg: fullMessageEmail[text] })
          : this.setState({ updateApprovalMsg: fullMessageEmail[text] })
        break
      case 'Height can’t be Correct Decline...':
        statusMessage === 'approvalEmailMsg'
          ? this.setState({ approvalEmailMsg: fullMessageEmail[text] })
          : this.setState({ updateApprovalMsg: fullMessageEmail[text] })
        break
      case 'Weight Can’t be Correct Decline...':
        statusMessage === 'approvalEmailMsg'
          ? this.setState({ approvalEmailMsg: fullMessageEmail[text] })
          : this.setState({ updateApprovalMsg: fullMessageEmail[text] })
        break
      case 'Inseam Can’t be Correct Decline...':
        statusMessage === 'approvalEmailMsg'
          ? this.setState({ approvalEmailMsg: fullMessageEmail[text] })
          : this.setState({ updateApprovalMsg: fullMessageEmail[text] })
        break
      case 'Update Approved...':
        statusMessage === 'approvalEmailMsg'
          ? this.setState({ approvalEmailMsg: fullMessageEmail[text] })
          : this.setState({ updateApprovalMsg: fullMessageEmail[text] })
        break
      case 'Update Declined...':
        statusMessage === 'approvalEmailMsg'
          ? this.setState({ approvalEmailMsg: fullMessageEmail[text] })
          : this.setState({ updateApprovalMsg: fullMessageEmail[text] })
        break

      default:
        return null
    }
  }

  render() {
    let pItem
    let profile = this.props.profile
    const expiry = profile && profile.expiry_date ? moment(profile.expiry_date).format('MMMM DD, YYYY') : null
    return (
      <ContextWrapper>
        <div>
          <hr />
          <Row>
            <Col>
              <h2 className="admin-header">Administrator Panel</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                This is for Admin use only. Only administrators can view the content below. Use this section to
                edit/approve/deny profiles. Also use this section to review all profile data and make profile changes.
              </p>
              <br />
            </Col>
          </Row>
          <Row>
            <Col span={24} md={12}>
              {this.props.draft && (
                <p>
                  <em>This profile has updates to review</em>
                </p>
              )}
              {this.state.approved ? (
                <p>
                  <em>
                    {this.props.profile.approved == 'approved'
                      ? 'This profile has been approved'
                      : 'This profile has not been approved'}
                  </em>
                </p>
              ) : (
                <p>
                  <em>This profile has not yet been approved.</em>
                </p>
              )}
              <br />
              <a href={`/profiles/${this.props.profile.id}/edit`}>Edit this profile</a> as an administrator.
            </Col>
            <Col span={24} md={12}>
              <Form onSubmit={this.setExpiryDate}>
                <p style={{ fontWeight: 'bold' }}>Expiration Date (optional)</p>
                <p>This should be set to the length of the guardian's contract.</p>
                {expiry && <p>Current expiry date: {String(expiry)}</p>}
                <br />
                <br />
                <Form.Item>
                  <DatePicker
                    showTime
                    className={'datepicker'}
                    format="YYYY-MM-DD HH:mm:ss"
                    placeholder="Select Time"
                    onChange={(e) => this.onInputChange(e.toDate(), 'expiry_date')}
                    onOk={(e) => this.onInputChange(e.toDate(), 'expiry_date')}
                  />
                </Form.Item>
                <Form.Item>
                  <Button disabled={this.state.xhr} className="admin-btn" type="primary" htmlType="submit" size="large">
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col>
              <hr />
              <h2 className="admin-header">Profile Status</h2>
              <p>
                This section determines whether a profile is live on the site or not. This is not used to for approving
                or denying profile updates.
              </p>
              <br />
              <Form onSubmit={this.handleSubmit}>
                <Row>
                  <Col span={14}>
                    <p style={{ fontWeight: 'bold' }}>1. Profile approve/deny message</p>
                    <p>
                      Whatever is selected or written below will be included in the approval/denial email received by
                      the guardian.
                    </p>
                    <br />
                    <Row>
                      <Col span={16}>
                        <Form.Item label="Explain why with a pre-selected message:">
                          <Select
                            notFoundContent="Not Found"
                            style={{ width: '100%' }}
                            placeholder="Choose"
                            stateKey="preselectedMsg"
                            value={this.state.preselectedMsg}
                            onChange={(e) => this.updateStateFullText(e, 'approvalEmailMsg')}
                            optionFilterProp="children"
                          >
                            {this.props.preselections &&
                              this.props.preselections.map((preselection, index) => {
                                return (
                                  <Select.Option key={preselection} value={preselection}>
                                    {preselection}
                                  </Select.Option>
                                )
                              })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col offset={1} span={7}>
                        <br />
                        <br />
                        <Button
                          disabled={this.state.xhr}
                          className="admin-btn"
                          type="primary"
                          onClick={() => this.resetMsg('preselectedMsg')}
                        >
                          Clear message
                        </Button>
                      </Col>
                    </Row>
                    <Form.Item label="And/Or include a custom message:">
                      <WysiwygInput
                        onChange={(e) => this.onInputChange(e, 'approvalEmailMsg')}
                        value={this.state.approvalEmailMsg ? this.state.approvalEmailMsg : ''}
                        placeholder="Enter a message that will greet the user when they signup/update their profile"
                      />
                    </Form.Item>
                  </Col>
                  <Col offset={2} span={8}>
                    <div style={{ textAlign: 'left' }}>
                      <p style={{ fontWeight: 'bold' }}>2. Make profile live</p>
                      <Form.Item>
                        <Radio.Group
                          value={this.state.approved}
                          size="large"
                          onChange={(e) => this.onInputChange(e.target.value, 'approved')}
                        >
                          <Radio.Button value="approved">Approved</Radio.Button>
                          <Radio.Button value="unset">Unset</Radio.Button>
                          <Radio.Button value="denied">Denied</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          disabled={this.state.xhr}
                          className="admin-btn"
                          type="primary"
                          htmlType="submit"
                          size="large"
                        >
                          Update
                        </Button>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>

          <Row style={{ marginBottom: '30px' }}>
            <hr />
            <h2 className="admin-header">Connections</h2>

            <RelatedProfilesForm
              profile={this.props.profile}
              allActiveProfiles={this.props.allActiveProfiles}
              relatedProfiles={this.props.relatedProfiles}
              current_user={this.props.current_user}
            />
          </Row>

          {this.props.msgHistories && this.props.msgHistories.length > 0 && (
            <Row>
              <hr />
              <Col span={24}>
                <h2 className="admin-header">Correspondance History</h2>

                <Table
                  dataSource={this.msgHistoryData(this.props.msgHistories)[1]}
                  columns={this.msgHistoryData(this.props.msgHistories)[0]}
                  expandedRowRender={(record) => (
                    <div>
                      <span style={{ fontWeight: 'bold' }}>Message:</span> {record.message}
                    </div>
                  )}
                />
              </Col>
            </Row>
          )}
          {this.props.draft && (
            <div>
              <hr />
              <h2 className="admin-header">Profile Updates</h2>
              <p>
                In this section you can compare the current profile with changes that a guardian has submitted, and
                approve / deny them under Approval Status below.
              </p>
              <br />
              <ProfileCompare {...this.props} />
              {this.props.profile && this.props.profile.submission_count > 1 && (
                <div>
                  <Row type="flex" justify="center">
                    <Col span={24} md={14}>
                      <div className="ant-form-item-label">
                        <label>
                          {' '}
                          Enter a preselected message that will be emailed to the user when you approve their profile
                          updates
                        </label>
                      </div>

                      <Row>
                        <Col span={16}>
                          <Select
                            notFoundContent="Not Found"
                            style={{ width: '100%' }}
                            placeholder="Choose"
                            stateKey="preselectedMsg"
                            value={this.state.preselectedUpdateMsg}
                            onChange={(e) => this.updateStateFullText(e, 'updateApprovalMsg')}
                            optionFilterProp="children"
                          >
                            {this.props.preselections &&
                              this.props.preselections.map((preselection, index) => {
                                return (
                                  <Select.Option key={preselection} value={preselection}>
                                    {preselection}
                                  </Select.Option>
                                )
                              })}
                          </Select>
                        </Col>
                        <Col offset={1} span={7}>
                          <Button
                            disabled={this.state.xhr}
                            className="admin-btn"
                            type="primary"
                            onClick={() => this.resetMsg('preselectedUpdateMsg')}
                          >
                            Clear message
                          </Button>
                        </Col>
                      </Row>

                      <div className="ant-form-item-label">
                        <label>
                          {' '}
                          Enter a custom message that will be emailed to the user when you approve their profile updates
                        </label>
                      </div>
                      <WysiwygInput
                        onChange={(e) => this.onInputChange(e, 'updateApprovalMsg')}
                        value={this.state.updateApprovalMsg ? this.state.updateApprovalMsg : ''}
                        placeholder="Enter a message that will greet the user when they update their profile"
                      />
                      <br />
                    </Col>
                  </Row>
                  <Row type="flex" justify="center">
                    <Col span={24} md={4}>
                      <Button
                        disabled={this.state.xhr}
                        className="admin-btn"
                        type="primary"
                        onClick={() => this.approveUpdates(true)}
                        size="large"
                      >
                        Approve updates
                      </Button>
                      <div className="hide-on-desktop">
                        <br />
                      </div>
                    </Col>
                    <Col span={24} md={4}>
                      <Button
                        disabled={this.state.xhr}
                        className="admin-btn"
                        type="primary"
                        onClick={() => this.approveUpdates(false)}
                        size="large"
                      >
                        Deny updates & delete changes
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
              <br />
              <br />
            </div>
          )}

          <Row>
            <Col>
              {this.props.bookouts && this.props.bookouts.length > 0 ? (
                <div>
                  <br />
                  <hr />
                  <h2 className="admin-header">Current Bookings</h2>
                  <Table dataSource={this.getTableData()}>
                    <Column title="Event" dataIndex="event" key="event" />
                    <Column title="Start Date" dataIndex="startDate" key="startDate" />
                    <Column title="End Date" dataIndex="endDate" key="endDate" />
                  </Table>
                </div>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col>
              <br />
              <hr />
              <h2 className="admin-header"> Stats</h2>
              {this.props.profile &&
                Object.keys(this.props.profile)
                  .sort()
                  .map((item, index) => {
                    switch (item) {
                      case 'date_of_birth':
                      case 'approval_time':
                      case 'updated_at':
                      case 'created_at':
                      case 'published_at':
                      case 'trashed_at':
                        pItem = profile[item] ? moment.utc(profile[item]).format('dddd, MMMM Do YYYY') : ''
                        break
                      case 'baby_skills':
                      case 'family_origin':
                      case 'ethnicity':
                        pItem = profile[item] ? __.parseIfString(profile[item]).join(', ') : ''
                        break
                      case 'languages':
                      case 'special_skills':
                      case 'performance_skills':
                      case 'special_needs':
                        pItem = profile[item]
                          ? Object.keys(__.parseIfString(profile[item])).map((subItem, index) => {
                              return (
                                <div key={`profile-${index}`} style={{ marginLeft: '20px' }}>
                                  {_.startCase(subItem.toLowerCase())}: {__.parseIfString(profile[item])[subItem]}
                                </div>
                              )
                            })
                          : ''
                        break
                      case 'awards':
                      case 'class_awards':
                      case 'experience':
                      case 'other_parents':
                      case 'images':
                      case 'hand_images':
                      case 'audio_files':
                        pItem = profile[item]
                          ? __.parseIfString(profile[item]).map((item, index) => {
                              return (
                                <div key={`profile-${index}`} style={{ marginBottom: '5px' }}>
                                  {Object.keys(item).map((subItem, index2) => {
                                    return (
                                      <div key={`profile-subitem-${index2}`} style={{ marginLeft: '20px' }}>
                                        <strong>{_.startCase(subItem)}:</strong>{' '}
                                        {subItem.toLowerCase() == 'url' ? (
                                          <a href={item[subItem]} target="_blank">
                                            {item[subItem]}
                                          </a>
                                        ) : (
                                          String(item[subItem])
                                        )}
                                      </div>
                                    )
                                  })}
                                </div>
                              )
                            })
                          : ''
                        break
                      case 'stats':
                        pItem = profile[item]
                          ? Object.keys(__.parseIfString(profile[item])).map((subItem, index) => {
                              let value
                              if (subItem == 'height') {
                                value = Object.keys(__.parseIfString(profile[item])[subItem])
                                  .map((subsubItem, index) => {
                                    return `${__.parseIfString(profile[item])[subItem][subsubItem]} ${subsubItem}`
                                  })
                                  .join(', ')
                              } else {
                                value = __.parseIfString(profile[item])[subItem]
                              }
                              return (
                                <div key={`profile-subitem-${index}`} style={{ marginLeft: '20px' }}>
                                  <strong>{subItem}: </strong> {value}
                                </div>
                              )
                            })
                          : ''
                        break
                      default:
                        pItem = profile[item]
                        break
                    }
                    return (
                      <div key={`profile-${index}`}>
                        <strong>{_.startCase(item)}:</strong> {pItem || 'null'}
                      </div>
                    )
                  })}
            </Col>
          </Row>
          <br />
        </div>
      </ContextWrapper>
    )
  }
}

export default ProfileAdmin
