import { Col, Row } from 'antd'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import s from './style.module.scss'

class ImageGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    if (this.props.images && this.props.images[0].url) {
      // console.log(_.chunk(this.props.images, 5));
    }
  }

  constructImageArray() {
    var i = 1
    let images = []
    for (var i = 1; i < 21; i++) {
      if (this.props[`image_${i}`]) {
        let image = _.cloneDeep(this.props[`image_${i}`][0])
        if (this.props[`onHover_${i}`]) {
          image['onHover'] = this.props[`onHover_${i}`]
        }
        images.push(image)
      }
    }
    return images
  }

  renderImages(imageArr) {
    if (imageArr) {
      const chunked = _.chunk(imageArr, 5)
      // @arjuna : reverse array here
      return chunked.map((imageGroup, index) => {
        let widths = [6, 6, 6]
        widths[index] = widths[index] * 2
        return (
          <div key={`group-${index}`}>
            {(index == 0 || index == 3) && (
              <Row gutter={32} key={`group-${index}`}>
                <Col xs={24} sm={widths[0]}>
                  {imageGroup[0] && (
                    <div id={`index-${index}`} className={s.lgImgContainer}>
                      <img className={s.image} src={imageGroup[0].url} alt="" />
                      {imageGroup[0].onHover ? <div className={s.onHover}>{imageGroup[0].onHover}</div> : null}
                    </div>
                  )}
                </Col>
                <Col xs={24} sm={widths[1]}>
                  {imageGroup[1] && (
                    <div className={s.imgContainer} id={`index-${index}`}>
                      {<img className={s.image} src={imageGroup[1].url} alt="" />}
                      {imageGroup[1].onHover ? <div className={s.onHover}>{imageGroup[1].onHover}</div> : null}
                    </div>
                  )}
                  {imageGroup[2] && (
                    <div className={s.imgContainer} id={`index-${index}`}>
                      <img className={s.image} src={imageGroup[2].url} alt="" />
                      {imageGroup[2].onHover ? <div className={s.onHover}>{imageGroup[2].onHover}</div> : null}
                    </div>
                  )}
                </Col>
                <Col xs={24} sm={widths[2]}>
                  {imageGroup[3] && (
                    <div className={s.imgContainer} id={`index-${index}`}>
                      {<img className={s.image} src={imageGroup[3].url} alt="" />}
                      {imageGroup[3].onHover ? <div className={s.onHover}>{imageGroup[3].onHover}</div> : null}
                    </div>
                  )}
                  {imageGroup[4] && (
                    <div className={s.imgContainer} id={`index-${index}`}>
                      <img className={s.image} src={imageGroup[4].url} alt="" />
                      {imageGroup[4].onHover ? <div className={s.onHover}>{imageGroup[4].onHover}</div> : null}
                    </div>
                  )}
                </Col>
              </Row>
            )}
            {index == 1 && (
              <Row gutter={32} key={`group-${index}`}>
                <Col xs={24} sm={widths[0]}>
                  {imageGroup[0] && (
                    <div id={`index-${index}`} className={s.imgContainer}>
                      <img className={s.image} src={imageGroup[0].url} alt="" />
                      {imageGroup[0].onHover ? <div className={s.onHover}>{imageGroup[0].onHover}</div> : null}
                    </div>
                  )}
                  {imageGroup[1] && (
                    <div className={s.imgContainer} id={`index-${index}`}>
                      {<img className={s.image} src={imageGroup[1].url} alt="" />}
                      {imageGroup[1].onHover ? <div className={s.onHover}>{imageGroup[1].onHover}</div> : null}
                    </div>
                  )}
                </Col>
                <Col xs={24} sm={widths[1]}>
                  {imageGroup[2] && (
                    <div className={s.lgImgContainer} id={`index-${index}`}>
                      <img className={s.image} src={imageGroup[2].url} alt="" />
                      {imageGroup[2].onHover ? <div className={s.onHover}>{imageGroup[2].onHover}</div> : null}
                    </div>
                  )}
                </Col>
                <Col xs={24} sm={widths[2]}>
                  {imageGroup[3] && (
                    <div className={s.imgContainer} id={`index-${index}`}>
                      {<img className={s.image} src={imageGroup[3].url} alt="" />}
                      {imageGroup[3].onHover ? <div className={s.onHover}>{imageGroup[3].onHover}</div> : null}
                    </div>
                  )}
                  {imageGroup[4] && (
                    <div className={s.imgContainer} id={`index-${index}`}>
                      <img className={s.image} src={imageGroup[4].url} alt="" />
                      {imageGroup[4].onHover ? <div className={s.onHover}>{imageGroup[4].onHover}</div> : null}
                    </div>
                  )}
                </Col>
              </Row>
            )}
            {index == 2 && (
              <Row gutter={32} key={`group-${index}`}>
                <Col xs={24} sm={widths[0]}>
                  {imageGroup[0] && (
                    <div id={`index-${index}`} className={s.imgContainer}>
                      <img className={s.image} src={imageGroup[0].url} alt="" />
                      {imageGroup[0].onHover ? <div className={s.onHover}>{imageGroup[0].onHover}</div> : null}
                    </div>
                  )}
                  {imageGroup[1] && (
                    <div className={s.imgContainer} id={`index-${index}`}>
                      {<img className={s.image} src={imageGroup[1].url} alt="" />}
                      {imageGroup[1].onHover ? <div className={s.onHover}>{imageGroup[1].onHover}</div> : null}
                    </div>
                  )}
                </Col>
                <Col xs={24} sm={widths[1]}>
                  {imageGroup[2] && (
                    <div className={s.imgContainer} id={`index-${index}`}>
                      <img className={s.image} src={imageGroup[2].url} alt="" />
                      {imageGroup[2].onHover ? <div className={s.onHover}>{imageGroup[2].onHover}</div> : null}
                    </div>
                  )}
                  {imageGroup[3] && (
                    <div className={s.imgContainer} id={`index-${index}`}>
                      {<img className={s.image} src={imageGroup[3].url} alt="" />}
                      {imageGroup[3].onHover ? <div className={s.onHover}>{imageGroup[3].onHover}</div> : null}
                    </div>
                  )}
                </Col>
                <Col xs={24} sm={widths[2]}>
                  {imageGroup[4] && (
                    <div className={s.lgImgContainer} id={`index-${index}`}>
                      <img className={s.image} src={imageGroup[4].url} alt="" />
                      {imageGroup[4].onHover ? <div className={s.onHover}>{imageGroup[4].onHover}</div> : null}
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </div>
        )
      })
    }
  }

  render() {
    const images = this.constructImageArray()
    const imageRows = this.renderImages(images)
    return (
      <div className={s.root}>
        <div className="container">
          <div className={s.imageGroup}>{!_.isEmpty(images) && imageRows}</div>
        </div>
      </div>
    )
  }
}

ImageGroup.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    })
  ),
}

export default ImageGroup
