import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import s from './style.module.scss'

class RtaParallax extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div
        className={s.root}
        style={
          this.props.image && this.props.image.length > 0
            ? {
                backgroundImage: `url(${this.props.image[0].url})`,
              }
            : {}
        }
      >
        <div className="container">
          <div className={s.center}>
            <h3 className={s.title}>{this.props.title}</h3>
            {this.props.text && <div className={s.text} dangerouslySetInnerHTML={{ __html: this.props.text }} />}
            {this.props.url && (
              <div className={s.buttonWrapper}>
                <a
                  className={cx('button secondary', s.button)}
                  target={this.props.openInNewTab === 'yes' ? '_blank' : ''}
                  href={this.props.url}
                >
                  {this.props.buttonText}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

RtaParallax.defaultProps = {
  title: 'RtaParallax default title',
}

RtaParallax.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  href: PropTypes.string,
  buttonText: PropTypes.string,
  backgroundImage: PropTypes.string,
}

export default RtaParallax
