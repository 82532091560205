import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { Col, Row } from 'antd'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Slider from 'react-slick'
import PersonCard from './../PersonCard'
import s from './style.module.scss'

const Arrow = ({ position, onClick }) => {
  return (
    <button onClick={onClick} className={cx(s.slickarrow, s[position])}>
      {position == 'left' ? '<' : '>'}
    </button>
  )
}

class PersonWithSlider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const sliderSettings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplaySpeed: 6000,
      autoplay: true,
      prevArrow: <Arrow position="left" onClick={(e) => this.slider.slickPrev()} />,
      nextArrow: <Arrow position="right" onClick={(e) => this.slider.slickNext()} />,
    }

    return (
      <div className={s.root}>
        <div className="container">
          <Row>
            <Col span={24} sm={12}>
              <div className={s.person}>
                <br />
                <br />
                <PersonCard noBorder={true} name={this.props.name1} title={this.props.title} text={this.props.text} />
              </div>
            </Col>
            <Col span={24} sm={12}>
              <div className={s.imageslider}>
                <div className="person-with-slider">
                  {this.props.images && this.props.images.length > 0 && (
                    <Slider {...sliderSettings}>
                      {this.props.images &&
                        this.props.images.map((image, index) => {
                          return (
                            <div key={`image-${index}`} className={s.sliderImageHolder}>
                              <img className={s.sliderImage} src={image.url} alt="" />
                            </div>
                          )
                        })}
                    </Slider>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

PersonWithSlider.defaultProps = {
  title: 'PersonWithSlideriing default title',
}

PersonWithSlider.propTypes = {
  title: PropTypes.string,
}

export default PersonWithSlider
