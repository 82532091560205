import React from 'react'
import moment from 'moment'
import Input from './../components/Input'
import s from './RealFamilyDashboardSearch.module.scss'
import cx from 'classnames'
import _ from 'lodash'
import __ from './../components/Common'
import { ContextWrapper } from '../ContextWrapper'

import { Table, Modal, Row, Col, Select, Card, Button, Input as AntInput } from 'antd'
const Option = Select.Option

const FILTER_DEFAULTS = {
  name: '',
  same_sex: null,
  profile_status: null,
  ethnicity: null,
  has_pet: null,
  grandfather_age_min: null,
  grandfather_age_max: null,
  grandmother_age_min: null,
  grandmother_age_max: null,
  son_age_min: null,
  son_age_max: null,
  daughter_age_min: null,
  daughter_age_max: null,
  father_age_min: null,
  father_age_max: null,
  mother_age_min: null,
  mother_age_max: null,
  num_members_min: null,
  num_members_max: null,
  special_needs: null,
}

const SliderInput = ({ onToggle, value }) => (
  <div onClick={(e) => onToggle()} className={cx(s.slider, { [s.sliderActive]: value })}>
    <div className={s.sliderHalf}>Off</div>
    <div className={s.sliderHalf}>On</div>
    <div className={s.sliderToggle}>| | |</div>
    <div className="cf"></div>
  </div>
)

const RangeInput = ({ onMinChange, onMaxChange, valueMin, valueMax }) => (
  <div className={cx(s.rangeInput)}>
    <Row gutter={8}>
      <Col span={24} sm={11}>
        <AntInput
          placeholder="Min"
          onChange={(e) => onMinChange(e.target.value)}
          type="number"
          min="1"
          value={valueMin}
        />
      </Col>
      <Col span={24} sm={2}>
        <div className={s.to}>To</div>
      </Col>
      <Col span={24} sm={11}>
        <AntInput
          placeholder="Max"
          onChange={(e) => onMaxChange(e.target.value)}
          type="number"
          min="1"
          value={valueMax}
        />
      </Col>
    </Row>
  </div>
)

const SingleFamilyCard = ({ family, highlightFamily, addToCart, inCart }) => (
  <Card className={s.familyCard} bodyStyle={{ padding: 0 }} onClick={highlightFamily}>
    <div className={s.familyCardBody}>
      <div className={s.familyCardImg}>
        <img src={family.familyimage} alt="" />
      </div>
      <div className={s.familyCardHeadline}>
        <h4>{family.family_name} Family</h4>
        <Button
          type="link"
          icon={inCart ? 'minus' : 'plus'}
          onClick={(e) => {
            e.stopPropagation()
            addToCart(family.id)
          }}
        />
      </div>
    </div>
  </Card>
)

const Drawer = ({ visible, onClose, footer, children }) => {
  if (!visible) return null
  return (
    <aside className={s.drawer}>
      <div className={s.drawerInner}>
        <Button className={s.drawerClose} icon="close" onClick={onClose} />
        <div className={s.drawerBody}>{children}</div>
        <div className={s.drawerFooter}>{footer}</div>
      </div>
    </aside>
  )
}

const HighlightedFamilyModal = ({ family, onCancel }) => {
  const familyRows = family.familydetails
    .map((member) => ({
      relationship: member.relationship.toUpperCase(),
      name: [member.firstName, member.lastName].join(' '),
      dob: moment(member.dateOfBirth).format('MM/DD/YYYY'),
      age: __.displayAge(member.dateOfBirth),
      hasActra: member.actraPresent && 'Yes',
      agency: member.agency === 'true' ? member.agencyName : 'Not Represented',
    }))
    .concat(
      family.profiles.map((member) => ({
        relationship: member.gender === 'Female' ? 'DAUGHTER' : 'SON',
        name: [member.first_name, member.last_name].join(' '),
        dob: moment(member.date_of_birth).format('MM/DD/YYYY'),
        age: __.displayAge(member.date_of_birth),
        hasActra: member.actra_present && 'Yes',
        agency: `Minor Details (${member.approved})`,
      }))
    )
  return (
    <Modal title={`${family.family_name} Family`} visible={true} onCancel={onCancel} footer={null} width={'90%'}>
      <div className="container">
        <div className={s.details}>
          <img style={{ maxWidth: '100%' }} src={family.familyimage} alt="" />
          <h4>{family.family_name} Family</h4>
          {familyRows.length > 0 && (
            <Table
              dataSource={familyRows}
              columns={[
                { title: 'Relationship', dataIndex: 'relationship', key: 'relationship' },
                { title: 'Name', dataIndex: 'name', key: 'name' },
                { title: 'Age', dataIndex: 'age', key: 'age' },
                { title: 'Birthdate', dataIndex: 'dob', key: 'dob' },
                { title: 'Actra?', dataIndex: 'hasActra', key: 'hasActra' },
                { title: 'Agency', dataIndex: 'agency', key: 'agency' },
              ]}
              pagination={false}
              size="small"
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

class RealFamilyDashboardSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      size: this.props.sizeSorted,
      cardSize: this.props.cardSize || 'small',
      page: 0,
      offset: 20,
      families: [],
      pristine: [],
      sortBy: 'alphabetical',
      filters: FILTER_DEFAULTS,
      cart: [],
      cartOpen: false,
      highlightedFamily: null,
    }
  }

  componentDidMount() {
    window.onpageshow = function (event) {
      if (event.persisted) {
        // force a reload when page is returned to via 'back btn'
        // this only fires on safari. To circumvent javascript cacheing
        window.location.reload()
      }
    }
    let families = this.props.families
    families.forEach((f) => {
      f.profiles.forEach((p) => {
        for (var prop in p) {
          p[prop] = __.parseIfString(p[prop])
        }
        p.ageInYears = p.date_of_birth ? __.ageInYears(p.date_of_birth) : 0
      })
      f.familydetails.forEach((d) => {
        for (var prop in d) {
          d[prop] = __.parseIfString(d[prop])
        }
        d.ageInYears = d.dateOfBirth ? __.ageInYears(d.dateOfBirth) : 0
      })
      f.family_name = f.family_name.trim()
    })

    let _filters = window.location.search.includes('filters=true')
      ? Object.assign(this.state.filters, this.getFiltersFromURL(window.location.search))
      : this.state.filters

    // console.log("maxDate: ", parameters.minDayType == 'years' ? parameters.maxDate  / 365  : parameters.maxDayType == 'months' ? Math.round(parameters.maxDate / 30.4167) : parameters.maxDate);

    this.setState({
      pristine: families,
      families: families,
      filters: _filters,
    })
  }

  getFiltersFromURL(queryString) {
    let filterObj = {}
    const getRidOfFilterQuery = queryString.replace('?filters=true', '')
    const decodeURItem = decodeURIComponent(getRidOfFilterQuery)
    const queryArray = _.compact(decodeURItem.split('&'))
    queryArray.forEach((item, index) => {
      let values = item.split('=')
      if (values[0] != 'min_day_type' && values[0] != 'max_day_type') {
        let filterVal

        if (values[1].includes(',')) {
          filterVal = values[1].split(',')
        } else if (values[1] == 'true' || values[1] == 'false') {
          filterVal = JSON.parse(values[1])
        } else if (!isNaN(values[1])) {
          filterVal = Number(values[1])
        } else {
          filterVal = values[1]
        }
        filterObj[values[0]] = filterVal
      }
    })
    console.log('filterObj: ', filterObj)
    return filterObj
  }

  setFiltersInUrl(filters) {
    const appliedFilters = {}
    Object.keys(filters).forEach((item, index) => {
      if (!_.isEmpty(filters[item]) || _.isNumber(filters[item]) || _.isBoolean(filters[item])) {
        appliedFilters[item] = filters[item]
      }
    })
    var urlizeObj = function (obj) {
      var str = ''
      for (var key in obj) {
        if (str != '') {
          str += '&'
        }
        str += key + '=' + encodeURIComponent(obj[key])
      }
      return str
    }

    if (!_.isEmpty(appliedFilters)) {
      const x = urlizeObj(appliedFilters)
      history.pushState({}, 'Filter Updated', '/admins/realfamily?filters=true&' + x)
    }
  }

  setSize(size) {
    this.setState({ cardSize: size })
  }

  handleSort(families) {
    let order, against
    switch (this.state.sortBy) {
      case 'alphabetical':
        against = ['family_name']
        order = ['asc']
        break
      default:
        against = ['family_name']
        order = ['asc']
        break
    }

    return _.orderBy(families, against, order)
  }

  setSort(value) {
    this.setState({ sortBy: value, page: 0 })
  }

  /************************
    State-setting methods for search
  *************************/

  setAgeInDays(type, value) {
    const { minDayType, maxDayType, minDate, maxDate } = this.state
    const filters = _.cloneDeep(this.state.filters)
    let totalDays
    if (type === 'minDayType') {
      if (minDate) {
        totalDays = getTotalDays(value, minDate)
        filters.min_days = totalDays
      }
      this.setState({
        minDayType: value,
        filters: filters,
      })
    } else if (type === 'maxDayType') {
      if (maxDate) {
        totalDays = value == 'years' ? getTotalDays(value, maxDate, true) : getTotalDays(value, maxDate)
        filters.max_days = totalDays
      }
      this.setState({
        maxDayType: value,
        filters: filters,
      })
    } else if (type === 'minDate') {
      totalDays = getTotalDays(minDayType, value)
      filters.min_days = totalDays
      this.setState({
        minDate: value,
        filters: filters,
      })
    } else {
      totalDays = getTotalDays(maxDayType, value, true)
      filters.max_days = totalDays
      this.setState({
        maxDate: value,
        filters: filters,
      })
    }
    function getTotalDays(type, val, addYear = false) {
      let total
      switch (type) {
        case 'years':
          total = val * 365
          if (addYear) {
            total = total + 364
          }
          break
        case 'months':
          total = val * 30
          break
        case 'days':
          total = Number(val)
          break
      }
      return total
    }
    this.setFiltersInUrl(filters)
  }

  setFilter(filterstatekey, value) {
    let filters = _.cloneDeep(this.state.filters)
    if (
      (filterstatekey === 'special_needs' || filterstatekey === 'ethnicity') &&
      Array.isArray(value) &&
      value.length == 0
    ) {
      filters[filterstatekey] = null
    } else {
      filters[filterstatekey] = value
    }
    this.setState({
      page: 0,
      filters: filters,
    })
    this.setFiltersInUrl(filters)
  }

  setName(key, value) {
    let filters = _.cloneDeep(this.state.filters)
    filters.name = value
    this.setState({
      page: 0,
      filters: filters,
    })
    this.setFiltersInUrl(filters)
  }

  setPage(page) {
    this.setState({ page: page })
  }

  toggleFilters(val) {
    this.setState({ showFilters: val })
  }

  filterFamily(family, index) {
    let filterAgainst = []
    for (var key in this.state.filters) {
      if (this.state.filters.hasOwnProperty(key)) {
        const element = this.state.filters[key]
        element ? filterAgainst.push(key) : false
      }
    }

    let familypass = filterAgainst.map((key) => {
      if (key === 'name') {
        const name = family.family_name
        return name.toLowerCase().indexOf(this.state.filters[key].toLowerCase()) > -1
      } else if (key === 'same_sex') {
        if (this.state.filters[key] === 'only') {
          return family['samesexfamily'] === true
        } else if (this.state.filters[key] === 'none') {
          return family['samesexfamily'] === false || family['samesexfamily'] === null
        }
      } else if (key === 'profile_status') {
        return family.profiles.some((profile) => profile.approved === this.state.filters[key])
      } else if (key === 'ethnicity') {
        return family.profiles.some((profile) => {
          const ethnicities = __.parseIfString(profile.ethnicity)
          const filterEthnicities = [].concat(this.state.filters[key]) // ensure it is parsed as an array, even when it's a single value
          if (!ethnicities?.length > 0) return false
          const _profileEthnicities = ethnicities.map((e) => e.toLowerCase().trim())
          const _filterEthnicities = filterEthnicities.map((e) => e.toLowerCase().trim())
          return __.arrayContainsArray(_profileEthnicities, _filterEthnicities)
        })
      } else if (key === 'special_needs') {
        return family.profiles.some((profile) => {
          const needs = __.parseIfString(profile.special_needs)
          const filterNeeds = [].concat(this.state.filters[key]).map((need) => need.toLowerCase().trim()) // ensure it is parsed as an array, even when it's a single value
          return Object.keys(needs).some((need) => filterNeeds.includes(need.toLowerCase().trim()))
        })
      } else if (key === 'has_pet') {
        return family.familydetails.some((member) => member.relationship === this.state.filters[key])
      } else if (key.match('.+_age_.+')) {
        const [fieldName] = key.split('_')
        const min = parseInt(this.state.filters[`${fieldName}_age_min`]) || 0
        const max = parseInt(this.state.filters[`${fieldName}_age_max`]) || 200 // Sane default to make our condition simpler below
        const familyMatch = family.familydetails
          .filter((member) => member.relationship.toLowerCase() === fieldName.toLowerCase())
          .some((member) => member.ageInYears >= min && member.ageInYears <= max)
        const profileMatch = family.profiles
          .filter((profile) => {
            if (fieldName === 'son') {
              return profile.gender === 'Male'
            } else if (fieldName === 'daughter') {
              return profile.gender === 'Female'
            }
          })
          .some((profile) => profile.ageInYears >= min && profile.ageInYears <= max)
        return familyMatch || profileMatch
      } else if (key.match('num_members_.+')) {
        const min = this.state.filters['num_members_min'] ?? 0
        const max = this.state.filters['num_members_max'] ?? 200 // Sane default to make our condition simpler below
        const members = family.familydetails.concat(family.profiles)
        return members.length >= min && members.length <= max
      }
    })

    const Wrapper = ({ size, children, ...rest }) => {
      switch (size) {
        case 'large':
          return (
            <Col xs={24} {...rest}>
              {children}
            </Col>
          )
        case 'medium':
          return (
            <Col lg={12} xs={24} {...rest}>
              {children}
            </Col>
          )
        default:
          return (
            <Col lg={8} sm={12} xs={24} {...rest}>
              {children}
            </Col>
          )
      }
    }

    return familypass.indexOf(false) === -1 ? (
      <Wrapper size={this.state.cardSize} key={index}>
        <SingleFamilyCard
          family={family}
          addToCart={this.addToCart.bind(this)}
          inCart={this.state.cart.indexOf(family.id) > -1}
          highlightFamily={() =>
            this.setState({
              highlightedFamily: family,
            })
          }
        />
      </Wrapper>
    ) : null
  }

  addToCart(familyId) {
    let cart = _.cloneDeep(this.state.cart)
    const index = cart.indexOf(familyId)
    if (index > -1) {
      cart.splice(index, 1)
    } else {
      cart.push(familyId)
    }
    this.setState({ cart: cart })
  }

  clearFilter(filterItem) {
    const _filters = _.cloneDeep(this.state.filters)
    switch (filterItem) {
      case 'name':
        _filters[filterItem] = ''
        break
      default:
        _filters[filterItem] = null
        break
    }
    this.setState(
      {
        filters: _filters,
      },
      function () {
        this.setFiltersInUrl(_filters)
      }
    )
  }

  render() {
    let filters = Object.keys(this.state.filters).filter((item, index) => {
      return this.state.filters[item]
    })
    const orderedFamilies = this.handleSort(this.state.families)
    let counter = -1
    let newRendered
    if (orderedFamilies.length > 0) {
      newRendered = orderedFamilies
        .map((family, index) => {
          let filtered = this.filterFamily(family, index)
          if (filtered) {
            counter++
          }
          return counter >= this.state.offset * this.state.page && counter < this.state.offset * (this.state.page + 1)
            ? filtered
            : false
        })
        .filter((n) => n)
    } else {
      newRendered = <div className={s.noResult}>No one fits that description</div>
    }
    const availableFamilies = orderedFamilies.map((family, index) => this.filterFamily(family, index)).filter((n) => n)
    const pages = Array(Math.ceil(availableFamilies.length / this.state.offset)).fill(true)
    return (
      <ContextWrapper>
        <div className={s.root}>
          <div className={cx('container', s.container)}>
            <Row type="flex" align="middle">
              <Col span={24} sm={8}>
                <h1 className={s.h1}>Search Real Families</h1>
              </Col>
              <Col span={24} sm={8} className="hide-on-mobile">
                <div className={s.toggleSize}>
                  {['small', 'medium', 'large'].map((size, index) => {
                    const extraClass = size === this.state.cardSize ? s.currentSize : s.inactiveSize
                    return (
                      <i
                        onClick={(e) => this.setSize(size)}
                        className={cx(`icon icon-${size}`, extraClass, 'hover-icon')}
                        key={index}
                      ></i>
                    )
                  })}
                </div>
              </Col>
              <Col span={24} sm={4}>
                <div className={s.sortByLabel}>SORT BY</div>
              </Col>
              <Col span={24} sm={4}>
                <div className={s.sortBy}>
                  <Select
                    className={s.sortBySelect}
                    defaultValue="Alphabetical"
                    style={{ width: 120 }}
                    onChange={(e) => this.setSort(e)}
                  >
                    <Option value="alphabetical">Alphabetical</Option>
                  </Select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24} sm={7} className={this.state.showFilters ? s.filterShow : s.filterHolder}>
                <div className={s.showFilters}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault()
                      this.toggleFilters(!this.state.showFilters)
                    }}
                    className={cx('button', s.showfiltersButton)}
                  >
                    {this.state.showFilters ? `Hide` : `Show`} Filters
                  </a>
                </div>
                <br />
                <p className={s.explain}>Use filters below to narrow your search</p>
                <br />
                {!_.isEmpty(filters) && (
                  <ul className={s.filters}>
                    {filters.map((item, index) => {
                      return (
                        <li className={s.filterItem} key={`filter-${index}`}>
                          <div className={s.filterContent}>
                            {_.startCase(item)}
                            <span onClick={() => this.clearFilter(item)} className={s.filterRemove}></span>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                )}
                <br />
                <div className={s.formTitle}>Family Name</div>
                <Input
                  ref="Name"
                  stateKey={'name'}
                  onChange={this.setName.bind(this)}
                  value={this.state.filters.name ? this.state.filters.name : ''}
                  placeholder="Name"
                />

                <div className={s.formTitle}>Same Sex Families</div>
                <Select
                  notFoundContent="Not Found"
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Choose"
                  stateKey="same_sex"
                  value={this.state.filters.same_sex}
                  onChange={(e) => this.setFilter('same_sex', e)}
                  optionFilterProp="children"
                >
                  <Select.Option value={null}>All</Select.Option>
                  {['only', 'none'].map((opt, index) => {
                    return (
                      <Select.Option key={opt} value={opt}>
                        {opt}
                      </Select.Option>
                    )
                  })}
                </Select>

                <div className={s.formTitle}>Child Profile Status On Site</div>
                <Select
                  notFoundContent="Not Found"
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Choose"
                  stateKey="profile_status"
                  value={this.state.filters.profile_status}
                  onChange={(e) => this.setFilter('profile_status', e)}
                  optionFilterProp="children"
                >
                  <Select.Option value={null}>All</Select.Option>
                  <Select.Option value={'approved'}>Approved</Select.Option>
                  <Select.Option value={'unset'}>Pending</Select.Option>
                  <Select.Option value={'denied'}>Denied</Select.Option>
                </Select>

                <div className={s.formTitle}>Ethnicity</div>
                <Select
                  notFoundContent="Not Found"
                  showSearch
                  mode="multiple"
                  className={`search-select`}
                  value={
                    this.state.filters.ethnicity && this.state.filters.ethnicity.length > 0
                      ? this.state.filters.ethnicity
                      : []
                  }
                  style={{ width: '100%' }}
                  placeholder="Choose"
                  stateKey="ethnicity"
                  onChange={(e) => this.setFilter('ethnicity', __.cleanArray(e))}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                >
                  {this.props.ethnicities &&
                    this.props.ethnicities.map((ethnicity, index) => {
                      return (
                        <Select.Option key={ethnicity} value={ethnicity}>
                          {ethnicity}
                        </Select.Option>
                      )
                    })}
                </Select>

                <div className={s.formTitle}>Has Pet?</div>
                <Select
                  notFoundContent="Not Found"
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Choose"
                  stateKey="has_pet"
                  value={this.state.filters.has_pet}
                  onChange={(e) => this.setFilter('has_pet', e)}
                  optionFilterProp="children"
                >
                  <Select.Option value={null}>All</Select.Option>
                  <Select.Option value={'dog'}>Dog</Select.Option>
                  <Select.Option value={'cat'}>Cat</Select.Option>
                </Select>

                <div className={s.formTitle}>Grandfather Age</div>
                <RangeInput
                  onMinChange={(val) => this.setFilter('grandfather_age_min', val)}
                  onMaxChange={(val) => this.setFilter('grandfather_age_max', val)}
                  valueMin={this.state.filters.grandfather_age_min}
                  valueMax={this.state.filters.grandfather_age_max}
                />
                <div className={s.formTitle}>Grandmother Age</div>
                <RangeInput
                  onMinChange={(val) => this.setFilter('grandmother_age_min', val)}
                  onMaxChange={(val) => this.setFilter('grandmother_age_max', val)}
                  valueMin={this.state.filters.grandmother_age_min}
                  valueMax={this.state.filters.grandmother_age_max}
                />
                <div className={s.formTitle}>Son Age</div>
                <RangeInput
                  onMinChange={(val) => this.setFilter('son_age_min', val)}
                  onMaxChange={(val) => this.setFilter('son_age_max', val)}
                  valueMin={this.state.filters.son_age_min}
                  valueMax={this.state.filters.son_age_max}
                />
                <div className={s.formTitle}>Daughter Age</div>
                <RangeInput
                  onMinChange={(val) => this.setFilter('daughter_age_min', val)}
                  onMaxChange={(val) => this.setFilter('daughter_age_max', val)}
                  valueMin={this.state.filters.daughter_age_min}
                  valueMax={this.state.filters.daughter_age_max}
                />
                <div className={s.formTitle}>Father Age</div>
                <RangeInput
                  onMinChange={(val) => this.setFilter('father_age_min', val)}
                  onMaxChange={(val) => this.setFilter('father_age_max', val)}
                  valueMin={this.state.filters.father_age_min}
                  valueMax={this.state.filters.father_age_max}
                />
                <div className={s.formTitle}>Mother Age</div>
                <RangeInput
                  onMinChange={(val) => this.setFilter('mother_age_min', val)}
                  onMaxChange={(val) => this.setFilter('mother_age_max', val)}
                  valueMin={this.state.filters.mother_age_min}
                  valueMax={this.state.filters.mother_age_max}
                />

                <div className={s.formTitle}>Number of Family Members</div>
                <RangeInput
                  onMinChange={(val) => this.setFilter('num_members_min', val)}
                  onMaxChange={(val) => this.setFilter('num_members_max', val)}
                  valueMin={this.state.filters.num_members_min}
                  valueMax={this.state.filters.num_members_max}
                />

                <div className={s.formTitle}>Special Needs</div>
                <Select
                  notFoundContent="Not Found"
                  showSearch
                  mode="multiple"
                  className={`search-select`}
                  value={
                    this.state.filters.special_needs && this.state.filters.special_needs.length > 0
                      ? this.state.filters.special_needs
                      : []
                  }
                  style={{ width: '100%' }}
                  placeholder="Choose"
                  stateKey="special_needs"
                  onChange={(e) => this.setFilter('special_needs', __.cleanArray(e))}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                >
                  {this.props.specialNeeds &&
                    this.props.specialNeeds.map((need, index) => {
                      return (
                        <Select.Option key={need} value={need}>
                          {need}
                        </Select.Option>
                      )
                    })}
                </Select>
              </Col>

              <Col span={24} sm={1}></Col>
              <Col span={24} sm={16}>
                {counter === -1 ? (
                  <div className={s.noProfiles}>
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp; No profiles match that criteria.
                  </div>
                ) : (
                  <Row gutter={10}>{newRendered}</Row>
                )}
                <div className="cf"></div>
                <div className={s.pagination}>
                  <div
                    className={this.state.page > 0 ? cx(s.pageJump, s.currentPage) : cx(s.pageJump, s.disabledPage)}
                    onClick={() => (this.state.page > 0 ? this.setPage(this.state.page - 1) : false)}
                  >
                    {'<'}
                  </div>
                  {pages.map((p, index) => {
                    return (
                      <div
                        key={index}
                        className={this.state.page == index ? cx(s.pageJump, s.currentPage) : s.pageJump}
                        onClick={() => this.setPage(index)}
                      >
                        {index + 1}
                      </div>
                    )
                  })}
                  <div
                    className={
                      this.state.page < pages.length - 1
                        ? cx(s.pageJump, s.currentPage)
                        : cx(s.pageJump, s.disabledPage)
                    }
                    onClick={() => (this.state.page < pages.length - 1 ? this.setPage(this.state.page + 1) : false)}
                  >
                    {'>'}
                  </div>
                </div>
              </Col>
            </Row>

            <Button
              type="primary"
              size="large"
              onClick={() => this.setState({ cartOpen: true })}
              disabled={this.state.cart.length === 0}
              className={s.cartBtn}
            >
              Cart ({this.state.cart.length})
            </Button>
            {this.state.cart.length > 0 && (
              <Drawer
                visible={this.state.cartOpen}
                onClose={() => this.setState({ cartOpen: false })}
                footer={
                  <>
                    <a
                      className="ant-btn ant-btn-primary"
                      href={`/home/download_realfamilies.pdf?ids[]=${this.state.cart.join('&ids[]=')}`}
                      target="_blank"
                    >
                      <span>
                        DOWNLOAD PDF ({this.state.cart.length} {this.state.cart.length > 1 ? 'Families' : 'Family'})
                      </span>
                    </a>
                    <Button block onClick={() => this.setState({ cart: [] })}>
                      Clear
                    </Button>
                  </>
                }
              >
                {this.state.families
                  .filter((f) => this.state.cart.indexOf(f.id) > -1)
                  .map((family) => (
                    <div className={s.cartfamily} key={family.id}>
                      <SingleFamilyCard
                        family={family}
                        addToCart={this.addToCart.bind(this)}
                        inCart={true}
                        highlightFamily={() => this.setState({ highlightedFamily: family })}
                      />
                    </div>
                  ))}
              </Drawer>
            )}
            {this.state.highlightedFamily && (
              <HighlightedFamilyModal
                family={this.state.highlightedFamily}
                onCancel={() => this.setState({ highlightedFamily: null })}
              />
            )}
          </div>
        </div>
      </ContextWrapper>
    )
  }
}

export default RealFamilyDashboardSearch
