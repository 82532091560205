import { Collapse, Row } from 'antd'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import JsonTree from 'react-json-tree'
import __ from './../Common/'
import DataTable from './../DataTable/'

const Panel = Collapse.Panel

class ProfileCompare extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    let { profile, draftMeta, draft, changeset, patchedChangeset } = this.props
    let columns = [
      {
        Header: 'Changed Value',
        accessor: 'change',
      },
      {
        Header: 'Profile',
        accessor: 'profile',
      },
      {
        Header: 'In Draft',
        accessor: 'draft',
      },
      {
        Header: 'Checked',
        accessor: 'checked',
        Cell: (row) => <input style={{ marginLeft: '10%' }} type="checkbox" />,
      },
    ]

    let rows = []
    changeset = _.assign(changeset, patchedChangeset)
    let changes = Object.keys(changeset)
    changes.forEach((change, index) => {
      if (!(change == 'preferred_parent_lat' || change == 'preferred_parent_long')) {
        rows.push({
          change: __.humanize(change),
          profile: __.stringifyIfObject(changeset[change][0]),
          draft: __.stringifyIfObject(changeset[change][1]),
          checked: true,
        })
      }
    })

    return (
      <div className="admin-layout">
        <Collapse bordered={false} defaultActiveKey={['1']}>
          <Panel header="ChangeSet" key="1">
            <DataTable defaultPageSize={rows.length} columns={columns} rows={rows} filterable={true} />
          </Panel>
          <Panel header="Draft Information" key="3">
            <JsonTree invertTheme={false} data={draftMeta} />
          </Panel>
        </Collapse>
      </div>
    )
  }
}

ProfileCompare.defaultProps = {
  title: 'ProfileCompareiing default title',
}

ProfileCompare.propTypes = {
  title: PropTypes.string,
}

export default ProfileCompare
