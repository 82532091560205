import { notification } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import s from './style.module.scss'

class Notice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    // Slight delay to ensure notifications are loaded
    setTimeout(
      () =>
        notification.open({
          message: this.props.title ? this.props.title : 'Notification',
          description: this.props.notice,
          style: {},
        }),
      100
    )
  }
  render() {
    return <div className={s.root}></div>
  }
}

Notice.defaultProps = {
  notice: 'Noticing default notice',
}

Notice.propTypes = {
  notice: PropTypes.string,
}

export default Notice
