import { Col, Modal, Row, Button } from 'antd'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Logo from './../Logo/'
import ResultCard from './../ResultCard/'
import s from './style.module.scss'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      submissionsModal: false,
      clearModal: false,
      current: [],
    }
  }

  toggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      hasScrolled: false,
      setAnimation: false,
      left: 0,
    })
  }

  goHome = () => {
    window.location.href = '/'
  }

  downloadProfiles = () => {
    let queries = ''
    this.state.current.forEach((item, index) => {
      queries = queries + `ids[]=${item.id}&`
    })
    this.setState(
      {
        submissionsModal: false,
      },
      function () {
        window.open(`/home/download_profiles.pdf?${queries.slice(0, -1)}`, '_blank')
      }
    )
    // window.location.replace(`/home/download_profiles.pdf?${queries.slice(0, -1)}`))
  }

  handleCancel = () => {
    this.setState({ submissionsModal: false })
  }

  handleClear = () => {
    sessionStorage.clear()
    this.setState({ clearModal: !this.state.clearModal, current: [] })
    events.publish('cart_cleared')
    sessionStorage.setItem('minor_details_profiles', JSON.stringify([]))
  }

  modalClearToggle = () => {
    this.setState({
      submissionsModal: !this.state.submissionsModal,
      clearModal: !this.state.clearModal,
    })
  }

  showSubmissionsModal = () => {
    this.setState({
      submissionsModal: true,
    })
  }

  setSiteSearch = () => {
    const searchlink = document.getElementById('searchlink')
    // this.setState({left: searchlink.getBoundingClientRect().left})
  }

  componentDidMount() {
    document.addEventListener('scroll', () => {
      window.pageYOffset > 300 ? this.setState({ hasScrolled: true }) : this.setState({ hasScrolled: false })
      window.pageYOffset > 401 ? this.setState({ setAnimation: true }) : this.setState({ setAnimation: false })
    })

    window.addEventListener('resize', this.setSiteSearch)

    window.subscriptions.add = events.subscribe('profile_added', (e) => {
      const current = e.detail ? e.detail : []
      this.setState({ current: current })
    })
    const current = JSON.parse(sessionStorage.getItem('minor_details_profiles'))
    current
      ? this.setState({ current: current })
      : this.setState({ current: [] }, () => sessionStorage.setItem('minor_details_profiles', JSON.stringify([])))
    this.setSiteSearch()
  }
  componentWillUnmount() {
    window.subscriptions.add.remove()
  }

  render() {
    const loggedIn = this.props.logged_in
    const siteLinks = this.props.siteLinks
    const scrollstyles = this.state.setAnimation
      ? cx(s.setAnimation, s.hasScrolled, s.root)
      : this.state.hasScrolled
      ? cx(s.root, s.hasScrolled)
      : cx(s.root)
    return (
      <div className={scrollstyles}>
        <div className={s.absoluteContainer}>
          <div className={this.state.isOpen ? cx(s.mobileBar, s.open) : s.mobileBar}>
            <div className={s.hamburger} onClick={(e) => this.toggleOpen()}>
              <i className="icon-hamburger"></i>
            </div>
            {!this.state.isOpen && (
              <div className={s.mobileIcons}>
                {loggedIn && (
                  <a href={this.props.login_link}>
                    <i className="icon-profile"></i>
                  </a>
                )}
                <a href="#" onClick={(e) => this.showSubmissionsModal()}>
                  <i className="icon-download"></i>
                  {this.state.current && this.state.current.length > 0 && (
                    <div className={s.submissionCountMobile}> {this.state.current.length}</div>
                  )}
                </a>
              </div>
            )}
          </div>
          <div className={this.state.hasScrolled ? cx(s.logo, s.logoScrolled) : s.logo} onClick={(e) => this.goHome()}>
            <div className={s.repeatingImage}></div>
            <div className={this.state.hasScrolled ? cx(s.logoholder, s.smaller) : s.logoholder}>
              <Logo />
            </div>
          </div>
          <div className={this.state.isOpen ? cx(s.menubarHolder, s.open) : s.menubarHolder}>
            <div className={cx(s.menubar)}>
              <Row type="flex" justify="center">
                <Col span={24}>
                  <ul className={s.siteLinks}>
                    <li className={cx(s.searchLink)}>
                      {/* search icon */}
                      <a className={cx(s.siteLink, s.iconlink, s.hasDropdown)} href={`${siteLinks[5].url}`}>
                        <i className="icon-search"></i> <span className={cx('hide-on-desktop')}>Search Talent</span>
                      </a>
                      <div className={cx(s.siteLinksDropDown, 'hide-on-mobile')}>
                        <div className="container">
                          <Col span={24}>
                            <ul className={s.siteSearchesList}>
                              {this.props.siteSearches.map((link, index) => {
                                return (
                                  <li key={index}>
                                    <a className={s.dropdownLink} href={link.url}>
                                      {link.title}
                                    </a>
                                  </li>
                                )
                              })}
                            </ul>
                          </Col>
                        </div>
                      </div>
                    </li>
                    <li>
                      <a className={s.siteLink} href={'/'}>
                        Home
                      </a>
                    </li>
                    <li>
                      {/* About us */}
                      <a className={s.siteLink} href={`${siteLinks[0].url}`}>
                        {siteLinks[0].title}
                      </a>
                    </li>
                    <li>
                      {/* Success */}
                      <a className={s.siteLink} href={`${siteLinks[1].url}`}>
                        {siteLinks[1].title}
                      </a>
                    </li>

                    <li>
                      {/* Contact us */}
                      <a className={s.siteLink} href={`${siteLinks[3].url}`}>
                        {siteLinks[3].title}
                      </a>
                    </li>
                    <li>
                      {/* Faqs */}
                      <a className={s.siteLink} href={`${siteLinks[4].url}`}>
                        {siteLinks[4].title}
                      </a>
                    </li>

                    {!loggedIn && (
                      <li>
                        {/* Register */}
                        <a className={s.siteLink} href={siteLinks[2].url}>
                          {siteLinks[2].title}
                        </a>
                      </li>
                    )}

                    {loggedIn && (
                      <li>
                        <a className={cx(s.siteLink, s.iconlink)} href={this.props.paths.logout}>
                          <i className="icon-lock"></i> <span className="">log out</span>
                        </a>
                      </li>
                    )}
                    {loggedIn && (
                      <li>
                        <a className={cx(s.siteLink, s.iconlink)} href={this.props.login_link}>
                          <i className="icon-profile"></i> <span className="">My Account</span>
                        </a>
                      </li>
                    )}
                    <li>
                      <a className={cx(s.siteLink, s.iconlink)} href="#" onClick={(e) => this.showSubmissionsModal()}>
                        <i className="icon-download"></i> <span className="hide-on-desktop">submissions</span>
                        {this.state.current && this.state.current.length > 0 && (
                          <div className={s.submissionCount}> {this.state.current.length}</div>
                        )}
                      </a>
                    </li>
                    {!loggedIn && (
                      <li>
                        <a className={cx(s.siteLink, s.iconlink)} href={this.props.paths.login}>
                          <i className="icon-lock"></i> <span className="">login</span>
                        </a>
                      </li>
                    )}
                    <li className={cx('hide-on-desktop', s.facebook)}>
                      <a href="https://www.facebook.com/minordetailsagency/" target="_blank">
                        f
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <Modal
          title="My Submissions"
          visible={this.state.submissionsModal}
          onOk={this.downloadProfiles.bind(this)}
          onCancel={this.handleCancel.bind(this)}
          width="95%"
          style={{ maxWidth: '750px' }}
          footer={[
            <Button onClick={this.handleCancel.bind(this)}>Close</Button>,
            <Button onClick={this.modalClearToggle.bind(this)}>Clear Profiles</Button>,
            <Button type="primary" onClick={this.downloadProfiles.bind(this)}>
              Download Profiles
            </Button>,
          ]}
        >
          <div className="container">
            <Row>
              <Col span="24">
                {this.state.current.map((p, index) => {
                  return <ResultCard explicitRemove={true} key={index} profile={p} size="small" />
                })}
              </Col>
            </Row>
          </div>
        </Modal>
        {this.state.clearModal && (
          <Modal
            title="Clearing of the cart"
            visible={this.state.clearModal}
            onOk={this.handleClear.bind(this)}
            onCancel={this.modalClearToggle.bind(this)}
            okText="Yes"
            cancelText="No"
          >
            <p>Are you sure you want to clear your cart?</p>
          </Modal>
        )}
      </div>
    )
  }
}

Header.defaultProps = {
  title: 'Testiing default title',
}

Header.propTypes = {
  title: PropTypes.string,
}

export default Header
