import PropTypes from 'prop-types'
import React from 'react'
import { ContextWrapper } from '../ContextWrapper'
import VideoForm from './../components/VideoForm'

class VideoProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <ContextWrapper>
        <VideoForm {...this.props} />
      </ContextWrapper>
    )
  }
}

VideoProfile.defaultProps = {
  title: 'Registration container title',
}

VideoProfile.propTypes = {
  title: PropTypes.string,
}

export default VideoProfile
