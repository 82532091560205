import { Col, Row } from 'antd'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import s from './style.module.scss'

class FAQItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }
  toggleFAQ() {
    this.setState({ open: !this.state.open })
  }
  render() {
    return (
      <div className={cx('container', s.root)}>
        <Row>
          <Col xs={24}>
            <h2 onClick={(e) => this.toggleFAQ()} className={this.state.open ? cx(s.title, s.open) : s.title}>
              {this.props.title}
            </h2>
            {this.state.open && (
              <div className={s.answer}>
                <div dangerouslySetInnerHTML={{ __html: this.props.answer }} />
                {this.props.children}
              </div>
            )}
          </Col>
        </Row>
      </div>
    )
  }
}

FAQItem.propTypes = {
  title: PropTypes.string,
  answer: PropTypes.string,
}

export default FAQItem
