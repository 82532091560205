import React from 'react'
import Exposer from './../Exposer/'

class PageItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const Component = Exposer.List[this.props.name]
    return <div> {Component ? <Component {...this.props} /> : <div>Unknown component type {this.props.name}</div>}</div>
  }
}

export default PageItem
