import _ from 'lodash'
import React from 'react'
import Exposer from './../Exposer/'

function humanize(str) {
  return _.capitalize(_.trim(_.snakeCase(str).replace(/_id$/, '').replace(/_/g, ' ')))
}

class PageItemEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const propTypes = Exposer.Fields[this.props.name]
    return (
      <div>
        {propTypes.map((propType, index) => {
          const EditComponent = Exposer.Form[propType.field]

          let field = (
            <EditComponent
              key={index}
              value={this.props.pageComponents[this.props.position][propType.name]}
              type={propType.type}
              onChange={(e) => {
                this.props.handleInputChange(e.target.value, propType.name, this.props.name, this.props.position)
              }}
            />
          )

          if (propType.field === 'Radio') {
            field =
              propType.options && propType.options.length > 1 ? (
                <EditComponent.Group
                  key={index}
                  defaultValue={propType.options[0]}
                  value={this.props.pageComponents[this.props.position][propType.name]}
                  onChange={(e) => {
                    this.props.handleInputChange(e.target.value, propType.name, this.props.name, this.props.position)
                  }}
                >
                  {propType.options.map((option, index) => {
                    return (
                      <EditComponent value={option} key={index}>
                        {option}
                      </EditComponent>
                    )
                  })}
                </EditComponent.Group>
              ) : (
                false
              )
          }

          if (propType.field === 'FileUploader' || propType.field === 'RichText') {
            // val, key, componentName, position
            field = (
              <EditComponent
                key={index}
                handleInputChange={this.props.handleInputChange}
                value={this.props.pageComponents[this.props.position][propType.name]}
                updatekey={propType.name}
                componentName={this.props.name}
                type={propType.type}
                position={this.props.position}
              />
            )
          }

          return (
            <div key={index}>
              <strong>
                {humanize(propType.name)} <br />
              </strong>
              {field}
            </div>
          )
        })}
      </div>
    )
  }
}

export default PageItemEditor
