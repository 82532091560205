import React from 'react'
import { ContextWrapper } from '../ContextWrapper'
import Footer from './../components/Footer/'

class SiteFooter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <ContextWrapper>
        <Footer siteLinks={this.props.siteLinks} siteSearches={this.props.siteSearches} />
      </ContextWrapper>
    )
  }
}

export default SiteFooter
