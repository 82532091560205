var errors = (obj) => {
  const { validation, validationArgs, message, required } = obj
  let error = ''
  switch (validation) {
    case 'isAlpha':
      error = `Should be alphanumeric. `
      break
    case 'isMobilePhone':
      error = `Should be a valid phone number. `
      break
    case 'isLength':
      if (validationArgs.min && validationArgs.max) {
        error = `Must be between ${validationArgs.min} and ${validationArgs.max} characters`
      } else if (validationArgs.min) {
        error = `Must be more than ${validationArgs.min} characters`
      } else {
        error = `Must be less than ${validationArgs.max} characters`
      }
      break
    case 'matches':
      error = `Doesn't match format`
      break
    default:
      error = message
      break
  }
  return error
}

export default errors
