import React, { useEffect, useRef } from 'react'
import axios from 'axios'
import Vimeo from '@vimeo/player'

const VimeoPlayer = ({ vimeoId, videoId }) => {
  const iframeRef = useRef(null)

  const logVideoWatched = () => {
    let body = {
      id: videoId
    }
    let meta = document.querySelector('meta[name="csrf-token"]')
    const config = {
      headers: {
        'X-CSRF-Token': meta ? meta.content : '',
        'Content-Type': 'application/json',
      },
    }
    axios.post('/watched_videos.json', body, config)
      .then(response => {
        console.log('Rails response', response)
      })
      .catch(error => {
        console.error("Error posting watched video:", error.message)
      })
  }

  useEffect(() => {
    let player
    if (iframeRef.current) {
      player = new Vimeo(iframeRef.current)
      player.on('play', logVideoWatched)
    }

    return () => {
      if (player) {
        player.off('play', logVideoWatched)
        player.destroy().catch(error => console.error('Error destroying player:', error))
      }
    }
  }, [vimeoId, videoId])

  return (
    <iframe
      ref={iframeRef}
      src={`https://player.vimeo.com/video/${vimeoId}?loop=0&title=0&byline=0&portrait=0&badge=0&autopause=0&frameborder=0&vimeo_logo=0`}
      style={{position: 'absolute', top: '0', left: '0', width: "100%", height: "100%", border: 'none'}}
      width="100%"
      height="100%"
      allow="fullscreen; picture-in-picture;"
      allowFullScreen
    ></iframe>
  )
}

export default VimeoPlayer