import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const WysiwygInput = (props) => {
  const onHandleContent = (value) => {
    props.onChange(value)
  }
  return (
    <div>
      <div style={{ maxWidth: '700px', margin: '2rem auto' }}>
        <ReactQuill
          placeholder={props.placeholder ? props.placeholder : 'Start Posting Something'}
          modules={WysiwygInput.modules}
          formats={WysiwygInput.formats}
          onChange={onHandleContent}
          value={props.value}
        />
      </div>
    </div>
  )
}
WysiwygInput.modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ direction: 'rtl' }], // text direction
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ align: [] }],
    ['clean'], // remove formatting button
  ],
}

WysiwygInput.formats = ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'align', 'direction']

export default WysiwygInput
