import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import s from './style.module.scss'

class Heading extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const Tag = this.props.headingLevel ? this.props.headingLevel : 'h1'
    const low = this.props.low
    return (
      <div className={low ? cx(s.root, s.lowHeading) : s.root}>
        <div className="container">
          <Tag>{this.props.title ? this.props.title : this.props.children}</Tag>
        </div>
      </div>
    )
  }
}

Heading.propTypes = {
  title: PropTypes.string,
  headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']),
}

export default Heading
