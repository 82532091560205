import { Col, Row, Pagination } from 'antd'
import _ from 'lodash'
import React from 'react'
import { ContextWrapper } from '../ContextWrapper'
import PropTypes from 'prop-types'
import __ from './../components/Common'
import axios from 'axios'
import VideoIndex from './../components/VideoIndex'


class TutorialVideoIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      videos: this.getVideos(props.videos.videos ? props.videos.videos : []),
      total_pages: props.videos.total_pages || 0,
      current_page: props.videos.current_page || 1,
      loading: false,
    }
  }

  handlePageChange = async (page) => {
    try {
      this.setState({ loading: true })
      const response = await axios.get(`tutorial_videos/new_page.json?page=${page}`)
      this.setState({
        videos: this.getVideos(response.data.videos),
        total_pages: response.data.total_pages,
        current_page: response.data.current_page,
        loading: false,
      })
    } catch(error) { 
      console.error('There was an error fetching the videos:', error);
      this.setState({ loading: false })
    }
  }

  render() {
    const watchedVideos = this.props.watchedVideos
    return (
      <ContextWrapper>
        <div style={{ marginTop: '55px' }} className="container">
          <Row gutter={16} type="flex" align="middle" justify="space-between">
            <Col span={8}>
              <h1 className="noMargin">
                Tutorial Videos
                <br />
              </h1>
            </Col>
            <Col xs={10} sm={6} md={6} lg={6} xl={6}>
              <a href="/guardians" className="button">Guardian Page</a>
            </Col>
          </Row>
          <br />
          { this.props.videos && ( 
            <div>
              <div className="page-divider"></div>
              <div className="list-container">
                <VideoIndex title="Videos" videoList={this.state.videos} watchedVideoIds={watchedVideos} />
              </div>
            </div>
          )}
          <br />
          <br />
          { this.state.total_pages > 1 && 
            <Pagination current={this.state.current_page} total={this.state.total_pages * this.props.videosPerPage} onChange={this.handlePageChange} pageSize={this.props.videosPerPage} />
          }
        </div>
      </ContextWrapper>
    )
  }

  getVideos(videos){
    let arr = []
    if (videos && videos.length > 0) {
      videos.forEach((video) => {
        arr.push({
          name: video.name,
          embedId: video.url_id,
          subtext: video.description,
          id: video.id,
        })
      })
    } else {
      arr = [
        {
          name: '',
          embedId: '',
          subtext: 'No videos to show',
        },
      ]
    }

    return arr
  }
}

TutorialVideoIndex.defaultProps = {
  title: 'Tutorial Videos container title',
}

TutorialVideoIndex.propTypes = {
  title: PropTypes.string,
}

export default TutorialVideoIndex
