import React from 'react'
import s from './style.module.scss'

class Breadcrumbs extends React.Component {
  constructor() {
    super()
    this.state = {
      paths: null,
    }
  }
  componentDidMount() {
    const paths = window.location.pathname
      .split('/')
      .filter(function (entry) {
        return entry.trim() != '' && isNaN(entry)
      })
      .map((item, index) => {
        return item.replace('_', ' ').replace('-', ' ')
      })

    this.setState({
      paths: paths.filter((item) => {
        return item.toLowerCase() != 'home'
      }),
    })
  }

  render() {
    return (
      this.state.paths &&
      this.state.paths.length > 0 && (
        <div className={s.root}>
          <a href="/">Home </a>
          {this.state.paths.map((item, index) => {
            return (
              <div key={`path-${index}`}>
                <span className={s.subPath}>&nbsp;/ </span>
                <span className={index === this.state.paths.length - 1 ? s.lastItem : s.subPath}> {item} </span>
              </div>
            )
          })}
        </div>
      )
    )
  }
}

export default Breadcrumbs
