import { Checkbox, Col, Icon, Modal, notification, Radio, Row, Select, Tooltip } from 'antd'
import axios from 'axios'
import cx from 'classnames'
import $ from 'jquery'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import Dropzone from 'react-dropzone'
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc'
import Input from '../Input'
import Loader from '../Loader'
import MultiInput from '../MultiInput'
import __ from './../Common'
import FamilySection from './../FamilySection'
import s from './style.module.scss'
const uuidv4 = require('uuid/v4')
const serverError =
  "We're sorry, looks like there was an error on our end. Please let us know, and we will do our best to solve the problem!"
const uploadSuccess = 'Upload was successful'
const RadioGroup = Radio.Group
const toCapitalize = [
  'city',
  'firstName',
  'lastName',
  'relationship',
  'type',
  'jobName',
  'role',
  'awards',
  'classAwards',
]
let submitting = false

function getParameterByName(name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const statsObj = {
  height: {
    ft: '',
    inches: '0',
  },
  weight: '',
  size: '',
  shoeSize: '',
  napeToCrotch: '',
  napeToAnkle: '',
  napeToWrist: '',
  inseam: '',
  chest: '',
  waist: '',
}

const expObjArr = [
  {
    jobName: '',
    category: '',
    soc: '',
    role: '',
    location: '',
    year: '',
  },
]

const awardObjArr = [
  {
    type: '',
    year: '',
  },
]

const otherParentsObj = [
  {
    address: '',
    city: '',
    email: '',
    firstName: '',
    lastName: '',
    postalCode: '',
    preferred: true,
    primaryPhone: '',
    province: '',
    relationship: '',
    secondaryAddress: '',
    secondaryPhone: '',
    tertiaryPhone: '',
  },
]

const Asterix = ({ inline, white }) => {
  let style = {}
  white ? (style['color'] = '#fff') : null
  return (
    <span style={style} className={inline ? cx(s.required, s.inline) : s.required}>
      *
    </span>
  )
}

class ProfileForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: 0,
      submitting: false,
      rating: null,
      csrf: null,
      badFields: [],
      allergies: null,
      modeledFor: null,
      cleanFields: null,
      uuid: null,
      firstName: null,
      middleName: null,
      lastName: null,
      nickName: null,
      primary: false,
      dateOfBirth: {
        year: null,
        month: null,
        day: null,
      },
      gender: '',
      transgender: false,
      hasTwin: false,
      twinType: '',
      sinPresent: false,
      sin: false,
      actra: false,
      actraApprenticeNumber: '',
      actraApprenticeFullNumber: '',
      union: false,
      passport: false,
      citizenship: '',
      dualCitizen: false,
      dualCitizenship: '',
      otherParents: [
        {
          address: '',
          city: '',
          email: '',
          firstName: '',
          lastName: '',
          postalCode: '',
          preferred: true,
          primaryPhone: '',
          province: '',
          relationship: '',
          secondaryAddress: '',
          secondaryPhone: '',
          tertiaryPhone: '',
        },
      ],
      stats: {
        height: {
          ft: '',
          inches: '0',
        },
        weight: '',
        size: '',
        shoeSize: '',
        napeToCrotch: '',
        napeToAnkle: '',
        napeToWrist: '',
        inseam: '',
        chest: '',
        waist: '',
      },
      hairColour: '',
      hairTexture: '',
      hairLength: '',
      eyeColor: '',
      missingTeeth: false,
      hasBraces: false,
      babySkills: [],
      familyOrigin: [],
      ethnicity: [],
      languages: {
        // armenian: 0 -> langName: level
      },
      specialSkills: {
        // skillName: level
      },
      awards: [
        {
          type: '',
          year: '',
        },
      ],
      classAwards: [
        {
          type: '',
          year: '',
        },
      ],
      performanceSkills: {},
      specialNeeds: {
        //[{type: '', requirements: ''}],
        // need: 'explanation'
      },
      experience: [
        {
          jobName: '',
          category: '',
          soc: '',
          role: '',
          location: '',
          year: '',
        },
      ],
      about: '',
      images: [],
      hand_images: [],
      audio_files: [],
      preferredImageId: null,
      statsUnchangedUsed: false,
    }

    this.incrementStep = this.incrementStep.bind(this)
    this.decrementStep = this.decrementStep.bind(this)
    this.renderStep1 = this.renderStep1.bind(this)
    this.renderStep2 = this.renderStep2.bind(this)
    this.renderStep3 = this.renderStep3.bind(this)
    this.addNewField = this.addNewField.bind(this)

    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.postBodyGenerator = this.postBodyGenerator.bind(this)

    // STATE SETTING FUNCTIONS:
    this.setNestedLevel = this.setNestedLevel.bind(this)
    this.setNested = this.setNested.bind(this)
    this.setPrimary = this.setPrimary.bind(this)
    this.setDOB = this.setDOB.bind(this)
    this.setBaseState = this.setBaseState.bind(this)
    this.setProfile = this.setProfile.bind(this)
    this.checkPreferred = this.checkPreferred.bind(this)
  }

  componentDidUpdate() {
    if (!this.props.editMode) {
      localStorage.setItem('__MID__profileform', JSON.stringify(this.state))
    }
  }

  componentDidMount() {
    this.setBaseState()
    if (this.props.profile) {
      // EDit Profile Props
      this.setProfile(this.props.profile)
    } else {
      // Need to push cookie into state:
      // let cookieProfile = Cookies.get('__MID__profileform');
      // console.log('cookieProfile: ', __.parseIfString(cookieProfile))
      if (!this.props.editMode) {
        this.setProfileFromStorage(__.parseIfString(localStorage['__MID__profileform']))
      }
    }
    if (typeof window !== 'undefined' && window.location.search) {
      if (getParameterByName('upload')) {
        this.setState({ step: 3 }, () => {
          const topOffset = window.innerWidth > 768 ? 300 : 100
          $('html, body').animate({ scrollTop: $(this.refs.uploadsContainer).offset().top - topOffset }, 1000)
        })
      }
    }
    if (typeof window !== 'undefined' && window) {
      window.leaveSiteWarning = function (e) {
        if (submitting === false) {
          var confirmationMessage = 'o/'
          e.returnValue = confirmationMessage // Gecko, Trident, Chrome 34+
          return confirmationMessage // Gecko, WebKit, Chrome <34
        }
      }
      window.addEventListener('beforeunload', window.leaveSiteWarning)
    }
  }

  /************************
    UPDATE & API CALLS
  *************************/

  checkPreferred(id, check) {
    let _images = this.state.images.map((item, index) => {
      return {
        created: item.created,
        file_size: item.file_size ? item.file_size : null,
        id: item.id,
        url: item.url,
        ...(id === item.id && { preferred: true }),
      }
    })

    this.setState({
      preferredImageId: check ? null : id,
      images: _images,
    })
  }

  handleFormSubmit() {
    submitting = true
    if (this.state.submitting) return // ALready submitting, prevent multiple submissions
    this.setState({
      submitting: true,
    })
    let body = this.postBodyGenerator()
    let meta = document.querySelector('meta[name="csrf-token"]')
    const config = {
      headers: {
        'X-CSRF-Token': meta.content,
        'Content-Type': 'application/json',
      },
    }
    let _this = this
    const path = this.props.paths
    // determine whether to post to new profile path or edit profile path:
    const postPath = this.props.profile ? `/profiles/${this.props.profile.id}` : path.profilePost
    const method = this.props.profile ? 'put' : 'post'
    const errorObj = this.checkRequired(this.state.step)
    errorObj.safe
      ? axios[method](`${postPath}.json`, body, config)
          .then(
            function (response) {
              console.log('rails response', response)
              localStorage.removeItem('__MID__profileform')
              if (typeof window !== 'undefined') {
                window.location.replace(path.redirectPath)
              }
            },
            function (error) {
              _this.setState({ submitting: false })
              throw new Error(error.message)
            }
          )
          .catch(function (error) {
            submitting = false
            _this.setState({ submitting: false }, () => {
              notification.open({
                message: 'Error',
                description: 'Opps something went wrong. Please try again in a bit.',
                icon: <Icon type="warning" style={{ color: 'red' }} />,
              })
            })
            throw new Error(error.message)
          })
      : this.errorModal(errorObj)
  }

  onAudioFileDrop(files) {
    const csrf = this.state.csrf
    let _state = _.cloneDeep(this.state)
    let formData = new FormData()
    formData.append('audio_upload[item]', files[0])
    formData.append('audio_upload[parent_uuid]', this.state.uuid)
    formData.append('audio_upload[upload_type]', 'audio')
    this.setState({ audioLoading: true })
    $.ajax({
      url: `${this.props.paths.audioUpload}.json`,
      method: 'POST',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', csrf)
      },
      contentType: false,
      processData: false,
      data: formData,
      error: (err) => {
        _state.imageLoading = false
        this.setState(_state)
        this.displayUploadError(null, serverError)
        throw new Error(err.statusText)
      },
      success: (responsedata) => {
        if (responsedata.id) {
          let _state = _.cloneDeep(this.state)
          _state.audio_files.push({ id: responsedata.id, url: responsedata.item.url })
          _state.audioLoading = false
          this.setState(_state)
          notification.open({
            message: uploadSuccess,
          })
        } else {
          _state.audioLoading = false
          this.setState(_state)
          this.displayUploadError(responsedata.item)
        }
      },
    })
  }

  setStatsUnchanged() {
    const path = `/stats_unchanged/${this.props.profile.id}.json`
    let body = this.postBodyGenerator()
    let meta = document.querySelector('meta[name="csrf-token"]')
    let _this = this
    this.setState({ submitting: true })
    const config = {
      headers: {
        'X-CSRF-Token': meta.content,
        'Content-Type': 'application/json',
      },
    }
    axios['post'](path, body, config)
      .then(function (response) {
        notification.open({
          message: 'Success',
          description: "You have checked in that your child's stats have not changed since your last update.",
          icon: <Icon type="warning" style={{ color: 'red' }} />,
        })
        window.removeEventListener('beforeunload', window.leaveSiteWarning)
        window.location.replace(_this.props.paths.redirectPath)
      })
      .catch(function (error) {
        console.log(error)
        _this.setState({ submitting: false }, () => {
          notification.open({
            message: 'Error',
            description: 'Opps something went wrong. Please try again in a bit.',
            icon: <Icon type="warning" style={{ color: 'red' }} />,
          })
        })
        throw new Error(error.message)
      })
  }

  onHandImageDrop(files) {
    const csrf = this.state.csrf
    let _state = _.cloneDeep(this.state)
    let formData = new FormData()
    formData.append('image_upload[items]', files[0])
    formData.append('image_upload[parent_uuid]', this.state.uuid)
    formData.append('image_upload[upload_type]', 'hand')

    this.setState({ handLoading: true })
    $.ajax({
      url: `${this.props.paths.imageUpload}.json`,
      method: 'POST',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', csrf)
      },
      contentType: false,
      processData: false,
      data: formData,
      error: (err) => {
        _state.imageLoading = false
        this.setState(_state)
        console.log('HERE', err)
        this.displayUploadError(null, serverError)
        throw new Error(err.statusText)
      },
      success: (responsedata) => {
        if (responsedata.id) {
          let _state = _.cloneDeep(this.state)
          _state.hand_images.push({
            id: responsedata.id,
            url: responsedata.items.url,
            created: responsedata.created_at,
          })
          _state.handLoading = false
          this.setState(_state)
          notification.open({
            message: uploadSuccess,
          })
        } else {
          // const msgs = responsedata
          _state.handLoading = false
          this.setState(_state)
          this.displayUploadError(responsedata.items)
        }
      },
    })
  }

  onDrop(files) {
    const csrf = this.state.csrf
    let _state = _.cloneDeep(this.state)
    let formData = new FormData()
    formData.append('image_upload[items]', files[0])
    formData.append('image_upload[parent_uuid]', this.state.uuid)
    formData.append('image_upload[upload_type]', 'photo')
    this.setState({ imageLoading: true })
    $.ajax({
      url: `${this.props.paths.imageUpload}.json`,
      method: 'POST',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', csrf)
      },
      contentType: false,
      processData: false,
      data: formData,
      xhr: () => {
        var xhr = new window.XMLHttpRequest()
        var percentComplete = 0
        console.log(percentComplete)
        xhr.upload.addEventListener(
          'progress',
          function (evt) {
            if (evt.lengthComputable) {
              percentComplete = evt.loaded / evt.total
              percentComplete = parseInt(percentComplete * 100)
              console.log(percentComplete)
            }
          },
          false
        )
        return xhr
      },
      error: (err) => {
        _state.imageLoading = false
        this.setState(_state)
        this.displayUploadError(null, serverError)
        throw new Error(err.statusText)
      },
      success: (responsedata) => {
        if (responsedata.id) {
          let _state = _.cloneDeep(this.state)
          _state.images.push({
            id: responsedata.id,
            url: responsedata.items.url,
            created: responsedata.created_at,
            file_size: responsedata.human_size,
          })
          _state.imageLoading = false
          this.setState(_state)
          notification.open({
            message: uploadSuccess,
            // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
          })
        } else {
          _state.imageLoading = false
          this.setState(_state)
          this.displayUploadError(responsedata.items)
        }
      },
    })
  }

  postBodyGenerator() {
    let s = this.state
    let admin = this.props.current_user && this.props.current_user.role === 'admin'
    let dob = s.dateOfBirth

    return {
      profile: {
        rating: s.rating,
        uuid: s.uuid,
        first_name: s.firstName,
        middle_name: s.middleName,
        last_name: s.lastName,
        nick_name: s.nickName,
        about: s.about,
        primary: s.primary,
        date_of_birth: dob.year + '/' + dob.month + '/' + dob.day,
        gender: _.capitalize(s.gender),
        transgender: s.transgender,
        has_twin: s.hasTwin,
        twin_type: s.twinType,
        sin_present: s.sinPresent,
        sin: s.sin,
        actra_present: s.actra,
        actra_apprentice_number: s.actraApprenticeNumber,
        actra_apprentice_full_number: s.actraApprenticeFullNumber,
        union: s.union,
        passport: s.passport,
        citizenship: s.citizenship,
        dual_citizen: s.dualCitizen,
        dual_citizenship: s.dualCitizenship,
        hair_color: s.hairColour,
        hair_texture: s.hairTexture,
        hair_length: s.hairLength,
        eye_color: s.eyeColor,
        missing_teeth: s.missingTeeth,
        has_braces: s.hasBraces,
        other_parents: JSON.stringify(s.otherParents),
        stats: JSON.stringify(s.stats),
        baby_skills: JSON.stringify(s.babySkills),
        family_origin: JSON.stringify(s.familyOrigin),
        allergies: s.allergies ?? '',
        modeled_for: s.modeledFor ?? '',
        ethnicity: JSON.stringify(s.ethnicity),
        languages: JSON.stringify(s.languages),
        special_skills: JSON.stringify(s.specialSkills),
        awards: JSON.stringify(s.awards),
        class_awards: JSON.stringify(s.classAwards),
        performance_skills: JSON.stringify(s.performanceSkills),
        special_needs: JSON.stringify(s.specialNeeds),
        experience: JSON.stringify(s.experience),
        images: JSON.stringify(s.images),
        hand_images: JSON.stringify(s.hand_images),
        audio_files: JSON.stringify(s.audio_files),
      },
    }
  }

  /************************
    STATE-SETTING FUNCTIONS
  *************************/

  addNewField(keyType) {
    let keyToUpdate = _.cloneDeep(this.state[keyType])
    let item = _.cloneDeep(keyToUpdate[0])
    Object.keys(item).map((key, index) => {
      item[key] = ''
    })
    keyToUpdate.push(item)
    this.setState({
      [keyType]: keyToUpdate,
    })
  }

  onAudioSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      audio_files: arrayMove(this.state.audio_files, oldIndex, newIndex),
    })
  }

  onHandImageSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      hand_images: arrayMove(this.state.hand_images, oldIndex, newIndex),
    })
  }

  onImageSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      images: arrayMove(this.state.images, oldIndex, newIndex),
    })
  }

  removeImage(index, key) {
    let _state = _.cloneDeep(this.state)
    _state[key].splice(index, 1)
    this.setState(_state)
  }

  removeThisField(keyType, index) {
    let keyToUpdate = _.cloneDeep(this.state[keyType])
    keyToUpdate.splice(index, 1)
    this.setState({
      [keyType]: keyToUpdate,
    })
  }

  setBaseState() {
    const meta = document.querySelector('meta[name="csrf-token"]')
    const step = this.state.step ? this.state.step : this.props.step
    this.setState({
      csrf: meta.content,
      step: step,
    })
  }

  setDOB(type, e) {
    let update = e.target.value
    let dob = this.state.dateOfBirth
    switch (type) {
      case 'year':
        dob.year = update
        break
      case 'month':
        dob.month = update
        break
      case 'day':
        dob.day = update
        break
    }
    this.setState({
      dateOfBirth: dob,
    })
  }

  setInput(key, value) {
    let _state = _.cloneDeep(this.state)
    _.set(_state, key, value)
    this.setState(_state)
  }

  setMultiSelect(input, keyToUpdate) {
    let stateObj = this.state[keyToUpdate]
    let newObj = {}
    input.forEach((item, index) => {
      if (keyToUpdate == 'languages' && item === 'OTHER') {
        newObj[item.replace(/[0-9]/g, '').trim()] = ''
      } else {
        newObj[item.replace(/[0-9]/g, '').trim()] = Number(item.replace(/[^0-9]/g, ''))
      }
    })
    this.setState({
      [keyToUpdate]: newObj,
    })
  }

  setNested(input, keyToUpdate) {
    let newObj = {}
    let self = this
    let stateObj = self.state[keyToUpdate]
    input.forEach((item) => {
      if (!stateObj[item]) {
        if (keyToUpdate === 'specialNeeds') {
          newObj[item] = ''
        } else {
          newObj[item] = 0
        }
      } else {
        newObj[item] = stateObj[item]
      }
    })
    this.setState({
      [keyToUpdate]: newObj,
    })
  }

  setNestedLevel(level, item, keyToUpdate) {
    let newObj = _.cloneDeep(this.state[keyToUpdate])
    newObj[item] = level
    this.setState({
      [keyToUpdate]: newObj,
    })
  }

  setPrimary() {
    let primary = this.state.primary
    this.setState({
      primary: !primary,
    })
  }

  setProfile(p) {
    const _images = p.images ? __.parseIfString(p.images) : []
    let preferredImageId =
      _images &&
      _images.find((item, index) => {
        return item.preferred == true
      })
        ? _images.find((item, index) => {
            return item.preferred == true
          })['id']
        : null
    this.setState({
      rating: p.rating ? p.rating : null,
      uuid: p.uuid ? p.uuid : uuidv4(),
      allergies: p.allergies ? p.allergies : '',
      modeledFor: p.modeled_for ? p.modeled_for : '',
      firstName: p.first_name ? p.first_name : null,
      middleName: p.middle_name ? p.middle_name : null,
      lastName: p.last_name ? p.last_name : null,
      nickName: p.nick_name ? p.nick_name : null,
      primary: p.primary && p.primary == '1' ? true : false,
      dateOfBirth: {
        year: p.date_of_birth && this.props.dob ? this.props.dob.year : null,
        month: p.date_of_birth && this.props.dob ? this.props.dob.month : null,
        day: p.date_of_birth && this.props.dob ? this.props.dob.day : null,
      },
      gender: p.gender ? p.gender : '',
      transgender: p.transgender ? p.transgender : false,
      sinPresent: p.sin_present ? p.sin_present : false,
      sin: p.sin ? p.sin : false,
      actra: p.actra_present ? p.actra_present : false,
      actraApprenticeNumber: p.actra_apprentice_number ? p.actra_apprentice_number : '',
      actraApprenticeFullNumber: p.actra_apprentice_full_number ? p.actra_apprentice_full_number : '',
      union: p.union ? p.union : false,
      hasTwin: p.has_twin ? p.has_twin : false,
      twinType: p.twin_type ? p.twin_type : null,
      passport: p.passport ? p.passport : false,
      citizenship: p.citizenship ? p.citizenship : '',
      dualCitizen: p.dual_citizen ? p.dual_citizen : false,
      dualCitizenship: p.dual_citizenship ? p.dual_citizenship : '',
      otherParents:
        p.other_parents && __.parseIfString(p.other_parents).length >= 1
          ? __.parseIfString(p.other_parents)
          : otherParentsObj,
      stats: p.stats ? __.parseIfString(p.stats) : statsObj,
      hairColour: p.hair_color ? p.hair_color : '',
      hairTexture: p.hair_texture ? p.hair_texture : '',
      hairLength: p.hair_length ? p.hair_length : '',
      eyeColor: p.eye_color ? p.eye_color : '',
      missingTeeth: p.missing_teeth ? p.missing_teeth : false,
      hasBraces: p.has_braces ? p.has_braces : false,
      babySkills: p.baby_skills ? __.parseIfString(p.baby_skills) : [],
      familyOrigin: p.family_origin ? __.parseIfString(p.family_origin) : [],
      ethnicity: p.ethnicity ? __.parseIfString(p.ethnicity) : [],
      languages: p.languages ? __.parseIfString(p.languages) : {},
      specialSkills: p.special_skills ? __.parseIfString(p.special_skills) : {},
      experience: p.experience ? __.parseIfString(p.experience) : expObjArr,
      awards: p.awards ? __.parseIfString(p.awards) : awardObjArr,
      classAwards: p.class_awards ? __.parseIfString(p.class_awards) : awardObjArr,
      performanceSkills: p.performance_skills ? __.parseIfString(p.performance_skills) : {},
      specialNeeds: p.special_needs ? __.parseIfString(p.special_needs) : {},
      about: p.about ? p.about : '',
      images: _images,
      hand_images: p.hand_images ? __.parseIfString(p.hand_images) : [],
      audio_files: p.audio_files ? __.parseIfString(p.audio_files) : [],
      preferredImageId: preferredImageId,
    })
  }

  setProfileFromStorage(profile) {
    if (profile) {
      const _images = profile.images ? __.parseIfString(profile.images) : []
      let preferredImageId =
        _images &&
        _images.find((item, index) => {
          return item.preferred == true
        })
          ? _images.find((item, index) => {
              return item.preferred == true
            })['id']
          : null
      this.setState({
        firstName: profile.firstName ? profile.firstName : null,
        middleName: profile.middleName ? profile.middleName : null,
        allergies: profile.allergies ? profile.allergies : null,
        modeledFor: profile.modeledFor ? profile.modeledFor : null,
        uuid: profile.uuid ? profile.uuid : uuidv4(),
        lastName: profile.lastName ? profile.lastName : null,
        nickName: profile.nickName ? profile.nickName : null,
        primary: profile.primary && profile.primary == '1' ? true : false,
        dateOfBirth: {
          year: profile.dateOfBirth.year ? profile.dateOfBirth.year : null,
          month: profile.dateOfBirth.month ? profile.dateOfBirth.month : null,
          day: profile.dateOfBirth.day ? profile.dateOfBirth.day : null,
        },
        gender: profile.gender ? profile.gender : '',
        transgender: profile.transgender ? profile.transgender : false,
        sinPresent: profile.sinPresent ? profile.sinPresent : false,
        sin: profile.sin ? profile.sin : false,
        actra: profile.actra ? profile.actra : false,
        actraApprenticeNumber: profile.actraApprenticeNumber ? profile.actraApprenticeNumber : '',
        actraApprenticeFullNumber: profile.actraApprenticeFullNumber ? profile.actraApprenticeFullNumber : '',
        union: profile.union ? profile.union : false,
        hasTwin: profile.hasTwin ? profile.hasTwin : false,
        twinType: profile.twinType ? profile.twinType : null,
        passport: profile.passport ? profile.passport : false,
        citizenship: profile.citizenship ? profile.citizenship : '',
        dualCitizen: profile.dualCitizen ? profile.dualCitizen : false,
        dualCitizenship: profile.dualCitizenship ? profile.dualCitizenship : '',
        otherParents:
          profile.otherParents && __.parseIfString(profile.otherParents).length >= 1
            ? __.parseIfString(profile.otherParents)
            : otherParentsObj,
        stats: profile.stats ? __.parseIfString(profile.stats) : statsObj,
        hairColour: profile.hairColour ? profile.hairColour : '',
        hairTexture: profile.hairTexture ? profile.hairTexture : '',
        hairLength: profile.hairLength ? profile.hairLength : '',
        eyeColor: profile.eyeColor ? profile.eyeColor : '',
        missingTeeth: profile.missingTeeth ? profile.missingTeeth : false,
        hasBraces: profile.hasBraces ? profile.hasBraces : false,
        babySkills: profile.babySkills ? __.parseIfString(profile.babySkills) : [],
        familyOrigin: profile.familyOrigin ? __.parseIfString(profile.familyOrigin) : [],
        ethnicity: profile.ethnicity ? __.parseIfString(profile.ethnicity) : [],
        languages: profile.languages ? __.parseIfString(profile.languages) : {},
        specialSkills: profile.specialSkills ? __.parseIfString(profile.specialSkills) : {},
        experience: profile.experience ? __.parseIfString(profile.experience) : expObjArr,
        awards: profile.awards ? __.parseIfString(profile.awards) : awardObjArr,
        classAwards: profile.classAwards ? __.parseIfString(profile.classAwards) : awardObjArr,
        performanceSkills: profile.performanceSkills ? __.parseIfString(profile.performanceSkills) : {},
        specialNeeds: profile.specialNeeds ? __.parseIfString(profile.specialNeeds) : {},
        about: profile.about ? profile.about : '',
        images: profile.images ? __.parseIfString(profile.images) : [],
        hand_images: profile.hand_images ? __.parseIfString(profile.hand_images) : [],
        audio_files: profile.audio_files ? __.parseIfString(profile.audio_files) : [],
        preferredImageId: preferredImageId,
      })
    }
  }

  /****************
    HELPER METHODS
  ****************/

  ageUnderThree(y, m, d) {
    if (d && m && y) {
      const day = d / 1 < 10 ? '0' + d / 1 : d / 1
      const month = m / 1 < 10 ? '0' + m / 1 : m / 1
      const year = y
      return __.ageInMonths(`${year}${month}${day}`) < 36
    }
    return false
  }

  handleStep(step) {
    if (step > this.state.step && this.props.current_user && this.props.current_user.role != 'admin') {
      let errorObj = this.checkRequired(this.state.step)
      if (errorObj.safe) {
        this.setState({ step: step })
      } else {
        this.errorModal(errorObj)
      }
    } else {
      this.setState({ step: step })
    }
  }

  checkRequired(step) {
    const _state = _.cloneDeep(this.state)
    // const otherParents = [`otherParents[${0}].firstName`, `otherParents[${0}].lastName`, `otherParents[${0}].primaryPhone`, `otherParents[${0}].email`, `otherParents[${0}].postalCode`]
    const stats = this.ageUnderThree(_state.dateOfBirth.year, _state.dateOfBirth.month, _state.dateOfBirth.day)
      ? [
          'stats.height.ft',
          'stats.height.inches',
          'stats.weight',
          'stats.size',
          'stats.shoeSize',
          'stats.napeToCrotch',
          'stats.napeToAnkle',
          'stats.napeToWrist',
          'hairColour',
          'hairTexture',
          'eyeColor',
          'hairLength',
          'babySkills',
          'ethnicity',
          'familyOrigin',
        ]
      : [
          'stats.height.ft',
          'stats.height.inches',
          'stats.size',
          'stats.shoeSize',
          'stats.inseam',
          'stats.chest',
          'stats.waist',
          'hairColour',
          'hairTexture',
          'eyeColor',
          'hairLength',
          'ethnicity',
          'familyOrigin',
        ]
    const requiredFields = [
      [
        'firstName',
        'lastName',
        'gender',
        'dateOfBirth.day',
        'dateOfBirth.month',
        'dateOfBirth.year',
        'citizenship',
        `otherParents[${0}].firstName`,
        `otherParents[${0}].lastName`,
        `otherParents[${0}].primaryPhone`,
        `otherParents[${0}].email`,
        `otherParents[${0}].postalCode`,
      ],
      stats,
      [],
      [],
    ]
    let cleanFields = []
    let badFields = []
    let birthDateMsg = []
    // let keys = []
    requiredFields[step].forEach((stateKey) => {
      const value = _.get(_state, stateKey)
      const valueEmpty = _.isEmpty(value)

      switch (stateKey) {
        case 'dateOfBirth.day':
          if (Number(value) >= 1 && Number(value) <= 31) {
            cleanFields.push(false)
          } else {
            birthDateMsg.push('Please make sure you date of birth day is between 1 and 31')
            cleanFields.push(true)
          }
          break
        case 'dateOfBirth.month':
          if (Number(value) && Number(value) >= 1 && Number(value) <= 12) {
            cleanFields.push(false)
          } else {
            birthDateMsg.push('Please make sure you date of birth month is between 1 and 12')
            cleanFields.push(true)
          }
          break
        case 'dateOfBirth.year':
          if (Number(value) >= 1900) {
            cleanFields.push(false)
          } else {
            birthDateMsg.push('Please make sure you date of birth year is at least 1900')
            cleanFields.push(true)
          }
          break
      }
      if (value === false) {
        cleanFields.push(false)
      } else if (typeof value === 'number') {
        cleanFields.push(false)
      } else {
        cleanFields.push(valueEmpty)
        if (valueEmpty) {
          badFields.push(stateKey)
        }
      }
    })
    return {
      safe: cleanFields.indexOf(true) === -1,
      badFields: badFields,
      cleanFields: cleanFields,
      birthDateMsg: birthDateMsg,
    }
  }

  extractLevelsFromProps(propItems) {
    let arr = []
    propItems.forEach((item, index) => {
      arr.push(`${item} 0`)
      arr.push(`${item} 1`)
      arr.push(`${item} 2`)
    })
    return arr
  }

  shouldCancelStart(e) {
    // console.log(e.target);
    // Cancel sorting if the event target is an anchor tag (`a`)
    if (
      e.target.tagName.toLowerCase() === 'a' ||
      e.target.tagName.toLowerCase() === 'i' ||
      e.target.tagName.toLowerCase() == 'input'
    ) {
      return true // Return true to cancel sorting
    }
  }

  spacerFix(stateItem, hideOnDesktop = false) {
    if (stateItem && this.state[stateItem] && this.state[stateItem].length >= 1) {
      return (
        <div className={hideOnDesktop ? 'hide-on-desktop' : ''}>
          {this.state[stateItem] &&
            Object.keys(this.state[stateItem]).map((item, index) => {
              return <div key={`${stateItem}-${index}`} className={s.spacerHack}></div>
            })}
        </div>
      )
    }
  }

  bigSpacerFix(stateItem) {
    return (
      this.state[stateItem] &&
      Object.keys(this.state[stateItem]).map((need, index) => {
        return <div key={`hack-${index}`} className={cx(s.spacerHack, 'big')}></div>
      })
    )
  }

  /****************
    RENDER METHODS
  ****************/

  decrementStep() {
    this.setState(
      {
        step: this.state.step - 1,
      },
      window.scroll(0, 0)
    )
  }

  displayUploadError(arr, string) {
    let content
    if (arr) {
      content = arr.map((item, index) => {
        return <div key={index}>{item}</div>
      })
    } else if (string) {
      content = string
    } else {
      content = ''
    }
    Modal.info({
      title: 'Error',
      okText: 'Okay',
      content: content,
    })
  }

  errorModal(errorObj) {
    this.setState(
      {
        badFields: errorObj.badFields,
      },
      () => {
        _.startCase('testThis')
        Modal.info({
          title: 'Please fill out all required fields',
          okText: 'Okay',
          content: (
            <div>
              {errorObj.badFields && errorObj.badFields.length >= 1 && <em>Missing: </em>}
              <ul>
                {errorObj.badFields &&
                  errorObj.badFields.map((field, index) => {
                    return (
                      <li style={{ listStyle: 'disc' }} key={`field-${index}`}>
                        {_.startCase(field).replace('.', ': ').replace(' 0 ', ': ')}
                      </li>
                    )
                  })}
                {errorObj.birthDateMsg &&
                  errorObj.birthDateMsg.map((message, index) => {
                    return (
                      <li style={{ listStyle: 'disc' }} key={`message-${index}`}>
                        {message}
                      </li>
                    )
                  })}
              </ul>
            </div>
          ),
        })
      }
    )
  }

  handleStep(step) {
    if (step > this.state.step && this.props.current_user && this.props.current_user.role != 'admin') {
      let errorObj = this.checkRequired(this.state.step)
      if (errorObj.safe) {
        this.setState({ step: step })
      } else {
        console.log('errObj: ', errorObj)
        this.errorModal(errorObj)
      }
    } else {
      this.setState({ step: step })
    }
  }

  incrementStep() {
    const errorObj = this.checkRequired(this.state.step)
    if (errorObj.safe) {
      this.setState(
        {
          step: this.state.step + 1,
        },
        window.scroll(0, 0)
      )
    } else {
      this.errorModal(errorObj)
    }
  }

  renderStep1() {
    return (
      <div className="container">
        <Row gutter={16}>
          <Col span={24} md={16}>
            <div className={s.formTitle}>Child's Name</div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={4}>
            <Asterix />
            <Input
              ref="firstName"
              value={this.state.firstName ? this.state.firstName : ''}
              stateKey="firstName"
              required={true}
              className={this.state.badFields.includes('firstName') ? 'error-field' : ''}
              onChange={this.setInput.bind(this)}
              placeholder="First"
            />
          </Col>
          <Col span={24} md={4}>
            <Input
              ref="middleName"
              value={this.state.middleName ? this.state.middleName : ''}
              stateKey="middleName"
              onChange={this.setInput.bind(this)}
              placeholder="Middle"
            />
          </Col>
          <Col span={24} md={4}>
            <Asterix />
            <Input
              ref="lastName"
              value={this.state.lastName ? this.state.lastName : ''}
              stateKey="lastName"
              className={this.state.badFields.includes('lastName') ? 'error-field' : ''}
              required={true}
              onChange={this.setInput.bind(this)}
              placeholder="Last"
            />
          </Col>
          <Col span={24} md={4}>
            <Input
              ref="nickName"
              value={this.state.nickName ? this.state.nickName : ''}
              stateKey="nickName"
              onChange={this.setInput.bind(this)}
              placeholder="Nickname"
            />
            <Checkbox ref="primary" checked={this.state.primary} onChange={this.setPrimary}>
              Set nickname as Primary
            </Checkbox>
            <div className="hide-on-desktop">
              <br />
              <br />
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={8}>
            <div className={s.formTitle}>Date Of Birth</div>
          </Col>
          <Col span={24} md={8}>
            <div className={cx(s.formTitle, 'hide-on-mobile')}>Gender</div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={4}>
            <p className={s.labelText}>
              <Asterix inline={true} /> Year
            </p>
            <Input
              stateKey="dateOfBirth.year"
              validate="isNumeric"
              required={true}
              type="number"
              min="1900"
              className={this.state.badFields.includes('dateOfBirth.year') ? 'error-field' : ''}
              max={`${new Date().getFullYear()}`}
              onChange={this.setInput.bind(this)}
              value={this.state.dateOfBirth.year ? this.state.dateOfBirth.year : ''}
              placeholder="YYYY"
            />
          </Col>
          <Col span={24} md={2}>
            <p className={s.labelText}>
              <Asterix inline={true} /> Month
            </p>
            <Input
              stateKey="dateOfBirth.month"
              validate="isNumeric"
              required={true}
              type="number"
              className={this.state.badFields.includes('dateOfBirth.month') ? 'error-field' : ''}
              min="1"
              max="12"
              onChange={this.setInput.bind(this)}
              value={this.state.dateOfBirth.month ? this.state.dateOfBirth.month : ''}
              placeholder="MM"
            />
          </Col>
          <Col span={24} md={2}>
            <p className={s.labelText}>
              <Asterix inline={true} /> Day
            </p>
            <Input
              stateKey="dateOfBirth.day"
              validate="isNumeric"
              required={true}
              type="number"
              className={this.state.badFields.includes('dateOfBirth.day') ? 'error-field' : ''}
              min="1"
              max="31"
              onChange={this.setInput.bind(this)}
              value={this.state.dateOfBirth.day ? this.state.dateOfBirth.day : ''}
              placeholder="DD"
            />
          </Col>
          <Col span={24} md={4}>
            <div className={cx(s.formTitle, 'hide-on-desktop')}>Gender</div>
            <p className={s.labelText}>&nbsp;</p>
            <Select
              notFoundContent="Not Found"
              showSearch
              style={{ width: '100%' }}
              placeholder="Choose"
              stateKey="gender"
              className={this.state.badFields.includes('gender') ? 'error-field' : ''}
              value={this.state.gender}
              onChange={(e) => this.setInput('gender', e)}
              optionFilterProp="children"
            >
              {this.props.genders &&
                this.props.genders.map((gender, index) => {
                  return (
                    <Select.Option key={gender} value={gender}>
                      {gender}
                    </Select.Option>
                  )
                })}
            </Select>
          </Col>
          <Col span={24} md={4}>
            <p className={s.labelText}>&nbsp;</p>
            <Checkbox
              ref="hasTwin"
              checked={this.state.hasTwin}
              onChange={() => {
                const hasTwin = !this.state.hasTwin
                if (!hasTwin) {
                  this.setState({ twinType: null })
                }
                this.setState({ hasTwin: hasTwin })
              }}
            >
              Has Twin
            </Checkbox>
            {this.state.hasTwin && (
              <RadioGroup
                ref="twinType"
                onChange={(e) => this.setInput('twinType', e.target.value)}
                value={this.state.twinType}
              >
                <Radio value={'identical'} style={{ marginBottom: '10px', marginTop: '10px' }}>
                  Identical
                </Radio>
                <Radio value={'fraternal'} style={{ marginBottom: '10px' }}>
                  Fraternal
                </Radio>
              </RadioGroup>
            )}
            <p className={s.explanation}>Does this child have a twin available to work with?</p>
          </Col>
        </Row>
        <div className="hide-on-desktop">
          <br />
          <br />
        </div>
        <Row gutter={24}>
          <Col span={24} md={8}>
            <div className={s.formTitle}>SIN (child's)</div>
            <Asterix />
            <RadioGroup
              ref="sinPresent"
              onChange={(e) => this.setInput('sinPresent', e.target.value)}
              value={this.state.sinPresent}
              className="square-radio"
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
              <Radio value={null}>Not Specified</Radio>
            </RadioGroup>
            {this.state.sinPresent && (
              <Input
                mask="999-999-999"
                stateKey="sin"
                validate="matches"
                validateArgs={[/^[0-9]{3}-[0-9]{3}-[0-9]{3}$/]}
                required={true}
                onChange={this.setInput.bind(this)}
                value={this.state.sin ? this.state.sin : ''}
                placeholder="Sin Number"
              />
            )}
          </Col>
          <Col span={24} md={4}>
            <div className={s.formTitle}>Actra</div>
            <Asterix />
            <RadioGroup
              ref="actra"
              onChange={(e) => this.setInput('actra', e.target.value)}
              value={this.state.actra}
              className="square-radio"
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </RadioGroup>
          </Col>
          {this.state.actra && (
            <Col span={24} md={12}>
              <div className={s.formTitle}>Actra #</div>
              <Row gutter={16}>
                <Col span={24} md={12}>
                  <p className={s.labelItem}>Actra Apprentice Number</p>
                  <Input
                    ref="actraApprenticeNumber"
                    value={this.state.actraApprenticeNumber}
                    stateKey="actraApprenticeNumber"
                    onChange={this.setInput.bind(this)}
                    placeholder="ex: AM - 12345"
                  />
                </Col>
                <Col span={24} md={12}>
                  <p className={s.labelItem}> Full Actra Number</p>
                  <Input
                    ref="actraApprenticeFullNumber"
                    value={this.state.actraApprenticeFullNumber}
                    stateKey="actraApprenticeFullNumber"
                    onChange={this.setInput.bind(this)}
                    placeholder="ex: 04 - 12345"
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row gutter={16}>
          <Col span={24} md={4}>
            <div className={s.formTitle}>Passport</div>
            <RadioGroup
              ref="passport"
              onChange={(e) => this.setInput('passport', e.target.value)}
              value={this.state.passport}
              className="square-radio"
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </RadioGroup>
          </Col>
          <Col span={24} md={4}>
            <div className={s.formTitle}>Citizenship</div>
            <Asterix />
            <RadioGroup
              ref="citizenship"
              onChange={(e) =>
                e.target.value.toUpperCase() === 'CANADIAN'
                  ? this.setInput('citizenship', e.target.value)
                  : Modal.info({
                      title: 'We are sorry. Registration is permitted for Canadian citizens only.',
                      okText: 'OK',
                    })
              }
              value={this.state.citizenship}
              className="square-radio"
            >
              <Radio value={'CANADIAN'}>Canadian</Radio>
              <Radio value={'other'}>Other</Radio>
            </RadioGroup>
          </Col>
          <Col span={24} md={4}>
            <div className={s.formTitle}> Dual Citizen</div>
            <Asterix />
            <RadioGroup
              ref="dualCitizen"
              onChange={(e) => this.setInput('dualCitizen', e.target.value)}
              value={this.state.dualCitizen}
              className="square-radio"
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </RadioGroup>
          </Col>
          {this.state.dualCitizen && (
            <Col span={24} md={6}>
              <div className={s.formTitle}>Second Citizenship</div>
              <Asterix />
              <Select
                notFoundContent="Not Found"
                showSearch
                style={{ width: '100%' }}
                ref="dualCitizenship"
                value={this.state.dualCitizenship}
                placeholder="Choose"
                onChange={(e) => this.setInput('dualCitizenship', e)}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                optionFilterProp="children"
              >
                {this.props.dualCitizenship &&
                  this.props.dualCitizenship.map((nationality, index) => {
                    return (
                      <Select.Option key={`dualCitizenship-${nationality}`} value={nationality}>
                        {nationality}
                      </Select.Option>
                    )
                  })}
              </Select>
            </Col>
          )}
        </Row>
        <MultiInput.MultiParent
          otherParents={this.state.otherParents}
          rowClass={s.multiInputRowClass}
          provinces={this.props.provinces}
          badFields={this.state.badFields}
          setInput={this.setInput.bind(this)}
        />
      </div>
    )
  }

  renderStep2() {
    let underThree = this.ageUnderThree(
      this.state.dateOfBirth.year,
      this.state.dateOfBirth.month,
      this.state.dateOfBirth.day
    )
    return (
      <div className="container">
        <Row>
          <Col span={24} md={16}>
            <div className={cx(s.formTitle, 'hide-on-mobile')}>
              {underThree ? 'Stats (0-35) months' : 'Stats (3+)'}
              <span>
                <a
                  className={s.viewMeasurements}
                  href={underThree ? '/ToddlerIllustrations.pdf' : '/Child3illustrations.pdf'}
                  target="_blank"
                >
                  View Measurements Guide
                </a>
              </span>
              <p className={s.explanation}>
                Please never guess measurements! This is critical to our business. For help with conversions,{' '}
                <a href="http://www.metric-conversions.org/conversion-calculators.htm" target="_blank">
                  use this free tool.
                </a>
              </p>
            </div>
            <div className={cx(s.formTitle, 'hide-on-desktop')}>
              {underThree ? 'Stats (0-35) months' : 'Stats (3+)'}
              <br />
              <a
                style={{ fontSize: '12px' }}
                href={underThree ? '/ToddlerIllustrations.pdf' : '/Child3illustrations.pdf'}
                target="_blank"
              >
                View Measurements Guide
              </a>

              <p className={s.explanation}>
                Please never guess measurements! This is critical to our business. For help with conversions,{' '}
                <a href="http://www.metric-conversions.org/conversion-calculators.htm" target="_blank">
                  use this free tool.
                </a>
              </p>
            </div>

            <Row>
              <Col span={24} md={4}>
                <div className={s.leftLabel}>
                  {underThree ? (
                    <a href="/ToddlerIllustrations.pdf#page=4" target="_blank">
                      Height
                    </a>
                  ) : (
                    <a href="/Child3illustrations.pdf" target="_blank">
                      Height
                    </a>
                  )}
                </div>
              </Col>
              <Col span={24} md={4}>
                <Asterix inline={false} />
                <div className={s.selectWithRightLabel}>
                  <Select
                    notFoundContent="Not Found"
                    showSearch
                    style={{ width: '100%' }}
                    ref="stats.height.ft"
                    value={String(this.state.stats.height.ft)}
                    className={this.state.badFields.includes('stats.height.ft') ? 'error-field' : ''}
                    placeholder="Choose"
                    onChange={(e) => this.setInput('stats.height.ft', e)}
                    placeholder="0"
                    optionFilterProp="children"
                  >
                    {this.props.height_feet &&
                      this.props.height_feet.map((foot, index) => {
                        return (
                          <Select.Option key={`foot-${index}`} value={String(foot)}>
                            {foot}
                          </Select.Option>
                        )
                      })}
                  </Select>
                  <span className={s.rightLabel}>ft.</span>
                </div>
              </Col>
              <Col span={24} md={4}>
                <Asterix />
                <div className={s.selectWithRightLabel}>
                  <Select
                    notFoundContent="Not Found"
                    showSearch
                    value={String(this.state.stats.height.inches)}
                    onChange={(e) => this.setInput('stats.height.inches', e)}
                    className={this.state.badFields.includes('stats.height.inches') ? 'error-field' : ''}
                    ref="stats.height.inches"
                    style={{ width: '100%' }}
                    placeholder="0"
                    optionFilterProp="children"
                  >
                    {this.props.height_inches &&
                      this.props.height_inches.map((inch, index) => {
                        return (
                          <Select.Option key={inch} value={String(inch)}>
                            {inch}
                          </Select.Option>
                        )
                      })}
                  </Select>
                  <span className={s.rightLabel}>in</span>
                </div>
              </Col>
              {underThree && (
                <Col span={24} md={{ span: 6, offset: 1 }}>
                  <div className={s.leftLabel}>
                    <a href="/ToddlerIllustrations.pdf#page=3" target="_blank">
                      Nape to Crotch
                    </a>
                  </div>
                </Col>
              )}
              {underThree && (
                <Col span={24} md={4}>
                  <Asterix />
                  <div className={s.selectWithRightLabel}>
                    <Input
                      ref="stats.napeToCrotch"
                      value={this.state.stats.napeToCrotch}
                      onChange={this.setInput.bind(this)}
                      stateKey="stats.napeToCrotch"
                      className={this.state.badFields.includes('stats.napeToCrotch') ? 'error-field' : ''}
                      validate="isNumeric"
                      type="number"
                      min="1"
                      placeholder="0"
                    />
                    <span className={s.rightLabel}>in</span>
                  </div>
                </Col>
              )}
            </Row>
            <Row>
              <Col span={24} md={4}>
                <div className={s.leftLabel}>
                  {underThree ? (
                    'Weight'
                  ) : (
                    <a href="/Child3illustrations.pdf#page=2" target="_blank">
                      Inseam
                    </a>
                  )}
                </div>
              </Col>
              <Col span={24} md={4}>
                <Asterix inline={false} />
                <div className={s.selectWithRightLabel}>
                  {underThree ? (
                    <Input
                      ref="stats.weight"
                      value={this.state.stats.weight}
                      onChange={this.setInput.bind(this)}
                      stateKey="stats.weight"
                      className={this.state.badFields.includes('stats.weight') ? 'error-field' : ''}
                      validate="isNumeric"
                      type="number"
                      min="1"
                      placeholder="0"
                    />
                  ) : (
                    <Input
                      ref="stats.inseam"
                      value={this.state.stats.inseam}
                      onChange={this.setInput.bind(this)}
                      stateKey="stats.inseam"
                      validate="isNumeric"
                      className={this.state.badFields.includes('stats.inseam') ? 'error-field' : ''}
                      type="number"
                      min="1"
                      placeholder="0"
                    />
                  )}
                  <span className={s.rightLabel}>{underThree ? 'lbs' : 'in'}</span>
                </div>
              </Col>
              <Col span={24} md={{ span: 6, offset: 5 }}>
                <div className={s.leftLabel}>
                  {underThree ? (
                    <a href="/ToddlerIllustrations.pdf#page=1" target="_blank">
                      Nape to Ankle
                    </a>
                  ) : (
                    'Chest'
                  )}
                </div>
              </Col>
              <Col span={24} md={4}>
                <Asterix inline={false} />
                <div className={s.selectWithRightLabel}>
                  {underThree ? (
                    <Input
                      ref="stats.napeToAnkle"
                      value={this.state.stats.napeToAnkle}
                      onChange={this.setInput.bind(this)}
                      stateKey="stats.napeToAnkle"
                      validate="isNumeric"
                      className={this.state.badFields.includes('stats.napeToAnkle') ? 'error-field' : ''}
                      type="number"
                      min="1"
                      placeholder="0"
                    />
                  ) : (
                    <Input
                      ref="stats.chest"
                      value={this.state.stats.chest}
                      onChange={this.setInput.bind(this)}
                      stateKey="stats.chest"
                      className={this.state.badFields.includes('stats.chest') ? 'error-field' : ''}
                      validate="isNumeric"
                      type="number"
                      min="1"
                      placeholder="0"
                    />
                  )}
                  <span className={s.rightLabel}>in</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24} md={4}>
                <div className={s.leftLabel}>
                  {underThree ? (
                    <a href="/ToddlerIllustrations.pdf#page=6" target="_blank">
                      Size
                    </a>
                  ) : (
                    <a href="/Child3illustrations.pdf#page=4" target="_blank">
                      Size
                    </a>
                  )}
                </div>
              </Col>
              <Col span={24} md={5}>
                <Asterix />
                <div className={s.selectWithRightLabel}>
                  <Select
                    notFoundContent="Not Found"
                    showSearch
                    style={{ width: '100%' }}
                    ref="stats.size"
                    value={this.state.stats.size}
                    placeholder="Choose"
                    className={this.state.badFields.includes('stats.size') ? 'error-field' : ''}
                    onChange={(e) => this.setInput('stats.size', e)}
                    placeholder="0"
                    optionFilterProp="children"
                  >
                    {this.props.sizes &&
                      this.props.sizes.map((size, index) => {
                        return (
                          <Select.Option key={`size-${index}`} value={size}>
                            {size}
                          </Select.Option>
                        )
                      })}
                  </Select>
                </div>
              </Col>
              <Col span={24} md={{ span: 6, offset: 4 }}>
                <div className={s.leftLabel}>
                  {underThree ? (
                    <a href="/ToddlerIllustrations.pdf#page=2" target="_blank">
                      Nape to Wrist
                    </a>
                  ) : (
                    'Waist'
                  )}
                </div>
              </Col>
              <Col span={24} md={4}>
                <Asterix />
                <div className={s.selectWithRightLabel}>
                  <Input
                    ref={underThree ? 'stats.napeToWrist' : 'stats.waist'}
                    value={underThree ? this.state.stats.napeToWrist : this.state.stats.waist}
                    onChange={this.setInput.bind(this)}
                    className={
                      underThree
                        ? this.state.badFields.includes('stats.napeToWrist')
                          ? 'error-field'
                          : ''
                        : this.state.badFields.includes('stats.waist')
                        ? 'error-field'
                        : ''
                    }
                    stateKey={underThree ? 'stats.napeToWrist' : 'stats.waist'}
                    validate="isNumeric"
                    type="number"
                    min="1"
                    placeholder="0"
                  />
                  <span className={s.rightLabel}>in</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24} md={4}>
                <div className={s.leftLabel}>
                  {underThree ? (
                    <a href="/ToddlerIllustrations.pdf#page=5" target="_blank">
                      Shoe Size
                    </a>
                  ) : (
                    <a href="/Child3illustrations.pdf#page=3" target="_blank">
                      Shoe Size
                    </a>
                  )}
                </div>
              </Col>
              <Col span={24} md={4}>
                <Asterix />
                <div className={s.selectWithRightLabel}>
                  {/* <Input
                    ref="stats.shoeSize"
                    value={this.state.stats.shoeSize}
                    onChange={this.setInput.bind(this)}
                    stateKey='stats.shoeSize'
                    placeholder='0'/>
                  <span className={s.rightLabel}></span> */}
                  <Select
                    notFoundContent="Not Found"
                    showSearch
                    style={{ width: '100%' }}
                    ref="stats.shoeSize"
                    className={this.state.badFields.includes('stats.shoeSize') ? 'error-field' : ''}
                    value={this.state.stats.shoeSize}
                    onChange={(e) => this.setInput('stats.shoeSize', e)}
                    placeholder="Choose"
                    optionFilterProp="children"
                  >
                    {this.props.shoeSizes &&
                      this.props.shoeSizes.map((size, index) => {
                        return (
                          <Select.Option key={size} value={String(size)}>
                            {size}
                          </Select.Option>
                        )
                      })}
                  </Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} md={16}>
            <div className={s.statsUnchangedSection}>
              <p>
                Click on the button ("Stats Unchanged") below if your child's measurements have not changed within the
                last month.
              </p>
              {this.props.profile &&
              (this.props.profile.stats_unchanged_counter > 0 || this.state.statsUnchangedUsed) ? (
                <p className={s.statsUnchangedSectionInner}>
                  <button className={cx('button inline', s.statsbutton)} disabled>
                    Stats Unchanged
                  </button>
                  {this.props.profile.stats_unchanged_counter > 0 && (
                    <span>
                      Since you used this button during your last stats update, it is disabled until the measurements
                      above are updated.
                    </span>
                  )}
                </p>
              ) : (
                <p>
                  <button
                    className={cx('button inline', s.statsbutton)}
                    onClick={(e) => {
                      e.preventDefault()
                      this.setStatsUnchanged()
                    }}
                  >
                    Stats Unchanged
                  </button>
                </p>
              )}
            </div>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} md={4}>
            <div className={s.formTitle}>Hair Colour</div>
            <Asterix />
            <Select
              notFoundContent="Not Found"
              showSearch
              style={{ width: '100%' }}
              ref="hairColour"
              value={this.state.hairColour}
              className={this.state.badFields.includes('hairColour') ? 'error-field' : ''}
              onChange={(e) => this.setInput('hairColour', e)}
              placeholder="Choose"
              optionFilterProp="children"
            >
              {this.props.hairColor &&
                this.props.hairColor.map((colour, index) => {
                  return (
                    <Select.Option key={colour} value={String(colour)}>
                      {colour}
                    </Select.Option>
                  )
                })}
            </Select>
          </Col>
          <Col span={24} md={4}>
            <div className={s.formTitle}>Hair Texture</div>
            <Asterix />
            <Select
              notFoundContent="Not Found"
              showSearch
              style={{ width: '100%' }}
              value={this.state.hairTexture}
              ref="hairTexture"
              className={this.state.badFields.includes('hairTexture') ? 'error-field' : ''}
              onChange={(e) => this.setInput('hairTexture', e)}
              placeholder="Choose"
              optionFilterProp="children"
            >
              {this.props.hairTexture &&
                this.props.hairTexture.map((texture, index) => {
                  return (
                    <Select.Option key={texture} value={String(texture)}>
                      {texture}
                    </Select.Option>
                  )
                })}
            </Select>
          </Col>
          <Col span={24} md={4}>
            <div className={s.formTitle}>Hair Length</div>
            <Asterix />
            <Select
              notFoundContent="Not Found"
              showSearch
              style={{ width: '100%' }}
              className={this.state.badFields.includes('hairLength') ? 'error-field' : ''}
              value={this.state.hairLength}
              onChange={(e) => this.setInput('hairLength', e)}
              ref="hairLength"
              placeholder="Choose"
              optionFilterProp="children"
            >
              {this.props.hairLength &&
                this.props.hairLength.map((length, index) => {
                  return (
                    <Select.Option key={length} value={String(length)}>
                      {length}
                    </Select.Option>
                  )
                })}
            </Select>
          </Col>
          <Col span={24} md={4}>
            <div className={s.formTitle}>Eye Colour</div>
            <Asterix />
            <Select
              notFoundContent="Not Found"
              showSearch
              style={{ width: '100%' }}
              value={this.state.eyeColor}
              className={this.state.badFields.includes('eyeColor') ? 'error-field' : ''}
              ref="eyeColor"
              onChange={(e) => this.setInput('eyeColor', e)}
              placeholder="Choose"
              optionFilterProp="children"
            >
              {this.props.eyeColor &&
                this.props.eyeColor.map((colour, index) => {
                  return (
                    <Select.Option key={`colour-${index}`} value={String(colour)}>
                      {colour}
                    </Select.Option>
                  )
                })}
            </Select>
          </Col>
          <Col span={24} md={4}>
            <div className="hide-on-mobile">
              <br />
              <br />
              <br />
              <br />
            </div>
            <Checkbox
              ref="hasTwin"
              checked={this.state.missingTeeth}
              onChange={() => this.setInput('missingTeeth', !this.state.missingTeeth)}
            >
              Missing Teeth?
            </Checkbox>
            <p className={s.explanation}>Does your child currently have missing teeth?</p>
            {!underThree && (
              <Checkbox
                ref="hasBraces"
                checked={this.state.hasBraces}
                onChange={() => this.setInput('hasBraces', !this.state.hasBraces)}
                style={{ marginTop: '10px' }}
              >
                Has Braces?
              </Checkbox>
            )}
          </Col>
        </Row>

        <div className="hide-on-desktop">
          <br />
          <br />
        </div>

        {this.ageUnderThree(this.state.dateOfBirth.year, this.state.dateOfBirth.month, this.state.dateOfBirth.day) && (
          <Row>
            <Col span={24} md={10}>
              <div className={s.formTitle}>
                Baby Skills
                <p className={s.explanation}>(click all they can do without assistance/support)</p>
              </div>
              <Asterix />
              <div className={this.state.badFields.includes('babySkills') ? 'multiwrapper-error' : ''}>
                <Select
                  notFoundContent="Not Found"
                  showSearch
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="choose all that apply"
                  className={this.state.badFields.includes('babySkills') ? 'error-field' : 'nope'}
                  value={this.state.babySkills}
                  ref="babySkills"
                  onChange={(e) => {
                    this.setInput('babySkills', e)
                  }}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  className="multi-select"
                  optionFilterProp="children"
                >
                  {this.props.babySkills &&
                    this.props.babySkills.map((babYSkill, index) => {
                      return (
                        <Select.Option key={babYSkill} value={String(babYSkill)}>
                          {babYSkill}
                        </Select.Option>
                      )
                    })}
                </Select>
              </div>
              {this.spacerFix('babySkills')}
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24} md={10}>
            <div className={s.formTitle}>
              Ethnicity
              <p className={s.explanation}>If your child is mixed race, please click all ethnicities that apply to.</p>
            </div>
            <Asterix />
            <div className={this.state.badFields.includes('ethnicity') ? 'multiwrapper-error' : ''}>
              <Select
                notFoundContent="Not Found"
                showSearch
                mode="multiple"
                value={this.state.ethnicity}
                style={{ width: '100%' }}
                placeholder="choose all that apply"
                ref="ethnicity"
                className="multi-select"
                onChange={(e) => {
                  this.setInput('ethnicity', e)
                }}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                optionFilterProp="children"
              >
                {this.props.ethnicities &&
                  this.props.ethnicities.map((ethnicity, index) => {
                    return (
                      <Select.Option key={ethnicity} value={String(ethnicity)}>
                        {ethnicity}
                      </Select.Option>
                    )
                  })}
              </Select>
            </div>
            {this.spacerFix('ethnicity')}
          </Col>
        </Row>
        <Row>
          <Col span={24} md={10}>
            <div className={s.formTitle}>
              Family Origin <span className={s.subtitle}>(Background)</span>
              <p className={s.explanation}>Please click at least one from the list below</p>
            </div>
            <Asterix />
            <div className={this.state.badFields.includes('familyOrigin') ? 'multiwrapper-error' : ''}>
              <Select
                notFoundContent="Not Found"
                showSearch
                mode="multiple"
                style={{ width: '100%' }}
                value={this.state.familyOrigin}
                className={this.state.badFields.includes('familyOrigin') ? 'error-field' : ''}
                ref="nationality"
                placeholder="choose all that apply"
                className="multi-select"
                onChange={(e) => {
                  this.setInput('familyOrigin', e)
                }}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                optionFilterProp="children"
              >
                {this.props.nationalities &&
                  this.props.nationalities.map((country, index) => {
                    return (
                      <Select.Option key={country} value={String(country)}>
                        {country.toLowerCase()}
                      </Select.Option>
                    )
                  })}
              </Select>
            </div>
            {this.spacerFix('familyOrigin')}
          </Col>
        </Row>
        <Row>
          <Col span={24} md={10}>
            <div className={s.formTitle}>
              Allergies
              <p className={s.explanation}>List any allergies your child has. Otherwise, leave blank.</p>
            </div>
            <Input
              ref={'allergies'}
              value={this.state.allergies ? this.state.allergies : ''}
              onChange={this.setInput.bind(this)}
              stateKey={'allergies'}
              placeholder="Ex: Peanuts, Dairy, etc."
            />
          </Col>
        </Row>
      </div>
    )
  }

  renderStep3() {
    let { languages } = this.props
    let smallView = false
    if (typeof window === 'object') {
      smallView = window && window.innerWidth < 992
    }

    let _languages = []
    languages.forEach((item, index) => {
      if (item.toLowerCase() != 'other') {
        _languages.push(`${item} 0`)
        _languages.push(`${item} 1`)
        _languages.push(`${item} 2`)
      } else {
        _languages.push(`${item}`)
      }
    })

    return (
      <div>
        <div className="container">
          <Row>
            <Col span={24} md={24}>
              <div className={s.formTitle}>
                <Row gutter={16}>
                  <Col span={8}>Languages</Col>
                </Row>
              </div>
              <p className="helperText">
                Choose one or more according to your skill level. <br />
                If this is the primary language spoken, please choose Advanced (example: English Advanced).
              </p>
              <Row>
                <Col span={24} md={12}>
                  <Select
                    notFoundContent="Not Found"
                    showSearch
                    mode="multiple"
                    value={Object.keys(this.state.languages).map((item, index) => {
                      return `${item} ${this.state.languages[item]}`
                    })}
                    ref="languages-key"
                    style={{ width: '100%' }}
                    placeholder="Choose"
                    onChange={(e) => this.setMultiSelect(e, 'languages')}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    className="option-select"
                    optionFilterProp="children"
                  >
                    {_languages &&
                      _languages.map((lang, index) => {
                        return (
                          <Select.Option key={`${lang}-${index}`} value={String(lang)}>
                            {_.includes(lang.toLowerCase(), 'other')
                              ? lang.toLowerCase()
                              : _.includes(lang, '0')
                              ? lang.toLowerCase().replace('0', 'beginner')
                              : _.includes(lang, '1')
                              ? lang.toLowerCase().replace('1', 'intermediate')
                              : lang.toLowerCase().replace('2', 'advanced')}
                          </Select.Option>
                        )
                      })}
                  </Select>
                  {this.bigSpacerFix('languages')}
                </Col>
              </Row>
              <Row>
                <Col span={24} md={{ span: 12 }}>
                  {this.state.languages &&
                    Object.keys(this.state.languages).map((item, index) => {
                      if (item == 'OTHER') {
                        return (
                          <Row key={`${item}`}>
                            <Col span={18}>
                              <div className={s.otherLang}>
                                <Input
                                  value={this.state.languages[item] === 0 ? '' : this.state.languages[item]}
                                  type="text"
                                  style={{ height: '30px' }}
                                  placeholder="Which language is 'Other'?"
                                  stateKey={`languages.${item}`}
                                  onChange={this.setInput.bind(this)}
                                  ref={this.state.languages[item]}
                                />
                              </div>
                            </Col>
                          </Row>
                        )
                      }
                    })}
                </Col>
              </Row>
            </Col>
          </Row>

          <br />
          <br />
          <Row>
            <Col span={24} md={24}>
              <div className={s.formTitle}>
                <Row gutter={16}>
                  <Col span={8}>Special Skills</Col>
                </Row>
              </div>
              <p className="helperText">Choose one or more according to your skill level.</p>
              <Row>
                <Col span={24} md={12}>
                  <Select
                    notFoundContent="Not Found"
                    showSearch
                    mode="multiple"
                    value={Object.keys(this.state.specialSkills).map((item, index) => {
                      return `${item} ${this.state.specialSkills[item]}`
                    })}
                    ref="specialSkills-key"
                    style={{ width: '100%' }}
                    placeholder="Choose"
                    onChange={(e) => this.setMultiSelect(e, 'specialSkills')}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    className="option-select"
                    optionFilterProp="children"
                  >
                    {this.props.specialSkills &&
                      this.extractLevelsFromProps(this.props.specialSkills).map((skill, index) => {
                        return (
                          <Select.Option key={`${skill}-${index}`} value={String(skill)}>
                            {(_.includes(skill, 'BMX') ? skill : skill.toLowerCase())
                              .replace('0', 'beginner')
                              .replace('1', 'intermediate')
                              .replace('2', 'advanced')}
                          </Select.Option>
                        )
                      })}
                  </Select>
                  {this.bigSpacerFix('specialSkills')}
                </Col>
              </Row>
              <p className={s.smallTitle}>Awards and Accolades</p>
              <Row>
                <Col md={12}>
                  {this.state.awards.map((item, index) => {
                    return (
                      <Row key={`award-${index}`} gutter={16}>
                        <Col span={12}>
                          <Input
                            value={this.state.awards[index].type}
                            placeholder="Type award name"
                            stateKey={`awards.${index}.type`}
                            onChange={this.setInput.bind(this)}
                            ref={`awards.${index}.type`}
                          />
                        </Col>
                        <Col span={6}>
                          <Input
                            value={this.state.awards[index].year}
                            type="number"
                            validate="isNumeric"
                            min="1900"
                            max={`${new Date().getFullYear()}`}
                            placeholder="Year won"
                            stateKey={`awards.${index}.year`}
                            onChange={this.setInput.bind(this)}
                            ref={`awards.${index}.year`}
                          />
                        </Col>
                        <Col span={6}>
                          {index !== 0 ? (
                            <Tooltip title="Click to remove">
                              <Icon
                                onClick={() => this.removeThisField('awards', index)}
                                className={s.removeParent}
                                type="close"
                              />
                            </Tooltip>
                          ) : null}
                        </Col>
                      </Row>
                    )
                  })}
                  <Row>
                    <Col span={16} sm={7}>
                      <a onClick={() => this.addNewField('awards')} className="button secondary" type="default">
                        + Add Award
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col span={24} md={24}>
              <div className={s.formTitle}>
                <Row gutter={16}>
                  <Col span={8}>Performance Skills</Col>
                </Row>
              </div>
              <p className="helperText">Choose one or more according to your skill level.</p>
              <Row>
                <Col span={24} md={12}>
                  <Select
                    notFoundContent="Not Found"
                    showSearch
                    mode="multiple"
                    value={Object.keys(this.state.performanceSkills).map((item, index) => {
                      return `${item} ${this.state.performanceSkills[item]}`
                    })}
                    ref="performanceSkills-key"
                    style={{ width: '100%' }}
                    placeholder="Choose"
                    onChange={(e) => this.setMultiSelect(e, 'performanceSkills')}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    className="option-select"
                    optionFilterProp="children"
                  >
                    {this.props.performanceSkills &&
                      this.extractLevelsFromProps(this.props.performanceSkills).map((skill, index) => {
                        return (
                          <Select.Option key={`${skill}-${index}`} value={String(skill)}>
                            {_.includes(skill, '0')
                              ? skill.toLowerCase().replace('0', 'beginner')
                              : _.includes(skill, '1')
                              ? skill.toLowerCase().replace('1', 'intermediate')
                              : skill.toLowerCase().replace('2', 'advanced')}
                          </Select.Option>
                        )
                      })}
                  </Select>
                  {this.bigSpacerFix('performanceSkills')}
                </Col>
              </Row>

              <p className={s.smallTitle}>Classes, Awards and Accolades</p>
              <Row>
                <Col md={12}>
                  {this.state.classAwards.map((item, index) => {
                    return (
                      <Row key={`award-${index}`} gutter={16}>
                        <Col span={12}>
                          <Input
                            value={this.state.classAwards[index].type}
                            placeholder="Type award name"
                            stateKey={`classAwards.${index}.type`}
                            onChange={this.setInput.bind(this)}
                            ref={`classAwards.${index}.type`}
                          />
                        </Col>
                        <Col span={6}>
                          <Input
                            value={this.state.classAwards[index].year}
                            type="number"
                            min="1900"
                            max={`${new Date().getFullYear()}`}
                            placeholder="Year won"
                            stateKey={`classAwards.${index}.year`}
                            onChange={this.setInput.bind(this)}
                            ref={`classAwards.${index}.year`}
                          />
                        </Col>
                        <Col span={6}>
                          {index !== 0 ? (
                            <Tooltip title="Click to remove">
                              <Icon
                                onClick={() => this.removeThisField('classAwards', index)}
                                className={s.removeParent}
                                type="close"
                              />
                            </Tooltip>
                          ) : null}
                        </Col>
                      </Row>
                    )
                  })}
                  <Row>
                    <Col span={16} sm={7}>
                      <a onClick={() => this.addNewField('classAwards')} className="button secondary" type="default">
                        + Add Award
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col span={24} md={24}>
              <div className={s.formTitle}>Special Needs</div>
              <Row>
                <Col span={24} md={8}>
                  <Select
                    notFoundContent="Not Found"
                    showSearch
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Choose 1 or more that apply"
                    onChange={(e) => this.setNested(e, 'specialNeeds')}
                    value={Object.keys(this.state.specialNeeds)}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    className="option-select specialNeeds"
                    optionFilterProp="children"
                  >
                    {this.props.specialNeeds &&
                      this.props.specialNeeds.map((need, index) => {
                        return (
                          <Select.Option key={`${need}-${index}`} value={String(need)}>
                            {need}
                          </Select.Option>
                        )
                      })}
                  </Select>
                </Col>
                <Col span={24} md={{ offset: 5, span: 10 }}>
                  <div className="hide-on-desktop">
                    {this.state.specialNeeds &&
                      Object.keys(this.state.specialNeeds).map((need, index) => {
                        return <div key={`hack-${index}`} className={s.spacerHack}></div>
                      })}
                  </div>
                  {this.state.specialNeeds &&
                    Object.keys(this.state.specialNeeds).map((need, index) => {
                      return (
                        <div key={`need-${index}`} className={s.specialNeedsInput}>
                          <Input
                            key={index}
                            className={s.multiSelectInput}
                            value={this.state.specialNeeds[need]}
                            placeholder={
                              smallView
                                ? `Tell us more about their condition of ${need}`
                                : 'Tell us more about their condition or special requirements'
                            }
                            stateKey={`specialNeeds.${need}`}
                            required={true}
                            onChange={this.setInput.bind(this)}
                          />
                        </div>
                      )
                    })}
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="hide-on-mobile">
            <br />
            <br />
          </div>
          <Row>
            <Col span={24} md={24}>
              <div className={s.formTitle}>Experience</div>
              {this.state.experience &&
                this.state.experience.map((item, index) => {
                  return (
                    <div key={`item-${index}`}>
                      <Row key={`experience-${index}`} gutter={16}>
                        <Col span={24} md={4}>
                          <p className={s.labelText}>Experience</p>
                          <Input
                            value={this.state.experience[index].jobName}
                            onChange={this.setInput.bind(this)}
                            stateKey={`experience.${index}.jobName`}
                            placeholder="Job Name"
                            ref={`experience.${index}.jobName`}
                          />
                        </Col>
                        <Col span={24} md={4}>
                          <p className={s.labelText}>Category</p>
                          <Select
                            notFoundContent="Not Found"
                            showSearch
                            placeholder="Choose"
                            value={this.state.experience[index].category}
                            onChange={(e) => this.setInput(`experience.${index}.category`, e)}
                            style={{ width: '100%' }}
                            ref={`experience.${index}.category`}
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="Choose"
                            optionFilterProp="children"
                          >
                            {this.props.jobTypes &&
                              this.props.jobTypes.map((job, index) => {
                                return (
                                  <Select.Option key={job} value={String(job)}>
                                    {job}
                                  </Select.Option>
                                )
                              })}
                          </Select>
                        </Col>
                        <Col span={24} md={4}>
                          <p className={s.labelText}>Type</p>
                          <Select
                            notFoundContent="Not Found"
                            showSearch
                            style={{ width: '100%' }}
                            value={this.state.experience[index].soc}
                            onChange={(e) => this.setInput(`experience.${index}.soc`, e)}
                            ref={`experience.${index}.soc`}
                            placeholder="Choose"
                            optionFilterProp="children"
                          >
                            {this.props.soc &&
                              this.props.soc.map((item, index) => {
                                return (
                                  <Select.Option key={item} value={String(item)}>
                                    {item}
                                  </Select.Option>
                                )
                              })}
                          </Select>
                        </Col>
                        <Col span={24} md={4}>
                          <p className={s.labelText}>Role</p>
                          <Input
                            value={this.state.experience[index].role}
                            onChange={this.setInput.bind(this)}
                            stateKey={`experience.${index}.role`}
                            placeholder="Type"
                            ref={`experience.${index}.role`}
                          />
                        </Col>
                        <Col span={24} md={4}>
                          <p className={s.labelText}>Location</p>
                          <Input
                            value={this.state.experience[index].location}
                            onChange={this.setInput.bind(this)}
                            stateKey={`experience.${index}.location`}
                            placeholder="Toronto, ON"
                            ref={`experience.${index}.location`}
                          />
                        </Col>
                        <Col span={24} md={3}>
                          <p className={s.labelText}>Year</p>
                          <Select
                            notFoundContent="Not Found"
                            showSearch
                            style={{ width: '100%' }}
                            value={this.state.experience[index].year}
                            onChange={(e) => this.setInput(`experience.${index}.year`, e)}
                            placeholder="2017"
                            ref={`experience.${index}.year`}
                            optionFilterProp="children"
                          >
                            {this.props.years &&
                              this.props.years.map((year, index) => {
                                return (
                                  <Select.Option key={year} value={String(year)}>
                                    {year}
                                  </Select.Option>
                                )
                              })}
                          </Select>
                        </Col>
                        <Col offset={2} span={22} md={{ span: 1, offset: 0 }}>
                          <p className="helperText hide-on-mobile">&nbsp;</p>
                          {this.state.experience.length > 1 ? (
                            <Tooltip title="Click to remove">
                              <Icon
                                onClick={() => this.removeThisField('experience', index)}
                                className={s.removeParent}
                                type="close"
                              />
                            </Tooltip>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  )
                })}
            </Col>
          </Row>
          <br className="hide-on-desktop" />
          <Row>
            <Col span={16} md={5}>
              <a onClick={() => this.addNewField('experience')} className="button secondary" type="default">
                + Add Experience
              </a>
            </Col>
          </Row>
          <div className="hide-on-mobile">
            <br />
            <br />
          </div>
          <Row>
            <Col span={24} md={10}>
              <div className={s.formTitle}>
                Modeled For:
                <p className={s.explanation}>
                  Please list the brands your child has modeled for. If they have not modeled for anyone yet, please
                  leave blank.
                </p>
              </div>
              <Input
                ref={'modeledFor'}
                validate="isLength"
                validateArgs={{ max: 550 }}
                value={this.state.modeledFor ? this.state.modeledFor : ''}
                onChange={this.setInput.bind(this)}
                stateKey={'modeledFor'}
                placeholder="Brands"
              />
            </Col>
          </Row>
        </div>

        <div className="container">
          <p>
            <br />
            <br />
          </p>
          <Row>
            <Col span={24} md={12}>
              <div className={s.formTitle}>Tell us about your child:</div>
              <p>
                Write a short biography of 250 characters or less to describe your child's personality, special
                interests, or why they're interested in acting and/or modelling. DO NOT PUT LAST NAME.
                <br />
                <br />
              </p>
              <Input
                type="textarea"
                value={this.state.about}
                ref="about"
                onChange={this.setInput.bind(this)}
                stateKey="about"
                autosize={{ minRows: 3, maxRows: 7 }}
                placeholder="ex: Jane is a spirited leader with a great sense of humour and active imagination. This fourth grader loves to be photographed and perform songs with her microphone. Currently she enjoys swimming, skating, and gymnastics."
              />
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  renderStep4() {
    const admin = this.props.current_user && this.props.current_user.role === 'admin'
    const SortableUploadCard = SortableElement(({ image, itemindex, removeImage, stateKey, audio, checkPreferred }) => {
      const urlParts = image ? image.url.split('/') : audio.url.split('/')
      const imageName = urlParts[urlParts.length - 1]
      return (
        <div className={s.uploadedImage}>
          {image && (
            <div className={s.uploadedImageimage}>
              <img src={image.url} />
            </div>
          )}
          {audio && <div className={s.uploadedImageimage}></div>}
          {checkPreferred && (
            <label>
              <input
                className={s.checkPreferred}
                checked={image.id == this.state.preferredImageId}
                type="checkbox"
                onChange={() => this.checkPreferred(image.id, image.id == this.state.preferredImageId)}
              />{' '}
              Make Headshot
            </label>
          )}
          <div className={s.uploadedImageInfo}>
            <div className={s.uploadedImageimageName}>{imageName}</div>
            {checkPreferred ? null : <div className={s.imageCompletion}>Complete</div>}
          </div>
          <a
            onClick={(e) => {
              removeImage(itemindex, stateKey)
            }}
            className={s.removeImage}
          >
            <Icon type="close" />
          </a>
        </div>
      )
    })

    const SortableImages = SortableContainer(({ items }) => {
      return (
        <div className={s.uploadInfo}>
          {this.state.imageLoading && <Loader />}

          {items &&
            items.map((image, index) => (
              <div key={index}>
                <SortableUploadCard
                  image={image}
                  index={index}
                  checkPreferred={true}
                  itemindex={index}
                  stateKey="images"
                  removeImage={this.removeImage.bind(this)}
                />
              </div>
            ))}
          {items.length > 0 ? <div className={s.dragToRearrange}>Drag images to re-arrange order</div> : null}
        </div>
      )
    })

    const SortableAudio = SortableContainer(({ items }) => {
      return (
        <div className={s.uploadInfo}>
          {this.state.audioLoading && <Loader />}
          {items.length > 0 &&
            items.map((audio, index) => (
              <SortableUploadCard
                audio={audio}
                key={index}
                index={index}
                itemindex={index}
                stateKey="audio_files"
                removeImage={this.removeImage.bind(this)}
              />
            ))}
          {items.length > 0 ? <div className={s.dragToRearrange}>Drag audio files to re-arrange order</div> : null}
        </div>
      )
    })

    const SortableHandImages = SortableContainer(({ items }) => {
      return (
        <div className={s.uploadInfo}>
          {this.state.handLoading && <Loader />}
          {items &&
            items.map((image, index) => (
              <SortableUploadCard
                image={image}
                key={index}
                index={index}
                itemindex={index}
                stateKey="hand_images"
                removeImage={this.removeImage.bind(this)}
              />
            ))}
          {items.length > 0 ? <div className={s.dragToRearrange}>Drag images to re-arrange order</div> : null}
        </div>
      )
    })

    return (
      <div>
        <div className="container">
          <div ref="uploadsContainer" className={cx(s.formTitle, s.noBorder)}>
            Uploads
          </div>
          <p>
            Please upload a photo according to the specifications listed below. A photo is required to be considered for
            our agency. If you’re having difficulty uploading a photo, please e-mail it to{' '}
            <a href="mailto:lee@minordetails.ca">lee@minordetails.ca</a> with your child’s name as the subject line.
            Once you've e-mailed the photo, you can submit the registration form for your child without a photo, and we
            will attach it for you.
          </p>
          <br />
        </div>
        <div className={s.uploadHolder}>
          <div className="container">
            <Row gutter={30}>
              <Col span={24} md={8}>
                <div className={s.formTitle}>Photos</div>
                <div className={s.uploadExplanation}>
                  <p>
                    Please upload your photos below. Photos must be JPG, JPEG or PNG format. For best results, crop to a
                    4:5 ratio and under 2MB in size, though not required for photo to be uploaded.
                    <br />
                    <br />
                    Child 0-35 months photo requirements,{' '}
                    <a className="" href="/Headshot_Requirements_Babies.pdf" target="_blank">
                      click here
                    </a>
                    <br />
                    Child 3+ photo requirements,{' '}
                    <a className="" href="/Headshot_Requirements_Kids.pdf" target="_blank">
                      click here
                    </a>
                    <br />
                    <br />
                    You can drag and reorder the images below. Click ‘Make Headshot’ on the image you'd like as you
                    child's main headshot.
                    <br />
                    <br />
                    For current talent: Never delete images, simply add new photos, arrange the order as you like and
                    your agent will remove older/unnecessary photos.
                    <br />
                    <br />
                    If you're experiencing any difficulties, e-mail us the image, and submit your application without
                    photos. After applying, you will receive an automatic e-mail saying{' '}
                    <em>"Thank you for applying, we'll be in touch soon." </em>
                    If you do not receive this e-mail, it means we did not receive your application or your Guardian
                    Account e-mail was entered incorrectly.
                  </p>
                </div>
                <div className={s.dropZoneContainer}>
                  <SortableImages
                    helperClass={s.sortableHelper}
                    shouldCancelStart={this.shouldCancelStart}
                    items={this.state.images}
                    onSortEnd={this.onImageSortEnd}
                  />
                  <div className={s.uploadContainer}>
                    <Dropzone className={s.dropZone} onDrop={this.onDrop.bind(this)} ref="dropZone-image">
                      <p className={s.dropZoneText}>
                        Drag files here or <span className={s.dropZoneBrowse}>browse</span>
                      </p>
                    </Dropzone>
                  </div>
                </div>
                <div className="hide-on-desktop">
                  <br />
                  <br />
                </div>
              </Col>
              <Col span={24} md={8}>
                <div className={s.formTitle}>Voice Work</div>
                <div className={s.uploadExplanation}>
                  <p>
                    Upload limit. 2MB.
                    <br />
                    MP3 format only.
                    <br />
                    <br />
                    You can drag and reorder the files below.
                    <br />
                    <br />
                    You have the option to also record a voice demo of your child once with the agency.
                    <br />
                    <br />
                    This is not mandatory.
                    <br />
                    <br />
                    Voice demo’s must be professionally recorded.
                    <br />
                    <br />
                    Please inquire with your agent if interested.
                  </p>
                </div>
                <div className={s.dropZoneContainer}>
                  <SortableAudio
                    helperClass={s.sortableHelper}
                    shouldCancelStart={this.shouldCancelStart}
                    items={this.state.audio_files}
                    onSortEnd={this.onAudioSortEnd}
                  />

                  <div className={s.uploadContainer}>
                    <Dropzone className={s.dropZone} onDrop={this.onAudioFileDrop.bind(this)} ref="dropZone-audio">
                      <p className={s.dropZoneText}>
                        Drag files here or <span className={s.dropZoneBrowse}>browse</span>
                      </p>
                    </Dropzone>
                  </div>
                </div>
                <div className="hide-on-desktop">
                  <br />
                  <br />
                </div>
              </Col>
              <Col span={24} md={8}>
                <div className={s.formTitle}>Hand Demo</div>

                <div className={s.uploadExplanation}>
                  <p>
                    Photos cannot exceed a maximum 2MB file size. They must have a 4:5 ratio. Here is a free tool to
                    help you crop photos:{' '}
                    <a href="https://croppola.com/" target="_blank">
                      croppola.com
                    </a>{' '}
                    <br />
                    <br />
                    You have the option to upload three photographs of your child’s hands. They must be photographed on
                    a white, flat surface with natural light. Hands and nails must be clean with no nail polish,
                    scratches or band-aids. <br />
                    <br />
                    1st Photo: Left hand, palm down
                    <br />
                    2nd Photo: Right Hand, palm down
                    <br />
                    3rd Photo: Dominant hand only, palm down holding a pencil
                    <br />
                    <br />
                    <a className="" href="/Hand_Demo.pdf" target="_blank">
                      Click here
                    </a>{' '}
                    for sample images
                  </p>
                </div>
                <div className={s.dropZoneContainer}>
                  <SortableHandImages
                    helperClass={s.sortableHelper}
                    shouldCancelStart={this.shouldCancelStart}
                    items={this.state.hand_images}
                    onSortEnd={this.onHandImageSortEnd}
                  />

                  <div className={s.uploadContainer}>
                    <Dropzone className={s.dropZone} onDrop={this.onHandImageDrop.bind(this)} ref="dropZone-hand_image">
                      <p className={s.dropZoneText}>
                        Drag files here or <span className={s.dropZoneBrowse}>browse</span>
                      </p>
                    </Dropzone>
                  </div>
                </div>
              </Col>
            </Row>
            <p style={{ fontStyle: 'italic' }}>
              <br />
              <br />
              Having difficulty uploading a picture? Please read the instructions at the top of the page so we can help
              you.
              <br />
              After filling out your application form and pressing submit, you’ll be redirected to your My Account page.
              <br />
              You will see your child's name listed under Profiles. You will also receive an automatic e-mail saying
              "Thank you for applying, we'll be in touch soon". If your child is not listed under Profiles in your My
              Account page, we did not receive their application.
              <br />
              For current talent, you’ll also see 'updates pending approval' in the Notifications section of your My
              Account page.
            </p>
          </div>
        </div>
        {!!this.props.profile && this.props.guardianHasApprovedProfile && (
          <div className="container">
            <Row>
              <Col>
                <FamilySection
                  uuid={this.state.uuid}
                  guardian={this.props.guardian}
                  paths={this.props.paths}
                  isAdmin={admin}
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    )
  }

  addNewField(keyType) {
    let keyToUpdate = _.cloneDeep(this.state[keyType])
    let item = _.cloneDeep(keyToUpdate[0])
    Object.keys(item).map((key, index) => {
      item[key] = ''
    })
    keyToUpdate.push(item)
    this.setState({
      [keyType]: keyToUpdate,
    })
  }

  render() {
    const admin = this.props.current_user && this.props.current_user.role === 'admin'
    let steps = [this.renderStep1(), this.renderStep2(), this.renderStep3(), this.renderStep4()]
    return (
      <div className={s.root}>
        {this.state.submitting && (
          <div className={s.submittingOverlay}>
            <Loader />
            We are submitting your information
          </div>
        )}
        <div className={cx('container', s.headerContainer)}>
          <Row type="flex" align="middle">
            <Col span={24} sm={16}>
              <Row type="flex" align="bottom">
                <Col span={24} sm={16}>
                  <h1 className={s.registrationHeader}>Registration</h1>
                  {this.props.closed && (
                    <p>Registration is closed, sorry. We need time to get through all the applications</p>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24} sm={8}>
              {admin ? (
                <div className={s.steps}>
                  <div className={s.stepTitle}>Step</div>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => this.handleStep(0)}
                    className={this.state.step === 0 ? cx(s.stepCircle, s.activeStep) : s.stepCircle}
                  >
                    1
                  </div>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => this.handleStep(1)}
                    className={this.state.step === 1 ? cx(s.stepCircle, s.activeStep) : s.stepCircle}
                  >
                    2
                  </div>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => this.handleStep(2)}
                    className={this.state.step === 2 ? cx(s.stepCircle, s.activeStep) : s.stepCircle}
                  >
                    3
                  </div>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => this.handleStep(3)}
                    className={this.state.step === 3 ? cx(s.stepCircle, s.activeStep) : s.stepCircle}
                  >
                    4
                  </div>
                </div>
              ) : (
                <div className={s.steps}>
                  <div className={s.stepTitle}>Step</div>
                  <div className={this.state.step === 0 ? cx(s.stepCircle, s.activeStep) : s.stepCircle}>1</div>
                  <div className={this.state.step === 1 ? cx(s.stepCircle, s.activeStep) : s.stepCircle}>2</div>
                  <div className={this.state.step === 2 ? cx(s.stepCircle, s.activeStep) : s.stepCircle}>3</div>
                  <div className={this.state.step === 3 ? cx(s.stepCircle, s.activeStep) : s.stepCircle}>4</div>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24} sm={16}>
              <div className={s.requiredContainer}>
                <Asterix inline={true} />
                required
              </div>
            </Col>
          </Row>
        </div>
        {steps[this.state.step]}
        <div className="container">
          <hr className={s.btnDivider} />
          <div className={cx(s.actionholders, 'cf')}>
            <Row type="flex" justify="space-between">
              <Col span={24} sm={4}>
                {this.state.step !== 0 ? (
                  <a className={cx(s.button, 'button', 'secondary')} onClick={this.decrementStep} type="default">
                    &lt; back
                  </a>
                ) : null}
              </Col>
              <Col span={24} sm={4}>
                {this.state.step === 3 ? (
                  <a
                    className={cx(s.button, 'button')}
                    disabled={this.props.closed || this.state.submitting}
                    onClick={this.handleFormSubmit}
                    type="default"
                    icon="right"
                  >
                    submit
                  </a>
                ) : (
                  <a className={cx(s.button, 'button')} onClick={this.incrementStep} type="default">
                    next &gt;
                  </a>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  }
}

ProfileForm.defaultProps = {
  step: 0,
}

ProfileForm.propTypes = {
  step: PropTypes.number,
}

export default ProfileForm
