import { Input } from 'antd'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import validate from 'validator'
import errors from './errors.js'
import s from './style.module.scss'
import InputMask from 'react-input-mask'

class _Input extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: false,
    }
  }
  handleChange(e) {
    if (this.props.validate) {
      let errorMessage = validate[this.props.validate](e.target.value, ...this.props.validateArgs)
        ? false
        : errors({
            validation: this.props.validate,
            validationArgs: this.props.validateArgs,
            message: this.props.errorMessage,
          })
      this.setState({ errors: errorMessage })
    }
    this.props.onChange(this.props.stateKey, e.target.value)
  }
  render() {
    const input =
      this.props.type === 'textarea' ? (
        <Input
          value={this.props.value}
          type={this.props.type}
          autosize
          style={{ lineHeight: '24px' }}
          onChange={(e) => this.handleChange(e)}
          placeholder={this.props.placeholder}
        />
      ) : this.props.mask ? (
        <InputMask
          mask={this.props.mask}
          type={this.props.type}
          className="ant-input"
          value={this.props.value}
          onChange={(e) => this.handleChange(e)}
          placeholder={this.props.placeholder}
          beforeMaskedValueChange={this.props.beforeMaskedValueChange}
        />
      ) : (
        <Input
          value={this.props.value}
          name={this.props.name}
          type={this.props.type}
          min={this.props.min ? this.props.min : ''}
          max={this.props.max ? this.props.max : ''}
          className={cx(this.props.className, s.input)}
          onChange={(e) => this.handleChange(e)}
          placeholder={this.props.placeholder}
        />
      )
    return (
      <div className={cx(s.root, this.props.className)}>
        {input}
        <div className={s.errors}>
          {this.props.required && this.props.value === '' ? 'This field is required.' : this.state.errors}
        </div>
      </div>
    )
  }
}

_Input.defaultProps = {
  title: 'Testing default title',
}

_Input.propTypes = {
  title: PropTypes.string,
}

export default _Input
