import cx from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import crayon_1 from './crayon_1.png'
import crayon_2 from './crayon_2.png'
import crayon_3 from './crayon_3.png'
import crayon_4 from './crayon_4.png'
import crayon_5 from './crayon_5.png'
import crayon_6 from './crayon_6.png'
import crayon_7 from './crayon_7.png'
import crayon_8 from './crayon_8.png'
import s from './style.module.scss'

const crayons = _.shuffle([crayon_1, crayon_2, crayon_3, crayon_4, crayon_5, crayon_6, crayon_7, crayon_8])

class ParallaxCrayon extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      domReady: false,
      positionincrements: [],
    }
  }

  parallax(increment) {
    const positionincrements = this.state.positionincrements.map((el, index) => {
      return index % 2 === 0 ? el + increment : el - increment
    })
    this.setState({ domReady: true, positionincrements: positionincrements })
  }
  scrollUp(event) {
    var delta
    if (event.wheelDelta) {
      delta = event.wheelDelta
      console.log(event.wheelDelta)
    } else {
      delta = -1 * event.deltaY
      console.log('deltay', event.deltaY)
    }
    console.log(delta)
    if (delta < 0) {
      return false
    } else if (delta > 0) {
      return true
    }
  }

  componentDidMount() {
    const body = document.body,
      html = document.documentElement
    // const positionincrement = (Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight ) - 666 - 256) / 8;
    const positionincrement = window.outerHeight / 8
    const positionincrements = [
      positionincrement * 2.9,
      positionincrement * 2,
      positionincrement * 4.7,
      positionincrement * 6.3,
      positionincrement * 3.8,
      positionincrement * 6.5,
      positionincrement * 1.2,
      positionincrement * 2.8,
    ]

    this.pervScrollTop = 0

    document.addEventListener('scroll', (event) => {
      let currentScrollTop = window.pageYOffset || document.documentElement.scrollTop
      let increment = 1
      if (currentScrollTop > this.pervScrollTop) {
        this.pervScrollTop = currentScrollTop
        increment = -1
      }
      this.pervScrollTop = currentScrollTop

      this.parallax(increment)
    })
    this.setState({ domReady: true, positionincrements: positionincrements })
  }
  render() {
    return (
      <div className={s.root}>
        {this.state.domReady && (
          <div>
            {this.state.domReady &&
              crayons.map((crayon, index) => {
                return (
                  <img
                    id={`crayon-${index}`}
                    key={index}
                    src={crayon}
                    style={{ top: this.state.positionincrements[index], left: `${12 * index}vw` }}
                    className={cx(s.crayon, s['crayon-' + index])}
                  />
                )
              })}
          </div>
        )}
      </div>
    )
  }
}

ParallaxCrayon.defaultProps = {
  title: 'ParallaxCrayoning default title',
}

ParallaxCrayon.propTypes = {
  title: PropTypes.string,
}

export default ParallaxCrayon
