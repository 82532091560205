import React from 'react'
import ResultCard from './../components/ResultCard'
import Input from './../components/Input'
import PropTypes from 'prop-types'
import s from './SearchProfile.module.scss'
import cx from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import __ from './../components/Common'

import { Checkbox, DatePicker, Tooltip, Row, Col, Select, InputGroup, AutoComplete } from 'antd'
const Option = Select.Option
import { ContextWrapper } from '../ContextWrapper'

class SearchProfile extends React.Component {
  constructor(props) {
    super(props)
    // window.m = moment
    this.state = {
      size: this.props.sizeSorted,
      cardSize: this.props.cardSize || 'small',
      page: 0,
      offset: 20,
      profiles: [],
      pristine: [],
      sortBy: 'alphabetical',
      allKeywords: [],
      keywords: [],
      minDate: null,
      maxDate: null,
      minDayType: 'years',
      maxDayType: 'years',
      showAdvanced: false,
      // showAdvanced: true,
      filters: {
        gender: null,
        name: '',
        size: null,
        hair_color: null,
        eye_color: null,
        min_days: null,
        max_days: null,
        family_origin: null,
        language: null,
        performance_skills: null,
        special_skills: null,
        ethnicity: null,
        hair_texture: null,
        hair_length: null,
        baby_skills: null,
        missing_teeth: null,
        has_twin: null,
        has_siblings: null,
        has_actra: null,
        special_needs: null,
        date_available: null,
      },
    }
  }

  componentDidMount() {
    window.onpageshow = function (event) {
      if (event.persisted) {
        // force a reload when page is returned to via 'back btn'
        // this only fires on safari. To circumvent javascript cacheing
        window.location.reload()
      }
    }
    let profiles = _.cloneDeep(this.props.profiles)
    let allKeywords = []
    let groupedByParent = _.groupBy(profiles, 'parent_id')
    profiles.forEach((p) => {
      for (var prop in p) {
        p[prop] = __.parseIfString(p[prop])
      }
      p.ageInDays = p.date_of_birth ? __.ageInDays(p.date_of_birth) : 0
      p.stats = __.parseIfString(p.stats)
      p.keywords = [
        p.gender,
        p.hair_color,
        p.hair_texture,
        p.hair_length,
        p.eye_color,
        p.special_skills,
        p.about.split(' '),
      ]
      p.has_siblings = groupedByParent[p.parent_id].length > 1 || p.has_twin
      allKeywords.push(p.keywords)
    })

    allKeywords = _.uniq(_.flattenDeep(allKeywords))

    const parameters = {
      minDate: __.getParameterByName('min_days') ? __.getParameterByName('min_days') : null,
      minDayType: __.getParameterByName('min_day_type') ? __.getParameterByName('min_day_type') : 'years',
      maxDate: __.getParameterByName('max_days') ? __.getParameterByName('max_days') : null,
      maxDayType: __.getParameterByName('max_day_type') ? __.getParameterByName('max_day_type') : 'years',
    }

    let _filters = window.location.search.includes('filters=true')
      ? Object.assign(this.state.filters, this.getFiltersFromURL(window.location.search))
      : this.state.filters

    // console.log("maxDate: ", parameters.minDayType == 'years' ? parameters.maxDate  / 365  : parameters.maxDayType == 'months' ? Math.round(parameters.maxDate / 30.4167) : parameters.maxDate);

    this.setState({
      pristine: profiles,
      profiles: profiles,
      allKeywords: allKeywords,
      filters: _filters,
      minDate:
        parameters.minDayType == 'years'
          ? Math.round(parameters.minDate / 365)
          : parameters.minDayType == 'months'
          ? Math.round(parameters.minDate / 30.4167)
          : parameters.minDate,
      minDayType: parameters.minDayType,
      maxDate:
        parameters.minDayType == 'years'
          ? parameters.maxDate / 365
          : parameters.maxDayType == 'months'
          ? Math.round(parameters.maxDate / 30.4167)
          : parameters.maxDate,
      maxDayType: parameters.maxDayType,
    })
  }

  /************************
    Other methods
  *************************/

  getFiltersFromURL(queryString) {
    let filterObj = {}
    const getRidOfFilterQuery = queryString.replace('?filters=true', '')
    const decodeURItem = decodeURIComponent(getRidOfFilterQuery)
    const queryArray = _.compact(decodeURItem.split('&'))
    queryArray.forEach((item, index) => {
      let values = item.split('=')
      if (values[0] != 'min_day_type' && values[0] != 'max_day_type') {
        let filterVal

        if (values[1].includes(',')) {
          filterVal = values[1].split(',')
        } else if (values[1] == 'true' || values[1] == 'false') {
          filterVal = JSON.parse(values[1])
        } else if (!isNaN(values[1])) {
          filterVal = Number(values[1])
        } else {
          filterVal = values[1]
        }
        filterObj[values[0]] = filterVal
      }
    })
    console.log('filterObj: ', filterObj)
    return filterObj
  }

  setFiltersInUrl(filters) {
    const appliedFilters = {}
    Object.keys(filters).forEach((item, index) => {
      if (!_.isEmpty(filters[item]) || _.isNumber(filters[item]) || _.isBoolean(filters[item])) {
        appliedFilters[item] = filters[item]
      }
    })
    var urlizeObj = function (obj) {
      var str = ''
      for (var key in obj) {
        if (str != '') {
          str += '&'
        }
        str += key + '=' + encodeURIComponent(obj[key])
      }
      return str
    }

    if (!_.isEmpty(appliedFilters)) {
      const x = urlizeObj(appliedFilters)
      history.pushState({}, 'Filter Updated', '/home/search?filters=true&' + x)
    }
  }

  setSize(size) {
    this.setState({ cardSize: size })
  }

  /************************
    Sorting methods
  *************************/

  handleSort(profiles) {
    let order, against
    switch (this.state.sortBy) {
      case 'ageAsc':
        against = ['ageInDays']
        order = ['asc']
        break
      case 'ageDesc':
        against = ['ageInDays']
        order = ['desc']
        break
      case 'sizeAsc':
        against = (p) => this.props.sizeSorted.indexOf(p.stats.size)
        order = ['asc']
        break
      case 'sizeDesc':
        against = (p) => this.props.sizeSorted.indexOf(p.stats.size)
        order = ['desc']
        break
      case 'alphabetical':
        against = ['first_name']
        order = ['asc']
        break
      default:
        against = ['first_name']
        order = ['asc']
        break
    }

    return _.orderBy(profiles, against, order)
  }

  setSort(value) {
    this.setState({ sortBy: value, page: 0 })
  }

  /************************
    State-setting methods for search
  *************************/

  setAgeInDays(type, value) {
    const { minDayType, maxDayType, minDate, maxDate } = this.state
    const filters = _.cloneDeep(this.state.filters)
    let totalDays
    if (type === 'minDayType') {
      if (minDate) {
        totalDays = getTotalDays(value, minDate)
        filters.min_days = totalDays
      }
      this.setState({
        minDayType: value,
        filters: filters,
      })
    } else if (type === 'maxDayType') {
      if (maxDate) {
        totalDays = value == 'years' ? getTotalDays(value, maxDate, true) : getTotalDays(value, maxDate)
        filters.max_days = totalDays
      }
      this.setState({
        maxDayType: value,
        filters: filters,
      })
    } else if (type === 'minDate') {
      totalDays = getTotalDays(minDayType, value)
      filters.min_days = totalDays
      this.setState({
        minDate: value,
        filters: filters,
      })
    } else {
      totalDays = getTotalDays(maxDayType, value, true)
      filters.max_days = totalDays
      this.setState({
        maxDate: value,
        filters: filters,
      })
    }
    function getTotalDays(type, val, addYear = false) {
      let total
      switch (type) {
        case 'years':
          total = val * 365
          if (addYear) {
            total = total + 364
          }
          break
        case 'months':
          total = val * 30
          break
        case 'days':
          total = Number(val)
          break
      }
      return total
    }
    this.setFiltersInUrl(filters)
  }

  setKeywords(filterstatekey, value) {
    let filters = _.cloneDeep(this.state.filters)
    if (
      (filterstatekey === 'performance_skills' ||
        filterstatekey === 'special_skills' ||
        filterstatekey === 'ethnicity') &&
      Array.isArray(value) &&
      value.length == 0
    ) {
      filters[filterstatekey] = null
    } else {
      filters[filterstatekey] = value
    }
    this.setState({
      page: 0,
      filters: filters,
    })
    this.setFiltersInUrl(filters)
  }

  setName(key, value) {
    let filters = _.cloneDeep(this.state.filters)
    filters.name = value
    this.setState({
      page: 0,
      filters: filters,
    })
    this.setFiltersInUrl(filters)
  }

  setPage(page) {
    this.setState({ page: page })
  }

  /************************
    Filter methods
  *************************/

  toggleAdvanced() {
    let currentToggle = this.state.showAdvanced
    this.setState({
      showAdvanced: !currentToggle,
    })
  }

  toggleFilters(val) {
    this.setState({ showFilters: val })
  }

  filterProfile(profile, index) {
    let filterAgainst = []
    for (var key in this.state.filters) {
      if (this.state.filters.hasOwnProperty(key)) {
        const element = this.state.filters[key]
        element ? filterAgainst.push(key) : false
      }
    }

    let profilepass = filterAgainst.map((key) => {
      if (key === 'gender' || key === 'eye_color' || key === 'hair_texture' || key === 'hair_length') {
        return profile[key].toLowerCase() == this.state.filters[key].toLowerCase()
      }
      if (key === 'hair_color') {
        return profile[key].toLowerCase().includes(this.state.filters[key].toLowerCase())
      }

      if (key === 'has_twin' || key === 'missing_teeth') {
        return profile[key] == this.state.filters[key]
      }

      if (key === 'has_siblings') {
        return (
          this.props.profiles.filter((item, index) => {
            return item.parent_id == profile.parent_id
          }).length > 1
        )
      }

      if (key === 'date_available') {
        let hasBookouts = profile.bookouts && profile.bookouts.length > 0
        if (hasBookouts) {
          let during = profile.bookouts.filter(
            (bookout) =>
              moment(moment(bookout.date_start).format()).isBefore(this.state.filters.date_available) &&
              moment(moment(bookout.date_end).format()).isAfter(this.state.filters.date_available)
          )
          if (during && during.length > 0) {
            return false
          }
        }
        return true
      }

      if (key === 'has_actra') {
        return (
          (!_.isEmpty(profile.actra_apprentice_full_number) || !_.isEmpty(profile.actra_apprentice_number)) ==
          this.state.filters[key]
        )
      }

      if (key === 'size') {
        return profile.stats[key] == this.state.filters[key]
      }
      if (key === 'name') {
        const name = __.profileName(profile)
        // const name = profile['first_name'];
        return name.toLowerCase().indexOf(this.state.filters[key].toLowerCase()) > -1
      }

      if (key === 'min_days') {
        // if (profile.ageInDays >= this.state.filters.min_days) { console.log(profile) }
        return profile.ageInDays >= this.state.filters.min_days
      }

      if (key === 'max_days') {
        return profile.ageInDays <= this.state.filters.max_days
      }

      if (key === 'ethnicity') {
        if (profile[key]) {
          let obj = __.parseIfString(profile[key])
          let profileArr = obj.map((item, index) => {
            return item.toLowerCase().trim()
          })
          let filterArr = this.state.filters[key].map((item, index) => {
            return item.toLowerCase()
          })
          return __.arrayContainsArray(profileArr, filterArr)
        }
      }

      if (key === 'family_origin') {
        if (profile[key]) {
          let arr = profile[key].map(function (x) {
            return x.toLowerCase().trim()
          })
          // return __.arrayContainsArray(arr, this.state.filters[key].toLowerCase())
          return arr.includes(this.state.filters[key].toLowerCase())
        }
      }

      if (key === 'baby_skills') {
        if (profile[key]) {
          let arr = profile[key].map(function (x) {
            return x.toLowerCase().trim()
          })
          return arr.includes(this.state.filters[key].toLowerCase())
        }
      }

      if (key === 'special_skills' || key === 'performance_skills') {
        if (profile[key]) {
          let obj = __.parseIfString(profile[key])
          let profileArr = Object.keys(obj).map(function (x) {
            return x.toLowerCase().trim()
          })
          try {
            let filterArr = this.state.filters[key].map((item, index) => {
              return item.toLowerCase().trim()
            })
            return profileArr.some((r) => filterArr.includes(r))
          } catch (err) {
            console.log('try error: ', err)
          }
        }
      }

      if (key === 'performance_skills') {
        if (profile[key]) {
          let obj = __.parseIfString(profile[key])
          let arr = Object.keys(obj).map(function (x) {
            return x.toLowerCase().trim()
          })
          return arr.includes(this.state.filters[key].toLowerCase())
        }
      }
    })

    if (this.state.filters.special_needs) {
      profilepass.push(profile.special_needs && _.isEmpty(profile.special_needs) == false)
    }
    return profilepass.indexOf(false) === -1 ? (
      <ResultCard key={index} profile={profile} hover={true} size={this.state.cardSize} />
    ) : null
  }

  /************************
    Display methods
  *************************/

  maxAgeDisplay(date, type) {
    return date == Number.isInteger(date) ? date : Math.floor(date)
  }

  renderFilterMsgs(filters) {
    let msg = false
    let selected = Object.keys(filters).filter((item, index) => {
      return filters[item]
    })
    if (!_.isEmpty(selected)) {
      msg = (
        <div className={s.filterMsg}>
          <div className={s.msgContainer}>
            <span>Note, the following Filters are applied: </span>
            <ul>
              {selected.map((item, index) => {
                return <li>{_.startCase(item)}</li>
              })}
            </ul>
          </div>
          <button className="button secondary" onClick={() => this.clearFilters()}>
            Clear Filters
          </button>
        </div>
      )
    }
    return msg
  }

  clearFilters() {
    this.setState(
      {
        filters: {
          gender: null,
          name: '',
          size: null,
          hair_color: null,
          eye_color: null,
          min_days: null,
          max_days: null,
          family_origin: null,
          language: null,
          performance_skills: null,
          special_skills: null,
          ethnicity: null,
          hair_texture: null,
          hair_length: null,
          baby_skills: null,
          missing_teeth: null,
          has_twin: null,
          has_siblings: null,
          has_actra: null,
          special_needs: null,
          date_available: null,
        },
      },
      function () {
        history.pushState({}, 'Filter Updated', '/home/search')
      }
    )
  }

  clearFilter(filterItem) {
    const _filters = _.cloneDeep(this.state.filters)
    switch (filterItem) {
      case 'name':
        _filters[filterItem] = ''
        break
      default:
        _filters[filterItem] = null
        break
    }
    this.setState(
      {
        filters: _filters,
      },
      function () {
        this.setFiltersInUrl(_filters)
      }
    )
  }

  /************************
    Render methods
  *************************/

  render() {
    let filters = Object.keys(this.state.filters).filter((item, index) => {
      return this.state.filters[item]
    })
    const sizes = this.props.sizeSorted
    const dateType = ['days', 'months', 'years']
    const orderedProfiles = this.handleSort(this.state.profiles)
    let counter = -1
    let newRendered
    if (orderedProfiles.length > 0) {
      newRendered = orderedProfiles
        .map((profile, index) => {
          let filtered = this.filterProfile(profile, index)
          if (filtered) {
            counter++
          }
          return counter >= this.state.offset * this.state.page && counter < this.state.offset * (this.state.page + 1)
            ? filtered
            : false
        })
        .filter((n) => n)
    } else {
      newRendered = <div className={s.noResult}>No one fits that description</div>
    }
    const availableProfiles = orderedProfiles
      .map((profile, index) => this.filterProfile(profile, index))
      .filter((n) => n)
    const pages = Array(Math.ceil(availableProfiles.length / this.state.offset)).fill(true)
    const start = this.state.page * this.state.offset
    return (
      <ContextWrapper>
        <div className={s.root}>
          <div className={cx('container', s.container)}>
            <Row type="flex" align="middle">
              <Col span={24} sm={8}>
                <h1 className={s.h1}>Search Talent</h1>
              </Col>
              <Col span={24} sm={8} className="hide-on-mobile">
                <div className={s.toggleSize}>
                  {['small', 'medium', 'large'].map((size, index) => {
                    const extraClass = size === this.state.cardSize ? s.currentSize : s.inactiveSize
                    return (
                      <i
                        onClick={(e) => this.setSize(size)}
                        className={cx(`icon icon-${size}`, extraClass, 'hover-icon')}
                        key={index}
                      ></i>
                    )
                  })}
                </div>
              </Col>
              <Col span={24} sm={4}>
                <div className={s.sortByLabel}>SORT BY</div>
              </Col>
              <Col span={24} sm={4}>
                <div className={s.sortBy}>
                  <Select
                    className={s.sortBySelect}
                    defaultValue="Alphabetical"
                    style={{ width: 120 }}
                    onChange={(e) => this.setSort(e)}
                  >
                    <Option value="alphabetical">Alphabetical</Option>
                    <Option value="ageAsc">Age &uarr;</Option>
                    <Option value="ageDesc">Age &darr;</Option>
                    <Option value="sizeAsc">Size &uarr;</Option>
                    <Option value="sizeDesc">Size &darr;</Option>
                  </Select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24} sm={7} className={this.state.showFilters ? s.filterShow : s.filterHolder}>
                <div className={s.showFilters}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault()
                      this.toggleFilters(!this.state.showFilters)
                    }}
                    className={cx('button', s.showfiltersButton)}
                  >
                    {this.state.showFilters ? `Hide` : `Show`} Filters
                  </a>
                </div>
                {/*<AutoComplete style={{width: '100%'}} ref='keywords' filterstatekey="keywords" onChange={this.setKeywords.bind(this)} placeholder='Keyword Search'
                  filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />*/}
                <br />
                <p className={s.explain}>Use filters below to narrow your search</p>
                <br />
                {/* {this.renderFilterMsgs(this.state.filters)} */}
                {!_.isEmpty(filters) && (
                  <ul className={s.filters}>
                    {filters.map((item, index) => {
                      return (
                        <li className={s.filterItem} key={`filter-${index}`}>
                          <div className={s.filterContent}>
                            {_.startCase(item)}
                            <span onClick={() => this.clearFilter(item)} className={s.filterRemove}></span>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                )}
                <br />
                <div className={s.formTitle}>Name</div>
                <Input
                  ref="Name"
                  stateKey={'name'}
                  onChange={this.setName.bind(this)}
                  value={this.state.filters.name ? this.state.filters.name : ''}
                  placeholder="Name"
                />

                <div className={s.formTitle}>Gender</div>
                <Select
                  notFoundContent="Not Found"
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Choose"
                  stateKey="gender"
                  value={this.state.filters.gender}
                  onChange={(e) => this.setKeywords('gender', e)}
                  optionFilterProp="children"
                >
                  <Select.Option value={null}>All</Select.Option>
                  {['male', 'female'].map((gender, index) => {
                    return (
                      <Select.Option key={gender} value={gender}>
                        {gender}
                      </Select.Option>
                    )
                  })}
                </Select>

                <div className={s.formTitle}>Age</div>
                <Row gutter={8}>
                  <Col span={24} sm={4}>
                    <Input
                      ref="min_days"
                      onChange={this.setAgeInDays.bind(this)}
                      stateKey={'minDate'}
                      type="number"
                      min="1"
                      placeholder="0"
                      value={this.state.minDate ? this.state.minDate : ''}
                    />
                  </Col>
                  <Col span={24} sm={7}>
                    <Select
                      notFoundContent="Not Found"
                      showSearch
                      style={{ width: '100%' }}
                      placeholder={this.state.minDayType}
                      stateKey="min_days"
                      className={'min-padding'}
                      value={this.state.minDayType}
                      onChange={(e) => this.setAgeInDays('minDayType', e)}
                      optionFilterProp="children"
                    >
                      {dateType.map((type, index) => {
                        return (
                          <Select.Option key={type} value={type}>
                            {type}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Col>
                  <Col span={24} sm={2}>
                    <div className={s.to}>To</div>
                  </Col>
                  <Col span={24} sm={4}>
                    <Input
                      ref="max_days"
                      placeholder="0"
                      onChange={this.setAgeInDays.bind(this)}
                      stateKey={'maxDate'}
                      type="number"
                      min="1"
                      value={this.state.maxDate ? this.maxAgeDisplay(this.state.maxDate, this.state.maxDayType) : ''}
                    />
                  </Col>
                  <Col span={24} sm={7}>
                    <Select
                      notFoundContent="Not Found"
                      showSearch
                      style={{ width: '100%' }}
                      placeholder={this.state.maxDayType}
                      stateKey="max_days"
                      className={'min-padding'}
                      value={this.state.maxDayType}
                      onChange={(e) => this.setAgeInDays('maxDayType', e)}
                      optionFilterProp="children"
                    >
                      {dateType.map((type, index) => {
                        return (
                          <Select.Option key={type} value={type}>
                            {type}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Col>
                </Row>

                <div className={s.formTitle}>Size</div>
                <Select
                  notFoundContent="Not Found"
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Choose"
                  stateKey="size"
                  value={this.state.filters.size}
                  onChange={(e) => this.setKeywords('size', e)}
                  optionFilterProp="children"
                >
                  <Select.Option value={null}>All</Select.Option>
                  {sizes.map((size, index) => {
                    return (
                      <Select.Option key={size} value={size}>
                        {size}
                      </Select.Option>
                    )
                  })}
                </Select>

                <div className={s.formTitle}>Hair Colour</div>
                <Select
                  notFoundContent="Not Found"
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Choose"
                  stateKey="hair_color"
                  value={this.state.filters.hair_color}
                  onChange={(e) => this.setKeywords('hair_color', e)}
                  optionFilterProp="children"
                >
                  <Select.Option value={null}>All</Select.Option>
                  {this.props.hairColors &&
                    this.props.hairColors.map((hairColor, index) => {
                      return (
                        <Select.Option key={hairColor} value={hairColor}>
                          {hairColor}
                        </Select.Option>
                      )
                    })}
                </Select>

                <div className={s.formTitle}>Eye Colour</div>
                <Select
                  notFoundContent="Not Found"
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Choose"
                  stateKey="eye_color"
                  value={this.state.filters.eye_color}
                  onChange={(e) => this.setKeywords('eye_color', e)}
                  optionFilterProp="children"
                >
                  <Select.Option value={null}>All</Select.Option>
                  {this.props.eyeColors &&
                    this.props.eyeColors.map((eyeColor, index) => {
                      return (
                        <Select.Option key={eyeColor} value={eyeColor}>
                          {eyeColor}
                        </Select.Option>
                      )
                    })}
                </Select>
                <Row>
                  <Col>
                    <div className={s.formTitle}>Special Needs</div>
                    <div
                      onClick={(e) => this.setKeywords('special_needs', !this.state.filters.special_needs)}
                      className={
                        this.state.filters.special_needs ? cx(s.specialNeeds, s.specialNeedsActive) : s.specialNeeds
                      }
                    >
                      <div className={s.halfSpecial}>Off</div>
                      <div className={s.halfSpecial}>On</div>
                      <div className={s.toggle}>| | |</div>
                      <div className="cf"></div>
                    </div>
                  </Col>
                </Row>

                <br />
                <br />
                <div className={s.advancedToggle}>
                  <div className={s.formTitle}>
                    Advanced Filters{' '}
                    <i
                      className={this.state.showAdvanced ? cx('icon-hamburger', s.clicked) : 'icon-hamburger'}
                      onClick={() => this.toggleAdvanced()}
                    ></i>
                  </div>
                </div>

                {this.state.showAdvanced && (
                  <div>
                    <br />
                    {this.props.current_user && this.props.current_user.role === 'admin' && (
                      <div>
                        <div className={s.formTitle}>Family Origin</div>
                        <Select
                          notFoundContent="Not Found"
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Choose"
                          stateKey="family_origin"
                          value={this.state.filters.family_origin}
                          onChange={(e) => this.setKeywords('family_origin', e)}
                          optionFilterProp="children"
                        >
                          <Select.Option value={null}>All</Select.Option>
                          {this.props.nationalities &&
                            this.props.nationalities.map((nationality, index) => {
                              return (
                                <Select.Option key={nationality} value={nationality}>
                                  {nationality.toLowerCase()}
                                </Select.Option>
                              )
                            })}
                        </Select>
                      </div>
                    )}

                    <div className={s.formTitle}>Available on Date:</div>
                    <DatePicker
                      style={{ width: '100%' }}
                      format="YYYY-MM-DD"
                      placeholder={'Date'}
                      onChange={(e) => this.setKeywords('date_available', e.format())}
                      onOk={(e) => this.setKeywords('date_available', e.format())}
                    />

                    <br />
                    <br />

                    <div className={s.formTitle}>Performance Skills</div>
                    <Select
                      notFoundContent="Not Found"
                      showSearch
                      mode="multiple"
                      className={`search-select`}
                      style={{ width: '100%' }}
                      placeholder="Choose"
                      stateKey="performance_skills"
                      value={
                        this.state.filters.performance_skills && this.state.filters.performance_skills.length > 0
                          ? this.state.filters.performance_skills
                          : []
                      }
                      onChange={(e) => this.setKeywords('performance_skills', e)}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      optionFilterProp="children"
                    >
                      {this.props.performanceSkills &&
                        this.props.performanceSkills.map((skill, index) => {
                          return (
                            <Select.Option key={skill} value={skill}>
                              {skill.toLowerCase()}
                            </Select.Option>
                          )
                        })}
                    </Select>

                    <div className={s.formTitle}>Special Skills</div>
                    <Select
                      notFoundContent="Not Found"
                      showSearch
                      mode="multiple"
                      className={`search-select`}
                      style={{ width: '100%' }}
                      placeholder="Choose"
                      stateKey="special_skills"
                      value={
                        this.state.filters.special_skills && this.state.filters.special_skills.length > 0
                          ? this.state.filters.special_skills
                          : []
                      }
                      onChange={(e) => this.setKeywords('special_skills', e)}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      optionFilterProp="children"
                    >
                      {this.props.specialSkills &&
                        this.props.specialSkills.map((skill, index) => {
                          return (
                            <Select.Option key={skill} value={skill}>
                              {skill.toLowerCase()}
                            </Select.Option>
                          )
                        })}
                    </Select>

                    <div className={s.formTitle}>Ethnicity</div>
                    <Select
                      notFoundContent="Not Found"
                      showSearch
                      mode="multiple"
                      className={`search-select`}
                      value={
                        this.state.filters.ethnicity && this.state.filters.ethnicity.length > 0
                          ? this.state.filters.ethnicity
                          : []
                      }
                      style={{ width: '100%' }}
                      placeholder="Choose"
                      stateKey="ethnicity"
                      onChange={(e) => this.setKeywords('ethnicity', __.cleanArray(e))}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      optionFilterProp="children"
                    >
                      {this.props.ethnicities &&
                        this.props.ethnicities.map((ethnicity, index) => {
                          return (
                            <Select.Option key={ethnicity} value={ethnicity}>
                              {ethnicity}
                            </Select.Option>
                          )
                        })}
                    </Select>

                    <div className={s.formTitle}>Baby Skills</div>
                    <Select
                      notFoundContent="Not Found"
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Choose"
                      stateKey="baby_skills"
                      value={this.state.filters.baby_skills}
                      onChange={(e) => this.setKeywords('baby_skills', e)}
                      optionFilterProp="children"
                    >
                      <Select.Option value={null}>All</Select.Option>
                      {this.props.babySkills &&
                        this.props.babySkills.map((skill, index) => {
                          return (
                            <Select.Option key={skill} value={skill}>
                              {skill.toLowerCase()}
                            </Select.Option>
                          )
                        })}
                    </Select>

                    <div className={s.formTitle}>Hair Texture</div>
                    <Select
                      notFoundContent="Not Found"
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Choose"
                      stateKey="hair_texture"
                      value={this.state.filters.hair_texture}
                      onChange={(e) => this.setKeywords('hair_texture', e)}
                      optionFilterProp="children"
                    >
                      <Select.Option value={null}>All</Select.Option>
                      {this.props.hairTexture &&
                        this.props.hairTexture.map((texture, index) => {
                          return (
                            <Select.Option key={texture} value={texture}>
                              {texture.toLowerCase()}
                            </Select.Option>
                          )
                        })}
                    </Select>

                    <div className={s.formTitle}>Hair Length</div>
                    <Select
                      notFoundContent="Not Found"
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Choose"
                      stateKey="hair_length"
                      value={this.state.filters.hair_length}
                      onChange={(e) => this.setKeywords('hair_length', e)}
                      optionFilterProp="children"
                    >
                      <Select.Option value={null}>All</Select.Option>
                      {this.props.hairLength &&
                        this.props.hairLength.map((length, index) => {
                          return (
                            <Select.Option key={length} value={length}>
                              {length.toLowerCase()}
                            </Select.Option>
                          )
                        })}
                    </Select>

                    <div className={s.formTitle}>Actra</div>
                    <Checkbox
                      ref="hasActra"
                      checked={this.state.filters.has_actra}
                      onChange={() => this.setKeywords('has_actra', !this.state.filters.has_actra)}
                    >
                      Child is Actra
                    </Checkbox>

                    <br />
                    <br />

                    <div className={s.formTitle}>Has Siblings</div>
                    <Checkbox
                      ref="hasTwin"
                      checked={this.state.filters.has_siblings}
                      onChange={() => this.setKeywords('has_siblings', !this.state.filters.has_siblings)}
                    >
                      Child has available siblings
                    </Checkbox>
                    <br />
                    <br />

                    <div className={s.formTitle}>Has Twin</div>
                    <Checkbox
                      ref="hasTwin"
                      checked={this.state.filters.has_twin}
                      onChange={() => this.setKeywords('has_twin', !this.state.filters.has_twin)}
                    >
                      Child has an available twin
                    </Checkbox>

                    <br />
                    <br />

                    <div className={s.formTitle}>Missing Teeth</div>
                    <Checkbox
                      ref="hasTwin"
                      checked={this.state.filters.missing_teeth}
                      onChange={() => this.setKeywords('missing_teeth', !this.state.filters.missing_teeth)}
                    >
                      Child currently has missing teeth
                    </Checkbox>
                  </div>
                )}
                <br />
              </Col>

              <Col span={24} sm={1}></Col>
              <Col span={24} sm={16}>
                {/* {this.renderFilterMsgs(this.state.filters)} */}
                <div className={s.results}>
                  {counter === -1 ? (
                    <div className={s.noProfiles}>
                      <br />
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp; No profiles match that criteria.
                    </div>
                  ) : (
                    newRendered
                  )}
                  <div className="cf"></div>
                </div>
                <div className={s.pagination}>
                  <div
                    className={this.state.page > 0 ? cx(s.pageJump, s.currentPage) : cx(s.pageJump, s.disabledPage)}
                    onClick={() => (this.state.page > 0 ? this.setPage(this.state.page - 1) : false)}
                  >
                    {'<'}
                  </div>
                  {pages.map((p, index) => {
                    return (
                      <div
                        key={index}
                        className={this.state.page == index ? cx(s.pageJump, s.currentPage) : s.pageJump}
                        onClick={() => this.setPage(index)}
                      >
                        {index + 1}
                      </div>
                    )
                  })}
                  <div
                    className={
                      this.state.page < pages.length - 1
                        ? cx(s.pageJump, s.currentPage)
                        : cx(s.pageJump, s.disabledPage)
                    }
                    onClick={() => (this.state.page < pages.length - 1 ? this.setPage(this.state.page + 1) : false)}
                  >
                    {'>'}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </ContextWrapper>
    )
  }
}

ResultCard.defaultProps = {
  cardSize: 'small',
}

ResultCard.propTypes = {
  cardSize: PropTypes.string,
}

export default SearchProfile
