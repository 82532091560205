import { Col, Row } from 'antd'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import s from './style.module.scss'
import VimeoPlayer from './../VimeoPlayer'

class VideoIndex extends React.Component {
  constructor(props) {
    super(props)
    this.generateVideo = this.generateVideo.bind(this)
  }
  
  generateVideo() {
    return (
      <div>
        <Row gutter={16} type="flex" align="start" justify="flex-start">
        {this.props.videoList.map((video, index) => (
          <Col key={video.id} xs={24} sm={12} md={8} lg={8} xl={8} style={{ marginBottom: '3rem' }} >
            <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
              <VimeoPlayer vimeoId={video.embedId} videoId={video.id} />
            </div>

            <div>
              <div style={{ fontSize: '18px', color: '#5E5E5E', fontWeight: 900 }}>
                <span dangerouslySetInnerHTML={{ __html: video.name }}></span>{' '}
                <span id={`watchedTag-${index}`} style={{ display: this.props.watchedVideoIds && this.props.watchedVideoIds.includes(video.id) ? 'inline' : 'none' }}>
                  (WATCHED)
                </span>
              </div>
              {video.subtext && (
                <span className={s.subText}>
                  <i className={cx('icon-info-square', s.listTriangle)}></i>
                  {video.subtext}
                </span>
              )}
            </div>
          </Col>
        ))}
        </Row>
      </div>
    )
  }

  render() {
    let videos = this.generateVideo()

    return (
      <div className={s.root}>
        {videos}
      </div>
    )
  }
}

VideoIndex.defaultProps = {
  title: 'Videos',
}

VideoIndex.propTypes = {
  items: PropTypes.array,
}

export default VideoIndex
