import cx from 'classnames'
import React from 'react'
import s from './style.module.scss'

class Separator extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return <div className={cx([s.root, 'container', s[this.props.margin]])}></div>
  }
}

export default Separator
