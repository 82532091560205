import PropTypes from 'prop-types'
import React from 'react'
import { ContextWrapper } from '../ContextWrapper'
import ProfileForm from './../components/ProfileForm'

class ChildRegistration extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <ContextWrapper>
        <ProfileForm {...this.props} />
      </ContextWrapper>
    )
  }
}

ChildRegistration.defaultProps = {
  title: 'Registration container title',
}

ChildRegistration.propTypes = {
  title: PropTypes.string,
}

export default ChildRegistration
