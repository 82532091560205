import { Col, Input, Modal, Row } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React from 'react'
import Exposer from './../Exposer/'
import Loader from './../Loader/'
import PageItem from './../PageItem/'
import PageItemEditor from './../PageItemEditor/'
import s from './style.module.scss'

let ComponentList = []
for (var prop in Exposer.List) {
  ComponentList.push(prop)
}

class PageEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageComponents: [],
      loading: false,
      title: '',
    }
  }
  editComponent(componentName, index) {
    this.setState({
      showModal: true,
      formComponent: componentName,
      editingComponentIndex: index,
    })
  }
  deleteComponent(index) {
    Modal.confirm({
      title: 'Do you Want to delete this component?',
      content: 'This action is not reversible.',
      okText: 'Yes. Delete this component.',
      cancelText: 'No, dont delete it.',
      onOk: () => {
        let pageComponents = _.cloneDeep(this.state.pageComponents)
        pageComponents.splice(index, 1)
        this.setState({ pageComponents: pageComponents }, this.handleComponentData())
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  setForm(componentName) {
    let pageComponents = _.cloneDeep(this.state.pageComponents)
    pageComponents.push({})
    const editingComponentIndex = pageComponents.length - 1
    this.setState({
      showModal: true,
      formComponent: componentName,
      pageComponents: pageComponents,
      editingComponentIndex: editingComponentIndex,
    })
  }
  clearEmptyComponents() {
    let pageComponents = _.cloneDeep(this.state.pageComponents)
    for (var index = 0; index < pageComponents.length; index++) {
      Object.keys(pageComponents[index]).length === 0 && pageComponents.splice(index, 1)
    }
    return pageComponents
  }
  handleComponentData(redirect) {
    this.setState(
      {
        loading: true,
      },
      () => {
        // save data via ajax
        // determine whether to post to new profile path or edit profile path:
        const config = {
          headers: {
            'X-CSRF-Token': this.state.csrf,
            'Content-Type': 'application/json',
          },
        }
        const postPath = this.state.id ? `/pages/${this.state.id}` : `/pages`
        const method = this.state.id ? 'put' : 'post'
        axios[method](
          `${postPath}.json`,
          {
            title: this.state.title,
            content: JSON.stringify(this.state.pageComponents),
          },
          config
        )
          .then(
            (response) => {
              redirect == 'redirect' && window.location.replace(`/pages/${response.data.id}`)
              this.setState({
                id: response.data.id,
                showModal: false,
                formComponent: null,
                editingComponentIndex: null,
              })
              setTimeout(() => {
                this.setState({ loading: false })
              }, 1000)
            },
            (error) => {
              throw new Error(error.message)
            }
          )
          .catch((error) => {
            throw new Error(error.message)
          })
        // if error, display error
        // if not close modal with saved data and ensure you
        // update the post url accoringly for the new edit endpoint if it is was a new page
      }
    )
  }
  handleModalCancel() {
    const pageComponents = this.clearEmptyComponents()
    this.setState({
      showModal: false,
      formComponent: null,
      editingComponentIndex: null,
      pageComponents: pageComponents,
    })
  }
  handleInputChange(val, key, componentName, position) {
    let pageComponents = _.cloneDeep(this.state.pageComponents)
    let componentObject = pageComponents[position] || {}
    componentObject.name = componentName
    componentObject[key] = val
    pageComponents[position] = componentObject
    this.setState({
      pageComponents: pageComponents,
    })
  }
  handleKeyPress = (event) => {
    if (event.key == 'Enter') {
      this.handleComponentData()
    }
  }
  changeTitle(e) {
    this.setState({ title: e.target.value })
  }
  componentDidMount() {
    const meta = document.querySelector('meta[name="csrf-token"]')
    this.setState({
      csrf: meta.content,
      pageComponents: this.props.components,
      id: this.props.pageId,
      title: this.props.title,
    })
  }
  render() {
    const { components } = this.props
    const { pageComponents, title } = this.state
    const componentButtonStyle = {
      border: '1px solid black',
      padding: '5px 10px',
      display: 'inline-block',
      margin: '10px',
      cursor: 'pointer',
      borderRadius: '2px',
    }
    return (
      <div className={s.root}>
        {this.state.loading && (
          <div className={s.loading}>
            <p>Saving... please wait...</p>
            <Loader />
          </div>
        )}
        {title ? false : <div className={s.instructions}>Please enter a page title to build your page</div>}
        <div className={s.pageTitle}>
          <Input
            value={title}
            onChange={this.changeTitle.bind(this)}
            onKeyPress={this.handleKeyPress.bind(this)}
            placeholder="Page Title"
          />
        </div>
        {title && (
          <div className={s.componentPicker}>
            <Row>
              <Col span={24} sm={16}>
                <div className={s.addComponent}>
                  <strong>ADD COMPONENTS TO {title.toUpperCase()}</strong>
                </div>
                {title &&
                  ComponentList.map((name, index) => {
                    return (
                      <div
                        style={componentButtonStyle}
                        onClick={(e) => {
                          this.setForm(name)
                        }}
                        className={s.componentName}
                        key={index}
                      >
                        {name}
                      </div>
                    )
                  })}
              </Col>
              <Col span={24} sm={8}>
                <div className={s.addComponent}>
                  <strong>PAGE ACTIONS</strong>
                </div>
                <div
                  style={componentButtonStyle}
                  onClick={(e) => {
                    this.handleComponentData('redirect')
                  }}
                >
                  Save Page & View
                </div>
                <div
                  style={componentButtonStyle}
                  onClick={(e) => {
                    this.handleComponentData()
                  }}
                >
                  Save Page
                </div>
                <div
                  style={componentButtonStyle}
                  onClick={(e) => {
                    console.log('open sortable modal')
                  }}
                >
                  Reorder
                </div>
              </Col>
            </Row>
          </div>
        )}
        <div className={s.page}>
          {pageComponents.length > 0 &&
            pageComponents.map((component, index) => {
              return component.name ? (
                <div className={s.pageItem} key={index}>
                  <PageItem {...component} />
                  <div className={s.pageItemOverlay}>
                    <div className={s.componentActionLinks}>
                      <a className={s.componentActionLink} onClick={(e) => this.deleteComponent(index)}>
                        Delete This {component.name}
                      </a>
                      <a className={s.componentActionLink} onClick={(e) => this.editComponent(component.name, index)}>
                        Edit This {component.name}
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                false
              )
            })}
        </div>
        <div className="modal">
          <Modal
            title={`Edit ${this.state.formComponent}`}
            visible={this.state.showModal}
            onOk={this.handleComponentData.bind(this)}
            onCancel={this.handleModalCancel.bind(this)}
            okText="Save Page"
            cancelText="Don't save and continue editing"
          >
            {this.state.formComponent === 'ImageGroup' && (
              <div className={s.instructions}>
                To preserve this layout design, covers/ads must be added or removed in groups of 5. There are currently
                four slots of 5 covers/ads available - a total of 20 covers/ads. If you would like to add more than 20
                covers/ads on the page, please contact Dotfusion. Please consider that adding covers/ads will slow down
                page load time.{' '}
              </div>
            )}
            {this.state.formComponent && (
              <PageItemEditor
                name={this.state.formComponent}
                pageComponents={this.state.pageComponents}
                position={this.state.editingComponentIndex}
                handleInputChange={this.handleInputChange.bind(this)}
              />
            )}
          </Modal>
        </div>
      </div>
    )
  }
}

export default PageEditor
