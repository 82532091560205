import $ from 'jquery'
import PropTypes from 'prop-types'
import React from 'react'
import Dropzone from 'react-dropzone'
import s from './style.module.scss'

class FileUploader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      images: [],
    }
  }
  handleRemove(position) {
    let newval = this.props.value ? _.cloneDeep(this.props.value) : []
    newval.splice(position, 1)
    this.props.handleInputChange(newval, this.props.updatekey, this.props.componentName, this.props.position)
  }
  onDrop(files) {
    const csrf = document.querySelector('meta[name="csrf-token"]').content
    let formData = new FormData()
    formData.append('site_image[image]', files[0])
    this.setState({ imageLoading: true })
    $.ajax({
      url: `${this.props.uploadPath}.json`,
      method: 'POST',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', csrf)
      },
      contentType: false,
      processData: false,
      data: formData,
      success: (responsedata) => {
        if (responsedata.id) {
          let newval = this.props.value ? _.cloneDeep(this.props.value) : []
          newval.push(responsedata.image)
          this.props.handleInputChange(newval, this.props.updatekey, this.props.componentName, this.props.position)
        } else {
        }
      },
    })
  }
  render() {
    const images =
      this.props.value &&
      this.props.value.length > 0 &&
      this.props.value.map((image, index) => {
        return (
          <div key={index} className={s.image}>
            <div onClick={(e) => this.handleRemove(index)} className={s.removeImage}>
              X
            </div>
            <img src={image.url} />
          </div>
        )
      })
    const dropzone = (
      <Dropzone className={s.dropZone} onDrop={this.onDrop.bind(this)} ref="dropZone-image">
        <p className={s.dropZoneText}>
          Drag files here or <span className={s.dropZoneBrowse}>browse</span>
        </p>
      </Dropzone>
    )
    return (
      <div className={s.root}>
        {images}
        {this.props.type === 'single'
          ? this.props.value && this.props.value.length === 1
            ? false
            : dropzone
          : dropzone}
      </div>
    )
  }
}

FileUploader.defaultProps = {
  uploadPath: '/site_image',
}

FileUploader.propTypes = {
  uploadPath: PropTypes.string,
}

export default FileUploader
