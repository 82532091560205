import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import s from './style.module.scss'

class Loader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div className={s.loadingProgressGT}>
        <div className={cx(s.loadingProgressG)}></div>
      </div>
    )
  }
}

Loader.defaultProps = {
  title: 'Loaderiing default title',
}

Loader.propTypes = {
  title: PropTypes.string,
}

export default Loader
