import React, { useState } from 'react'
import axios from 'axios'
import { Button, Col, Icon, notification, Radio, Select, Table } from 'antd'

const initialFormState = { profileId: null, relationship: 'family' }

function NewRelatedProfile({ profiles, onSubmit }) {
  const [formState, setFormState] = useState(initialFormState)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async () => {
    setIsSubmitting(true)
    await onSubmit(formState)
    setIsSubmitting(false)
  }

  return (
    <div>
      <Col span={6}>
        <Select
          notFoundContent="Not Found"
          style={{ width: '100%' }}
          placeholder="Choose"
          value={formState.profileId}
          onChange={(value) => setFormState({ ...formState, profileId: value })}
          filterOption={(input, { props: { title } }) => title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          optionFilterProp="children"
          showSearch
        >
          {profiles.map(({ first_name, last_name, id }) => {
            const title = `${id} - ${first_name} ${last_name}`
            return (
              <Select.Option key={id} value={`${id}`} title={title}>
                {title}
              </Select.Option>
            )
          })}
        </Select>
      </Col>

      <Col span={4} offset={1}>
        <Radio.Group
          value={formState.relationship}
          size="large"
          onChange={(e) => setFormState({ ...formState, relationship: e.target.value })}
        >
          <Radio.Button value="cousin">Cousin</Radio.Button>
          <Radio.Button value="friend">Friend</Radio.Button>
        </Radio.Group>
      </Col>
      <Col offset={1} span={6}>
        <Button disabled={isSubmitting} className="admin-btn" type="primary" onClick={handleSubmit} size="large">
          Add
        </Button>
      </Col>
    </div>
  )
}

export function RelatedProfilesForm({
  profile,
  allActiveProfiles,
  relatedProfiles: initialRelatedProfiles,
  current_user,
}) {
  const [relatedProfiles, setRelatedProfiles] = useState(initialRelatedProfiles)
  const [showForm, setShowForm] = useState(false)
  const availableProfiles = allActiveProfiles.filter(
    ({ id }) => id !== profile.id && !relatedProfiles.map((p) => p.id).includes(id)
  )

  async function onSubmit({ profileId, relationship }) {
    try {
      const { data } = await axios.post(
        `/admins/${current_user.id}/create_profile_relationship/${profile.id}.json`,
        {
          related_profile_id: profileId,
          relation_type: relationship,
        },
        {
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
            'Content-Type': 'application/json',
          },
        }
      )
      setRelatedProfiles([...relatedProfiles, { ...data, id: +data.id, relationship_id: +data.relationship_id }])
      setShowForm(false)
    } catch (err) {
      notification.open({
        message: 'Error',
        description: 'Opps something went wrong. Please try again in a bit.',
        icon: <Icon type="warning" style={{ color: 'red' }} />,
      })
      throw new Error(err.message)
    }
  }

  async function removeRelatedProfile(relationshipId) {
    try {
      await axios.post(
        `/admins/${current_user.id}/delete_profile_relationship/${relationshipId}.json`,
        {},
        {
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
            'Content-Type': 'application/json',
          },
        }
      )
      setRelatedProfiles(relatedProfiles.filter(({ relationship_id }) => relationship_id !== relationshipId))
      setShowForm(false)
    } catch (err) {
      notification.open({
        message: 'Error',
        description: 'Opps something went wrong. Please try again in a bit.',
        icon: <Icon type="warning" style={{ color: 'red' }} />,
      })
      throw new Error(err.message)
    }
  }
  return (
    <>
      {!showForm ? (
        <Button className="admin-btn" type="primary" onClick={() => setShowForm(true)} size="large">
          Add
        </Button>
      ) : (
        <NewRelatedProfile profiles={availableProfiles} onSubmit={onSubmit} />
      )}
      {relatedProfiles && (
        <Col span={24}>
          <Table
            columns={[
              {
                title: 'ID',
                dataIndex: 'id',
                width: 150,
              },
              {
                title: 'Name',
                dataIndex: 'name',
                width: 270,
              },
              {
                title: 'Relationship',
                dataIndex: 'type',
                width: 270,
              },
              {
                title: 'Action',
                dataIndex: 'action',
                width: 120,
              },
            ]}
            dataSource={relatedProfiles.map((p) => ({
              key: p.id,
              id: (
                <a className="admin-link" target="_blank" href={`/profiles/${p.id}`}>
                  {p.id}
                </a>
              ),
              name: `${p.first_name} ${p.last_name}`,
              type: p.relation_type,
              action: (
                <button
                  className="ant-btn ant-btn-primary"
                  style={{ padding: '2px', marginLeft: '5px' }}
                  onClick={() => removeRelatedProfile(p.relationship_id)}
                >
                  Remove
                </button>
              ),
            }))}
            filterable={true}
          />
        </Col>
      )}
    </>
  )
}
