import React from 'react'
import { ContextWrapper } from '../ContextWrapper'
import SignupForm from './../components/SignupForm'

class Signup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <ContextWrapper>
        <div>
          <SignupForm parentTypes={this.props.parentTypes} provinces={this.props.provinces} />
        </div>
      </ContextWrapper>
    )
  }
}

export default Signup
