import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Slider from 'react-slick'
import Heading from './../Heading'
import defaultImage from './default.jpg'
import s from './style.module.scss'

const overlay = 'linear-gradient(to right, RGBA(252, 200, 143, 0.40), RGBA(252, 200, 143, 0.40))'

class SliderWithContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplaySpeed: 6000,
      autoplay: true,
    }
    const hasImages =
      (this.props.image1 && this.props.image1.length >= 1) ||
      (this.props.image2 && this.props.image2.length >= 1) ||
      (this.props.image3 && this.props.image3.length >= 1) ||
      (this.props.image4 && this.props.image4.length >= 1) ||
      (this.props.image5 && this.props.image5.length >= 1) ||
      (this.props.image6 && this.props.image6.length >= 1) ||
      (this.props.image7 && this.props.image7.length >= 1)
    return (
      <div className={s.root}>
        <div className={s.slider}>
          {hasImages && (
            <Slider {...sliderSettings}>
              {this.props.image1 && this.props.image1.length >= 1 && (
                <div
                  id="slide1"
                  className={s.slide}
                  style={{
                    backgroundPosition: 'top center',
                    backgroundImage: `${overlay}, url(${
                      this.props.image1.length > 0 ? this.props.image1[0].url : defaultImage
                    })`,
                  }}
                >
                  <div className={s.slidecontent}>
                    {this.props.title1 && <Heading low={true}>{this.props.title1}</Heading>}
                    <a className={cx('button secondary', s.button)} href={this.props.link1}>
                      {this.props.button1}
                    </a>
                  </div>
                </div>
              )}
              {this.props.image2 && this.props.image2.length >= 1 && (
                <div
                  id="slide2"
                  className={s.slide}
                  style={{
                    backgroundPosition: 'top center',
                    backgroundImage: `${overlay}, url(${
                      this.props.image2.length > 0 ? this.props.image2[0].url : defaultImage
                    })`,
                  }}
                >
                  <div className={s.slidecontent}>
                    {this.props.title2 && <Heading low={true}>{this.props.title2}</Heading>}
                    <a className={cx('button secondary', s.button)} href={this.props.link2}>
                      {this.props.button2}
                    </a>
                  </div>
                </div>
              )}
              {this.props.image3 && this.props.image3.length >= 1 && (
                <div
                  id="slide3"
                  className={s.slide}
                  style={{
                    backgroundPosition: 'top center',
                    backgroundImage: `${overlay}, url(${
                      this.props.image3.length > 0 ? this.props.image3[0].url : defaultImage
                    })`,
                  }}
                >
                  <div className={s.slidecontent}>
                    {this.props.title3 && <Heading low={true}>{this.props.title3}</Heading>}
                    <a className={cx('button secondary', s.button)} href={this.props.link3}>
                      {this.props.button3}
                    </a>
                  </div>
                </div>
              )}
              {this.props.image4 && this.props.image4.length >= 1 && (
                <div
                  id="slide4"
                  className={s.slide}
                  style={{
                    backgroundPosition: 'top center',
                    backgroundImage: `${overlay}, url(${
                      this.props.image4.length > 0 ? this.props.image4[0].url : defaultImage
                    })`,
                  }}
                >
                  <div className={s.slidecontent}>
                    {this.props.title4 && <Heading low={true}>{this.props.title4}</Heading>}
                    <a className={cx('button secondary', s.button)} href={this.props.link4}>
                      {this.props.button4}
                    </a>
                  </div>
                </div>
              )}
              {this.props.image5 && this.props.image5.length >= 1 && (
                <div
                  id="slide5"
                  className={s.slide}
                  style={{
                    backgroundPosition: 'top center',
                    backgroundImage: `${overlay}, url(${
                      this.props.image5.length > 0 ? this.props.image5[0].url : defaultImage
                    })`,
                  }}
                >
                  <div className={s.slidecontent}>
                    {this.props.title5 && <Heading low={true}>{this.props.title5}</Heading>}
                    <a className={cx('button secondary', s.button)} href={this.props.link5}>
                      {this.props.button5}
                    </a>
                  </div>
                </div>
              )}
              {this.props.image6 && this.props.image6.length >= 1 && (
                <div
                  id="slide6"
                  className={s.slide}
                  style={{
                    backgroundPosition: 'top center',
                    backgroundImage: `${overlay}, url(${
                      this.props.image6.length > 0 ? this.props.image6[0].url : defaultImage
                    })`,
                  }}
                >
                  <div className={s.slidecontent}>
                    {this.props.title6 && <Heading low={true}>{this.props.title6}</Heading>}
                    <a className={cx('button secondary', s.button)} href={this.props.link6}>
                      {this.props.button6}
                    </a>
                  </div>
                </div>
              )}
              {this.props.image7 && this.props.image7.length >= 1 && (
                <div
                  id="slide7"
                  className={s.slide}
                  style={{
                    backgroundPosition: 'top center',
                    backgroundImage: `${overlay}, url(${
                      this.props.image7.length > 0 ? this.props.image7[0].url : defaultImage
                    }`,
                  }}
                >
                  <div className={s.slidecontent}>
                    {this.props.title7 && <Heading low={true}>{this.props.title7}</Heading>}
                    <a className={cx('button secondary', s.button)} href={this.props.link7}>
                      {this.props.button7}
                    </a>
                  </div>
                </div>
              )}
            </Slider>
          )}
        </div>
      </div>
    )
  }
}

SliderWithContent.defaultProps = {
  title: 'Slidering default title',
}

SliderWithContent.propTypes = {
  title: PropTypes.string,
}

export default SliderWithContent
