import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { Button, Col, Modal, Row } from 'antd'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Slider from 'react-slick'
import FAQItem from '../FAQItem/'
import ResultCard from '../ResultCard/'
import __ from './../Common/'
import actra from './../ResultCard/actra.png'
import s from './style.module.scss'
import leftArrow from './left.png'
import rightArrow from './right.png'

const Arrow = ({ position, onClick }) => {
  return (
    <button onClick={onClick} className={cx(s.slickarrow, s[position])}>
      {position == 'left' ? <img src={leftArrow} /> : <img src={rightArrow} />}
    </button>
  )
}

const Sibling = ({ index, profile, onSiblingClick }) => {
  const images = __.parseIfString(profile.images)
  return (
    <div key={index} className={s.siblingCard}>
      <ResultCard key={index} profile={profile} size={'hundred'} />
    </div>
  )
}

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 'profile',
      modalVisible: false,
      image: null,
      preferredPhoto: null,
      profileImages: null,
    }
  }

  componentDidMount() {
    this.setImages('profile')
  }

  setImages(current) {
    const profileImages = __.parseIfString(this.props[current].images)
    const preferred = profileImages
      ? profileImages.find((item, index) => {
          return item.preferred == true
        })
      : null
    if (preferred) {
      let sorted = profileImages.filter((image) => image.preferred != true)
      sorted.unshift(preferred)
      this.setState({
        preferredPhoto: preferred,
        profileImages: sorted,
      })
    } else {
      this.setState({
        profileImages: profileImages,
      })
    }
  }

  toggleDrafts() {
    const current = this.state.current === 'profile' ? 'draft' : 'profile'
    this.setState({ current: current })
    this.setImages(current)
  }

  showImageModal(image_url) {
    this.setState({
      modalVisible: true,
      image: image_url,
    })
  }

  handleCancel() {
    this.setState({
      modalVisible: false,
      image: null,
    })
  }

  onSiblingClick(id) {
    console.log('click: ', id)
  }

  goBack(e) {
    e.preventDefault()
    if (document.referrer.includes('/home/search?filters=true')) {
      window.history.back()
    } else {
      window.location = this.props.paths.search_path
    }
  }

  render() {
    const {
      nick_name,
      primary,
      actra_apprentice_number,
      special_needs,
      actra_apprentice_full_number,
      id,
      about,
      modeled_for,
      first_name,
      last_name,
      hair_color,
      hair_length,
      hair_texture,
      eye_color,
      images,
      hand_images,
      audio_files,
      stats,
      updated_at,
      date_of_birth,
      stats_updated_at,
    } = this.props[this.state.current]
    const profile_images = __.parseIfString(images)
    const specialNeeds = __.parseIfString(special_needs)
    const hands = __.parseIfString(hand_images)
    const audio = __.parseIfString(audio_files)
    const siblings = this.props.siblings
    const profile_stats = __.parseIfString(stats)
    const updatedAt = new Date(stats_updated_at)
    const headshotTime = new Date(
      this.state.preferredPhoto ? this.state.preferredPhoto.created : this.props.headshotLastUpdated
    )
    const sliderSettings = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: false,
      prevArrow: <Arrow position="left" onClick={(e) => this.slider.slickPrev()} />,
      nextArrow: <Arrow position="right" onClick={(e) => this.slider.slickNext()} />,
    }
    const name = __.profileName(this.props[this.state.current])
    return (
      <div className={s.root}>
        <Modal
          title={null}
          visible={this.state.modalVisible}
          onOk={this.handleCancel}
          onCancel={() => this.handleCancel()}
          footer={null}
        >
          <div style={{ textAlign: 'center', marginLeft: 'auto' }}>
            <img style={{ maxWidth: '75%', marginLeft: 'auto' }} src={this.state.image} />
          </div>
        </Modal>

        <div className="container">
          <Row type="flex" justify="space-between">
            <Col span={24} sm={6} md={5}>
              <a className="search-link" onClick={(e) => this.goBack(e)}>
                &lt; back to search
              </a>
            </Col>
            <Col span={24} sm={6} md={5}>
              <ResultCard
                profile={this.props.profile}
                button={true}
                addText={'add to submissions'}
                removeText={'remove from submissions'}
              />
            </Col>
          </Row>

          {this.props.draft && this.props.profile.submission_count > 1 && (
            <Button
              className={cx(s.draftButton, 'admin-btn')}
              onClick={(e) => this.toggleDrafts()}
              type="primary"
              size="large"
            >
              {this.state.current === 'profile' ? 'Toggle Draft' : 'Toggle Current Profile'}
            </Button>
          )}
          <br />
          <br />
          <br />
          <Row>
            <Col span={24} sm={14}>
              <h1 className={cx(s.profileName, s.mobile)}>{name}.</h1>
              <div className={s.imageslider}>
                {this.state.profileImages && this.state.profileImages.length > 0 ? (
                  <Slider {...sliderSettings}>
                    {this.state.profileImages.map((image, index) => {
                      return (
                        <div key={`image-${image.id}`} className={s.sliderImageHolder}>
                          <img className={s.sliderImage} src={image.url} />
                        </div>
                      )
                    })}
                  </Slider>
                ) : (
                  'No Image Available'
                )}

                <div className={s.hasActra}>
                  {__.hasActra(this.props[this.state.current]) && <img src={actra} className={s.actra} alt="" />}
                </div>
              </div>
              <div className={cx(s.aboutme, 'hide-on-mobile')}>
                {about?.length > 0 && (
                  <>
                    <h4 className={s.aboutmeTitle}>About Me</h4>
                    <p className={s.aboutParagraph}>{about}</p>
                  </>
                )}
                {modeled_for?.length > 0 && (
                  <>
                    <h4 className={s.aboutmeTitle}>Modeled For</h4>
                    <p className={s.aboutParagraph}>{modeled_for}</p>
                  </>
                )}
              </div>
              {specialNeeds && !_.isEmpty(specialNeeds) && (
                <div className={s.specialNeeds}>
                  <div className="icon-special-needs-holder">
                    <span className="icon-special-needs"></span>
                  </div>
                  <h4 className={s.aboutmeTitle}>Special Needs:</h4>

                  {Object.keys(specialNeeds).map((key, index) => {
                    return (
                      <li key={index} style={{ paddingLeft: '50px' }}>
                        {` ${String(key)}${specialNeeds[key].length >= 1 ? `: ${specialNeeds[key]}` : ''}`}
                      </li>
                    )
                  })}
                </div>
              )}
            </Col>
            <Col span={24} sm={10}>
              <div className={s.profileinfo}>
                <h1 className={cx([s.profileName, s.desktop, 'h3'])}>{name}.</h1>
                <p className={s.profileStats}>
                  age <span className={s.stat}>{__.displayAge(date_of_birth)}</span>
                </p>
                <p className={s.profileStats}>
                  size <span className={s.stat}>{profile_stats.size}</span>
                </p>
                <p className={s.profileStats}>
                  shoe size <span className={s.stat}>{profile_stats.shoeSize}</span>
                </p>

                <p className={s.profileStats}>
                  height{' '}
                  <span className={s.stat}>
                    {profile_stats.height.ft} ft.{' '}
                    {profile_stats.height.inches > 0 ? `${profile_stats.height.inches} in.` : ''}{' '}
                  </span>
                </p>
                {!this.props.greaterThanThree && (
                  <p className={s.profileStats}>
                    weight <span className={s.stat}>{profile_stats.weight} lbs</span>
                  </p>
                )}
                <p className={s.profileStats}>
                  hair{' '}
                  <span className={s.stat}>
                    {hair_color} / {hair_length} / {hair_texture}
                  </span>
                </p>
                <p className={s.profileStats}>
                  eyes <span className={s.stat}>{eye_color}</span>
                </p>
                {this.props.greaterThanThree ? (
                  <div>
                    <p className={s.profileStats}>
                      inseam <span className={s.stat}>{profile_stats.inseam} in.</span>
                    </p>
                    <p className={s.profileStats}>
                      waist <span className={s.stat}>{profile_stats.waist} in.</span>
                    </p>
                    <p className={s.profileStats}>
                      chest <span className={s.stat}>{profile_stats.chest} in.</span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className={s.profileStats}>
                      nape to crotch <span className={s.stat}>{profile_stats.napeToCrotch} in.</span>
                    </p>
                    <p className={s.profileStats}>
                      nape to ankle <span className={s.stat}>{profile_stats.napeToAnkle} in.</span>
                    </p>
                    <p className={s.profileStats}>
                      nape to wrist <span className={s.stat}>{profile_stats.napeToWrist} in.</span>
                    </p>
                  </div>
                )}

                <div className={cx(s.lastUpdated, 'hide-on-mobile')}>
                  <div className={s.statsAlert}>
                    <span className={cx('icon-bell', s.bell)}></span>
                    Stats last updated {updatedAt.toDateString()}
                  </div>
                  {this.props.headshotLastUpdated && (
                    <div className={s.statsAlert}>
                      <span className={cx('icon-bell', s.bell)}></span>
                      Headshot last updated {headshotTime.toDateString()}
                    </div>
                  )}
                  <br />
                </div>
                {audio && audio.length > 0 && (
                  <div className="audio">
                    <FAQItem title="Voice Demo">
                      {audio.map((voice, index) => {
                        return (
                          <div key={`voice-${index}`}>
                            <audio controls>
                              <source src={voice.url} type="audio/mp3" /> Your browser does not support the audio
                              element.
                            </audio>
                          </div>
                        )
                      })}
                    </FAQItem>
                  </div>
                )}
                {hands && hands.length > 0 && (
                  <div className="hands">
                    <FAQItem title="Hand Images">
                      Click to zoom.
                      {hands.map((hand, index) => {
                        return (
                          <div
                            key={`voice-${index}`}
                            onClick={(e) => this.showImageModal(hand.url)}
                            className={s.handIcon}
                            style={{ height: '50px', width: '50px' }}
                          >
                            <img src={hand.url} style={{ width: '100%' }} alt="" />
                          </div>
                        )
                      })}
                    </FAQItem>
                  </div>
                )}
                <div className="hide-on-mobile">
                  <br />
                  <br />
                  <a href={this.props.paths.pdf_path} target="_blank" className={cx('button inline', s.resumeButton)}>
                    view resume
                  </a>
                  <a
                    href={this.props.paths.pdf_path + '?comp=true'}
                    target="_blank"
                    className="button secondary inline"
                  >
                    download comp card
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="hide-on-desktop">
            <Col>
              <div className={s.aboutme}>
                <h4 className={s.aboutmeTitle}>About Me</h4>
                <p className={s.aboutParagraph}>{about}</p>
                {modeled_for?.length > 0 && (
                  <>
                    <h4 className={s.aboutmeTitle}>Modeled For</h4>
                    <p className={s.aboutParagraph}>{modeled_for}</p>
                  </>
                )}
              </div>
            </Col>
          </Row>

          {(siblings && siblings.length > 1) || (siblings.length == 1 && siblings[0].id != id) ? (
            <Row className="hide-on-desktop">
              <Col span={24} sm={14}>
                <h2>Siblings</h2>
                <div className={s.cardContainer}>
                  {siblings.map((sibling, index) => {
                    return sibling.id !== id ? (
                      <Sibling key={`sibling-${index}`} onSiblingClick={this.onSiblingClick} profile={sibling} />
                    ) : (
                      false
                    )
                  })}
                </div>
              </Col>
            </Row>
          ) : null}

          <Row className="hide-on-desktop">
            <Col>
              <div className={s.lastUpdated}>
                <div className={s.statsAlert}>
                  <span className={cx('icon-bell', s.bell)}></span>
                  Stats last updated {updatedAt.toDateString()}
                </div>
                {this.props.headshotLastUpdated && (
                  <div className={s.statsAlert}>
                    <span className={cx('icon-bell', s.bell)}></span> Headshot last updated{' '}
                    {headshotTime.toDateString()}
                  </div>
                )}
                <br />
              </div>
            </Col>
          </Row>
          <Row className="hide-on-desktop">
            <Col>
              <a href={this.props.paths.pdf_path} target="_blank" className={cx('button inline', s.resumeButton)}>
                view resume
              </a>
              <a href={this.props.paths.pdf_path + '?comp=true'} target="_blank" className="button secondary inline">
                download comp card
              </a>
            </Col>
          </Row>

          {(siblings && siblings.length > 1) || (siblings.length == 1 && siblings[0].id != id) ? (
            <Row className="hide-on-mobile">
              <Col span={24} sm={14}>
                <h2>Siblings</h2>
                <div className={s.cardContainer}>
                  {siblings.map((sibling, index) => {
                    return sibling.id !== id ? (
                      <Sibling key={`sibling-${index}`} onSiblingClick={this.onSiblingClick} profile={sibling} />
                    ) : (
                      false
                    )
                  })}
                </div>
              </Col>
            </Row>
          ) : null}
        </div>
      </div>
    )
  }
}

Profile.defaultProps = {
  title: 'Profileing default title',
}

Profile.propTypes = {
  title: PropTypes.string,
}

export default Profile
