import { Col, Row, Select } from 'antd'
import React from 'react'
import Input from '../Input'
import s from './style.module.scss'

class SignupForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      email_confirmation: '',
      pw1: null,
      pw2: null,
      firstName: null,
      lastName: null,
      parentType: null,
      streetAddress: null,
      unit: null,
      province: null,
      postalCode: null,
    }
  }

  setInput(key, val) {
    this.setState({
      [key]: val,
    })
  }

  componentDidMount() {
    this.setState({
      province: this.props.provinces[0],
      parentType: this.props.parentTypes[0],
    })
  }

  render() {
    return (
      <div>
        <Row>
          <Col span={24} md={8}>
            <div className={s.formLabel}>Email</div>
          </Col>
          <Col span={24} md={16}>
            <Input
              ref="email"
              value={this.state.email ? this.state.email : ''}
              stateKey="email"
              type="email"
              name="user[email]"
              required={true}
              onChange={this.setInput.bind(this)}
              placeholder="Enter your email"
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} md={8}>
            <div className={s.formLabel}>Confirm Email</div>
          </Col>
          <Col span={24} md={16}>
            <Input
              ref="email_confirmation"
              value={this.state.email_confirmation ? this.state.email_confirmation : ''}
              stateKey="email_confirmation"
              type="email"
              name="user[email_confirmation]"
              required={true}
              onChange={this.setInput.bind(this)}
              placeholder="Confirm Email"
            />
          </Col>
        </Row>
        {this.state.email && this.state.email.trim() != this.state.email_confirmation.trim() && (
          <Row>
            <Col span={24}>
              <p className="error" style={{ color: 'red', fontStyle: 'italic', marginLeft: '20px' }}>
                Make sure your emails match!
              </p>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24} md={8}>
            <div className={s.formLabel}>
              Password <em>(six characters minimum)</em>
            </div>
          </Col>
          <Col span={24} md={16}>
            <Input
              type="password"
              ref="pw1"
              name="user[password]"
              value={this.state.pw1 ? this.state.pw1 : ''}
              stateKey="pw1"
              onChange={this.setInput.bind(this)}
              placeholder="Enter a password"
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} md={8}></Col>
          <Col span={24} md={16}>
            <Input
              type="password"
              ref="pw2"
              name="user[password_confirmation]"
              value={this.state.pw2 ? this.state.pw2 : ''}
              stateKey="pw2"
              onChange={this.setInput.bind(this)}
              placeholder="Re-enter password"
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} md={8}>
            <div className={s.formLabel}>First Name</div>
          </Col>
          <Col span={24} md={16}>
            <Input
              ref="firstName"
              name="user[first_name]"
              value={this.state.firstName ? this.state.firstName : ''}
              stateKey="firstName"
              validate="isAlpha"
              required={true}
              onChange={this.setInput.bind(this)}
              placeholder="Parent/Guardian's Name"
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} md={8}>
            <div className={s.formLabel}>Last Name</div>
          </Col>
          <Col span={24} md={16}>
            <Input
              ref="lastName"
              value={this.state.lastName ? this.state.lastName : ''}
              stateKey="lastName"
              validate="isAlpha"
              name="user[last_name]"
              required={true}
              onChange={this.setInput.bind(this)}
              placeholder="Parent/Guardian's Last Name"
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} md={8}>
            <div className={s.formLabel}>Parent Type</div>
          </Col>
          <Col span={24} md={16}>
            <Select
              style={{ width: '100%' }}
              placeholder="Parent Type"
              stateKey="parentType"
              name="user[parent_type]"
              value={this.state.parentType ? this.state.parentType : this.props.parentTypes[0]}
              onChange={(e) => this.setInput('parentType', e)}
              optionFilterProp="children"
            >
              {this.props.parentTypes &&
                this.props.parentTypes.map((parentType, index) => {
                  return (
                    <Select.Option key={parentType} value={parentType}>
                      {parentType}
                    </Select.Option>
                  )
                })}
            </Select>
            {this.state.parentType && <input type="hidden" value={this.state.parentType} name="user[parent_type]" />}
          </Col>
        </Row>
        <Row>
          <Col span={24} md={8}>
            <div className={s.formLabel}>Street Address</div>
          </Col>
          <Col span={24} md={16}>
            <Input
              ref="streetAddress"
              value={this.state.streetAddress ? this.state.streetAddress : ''}
              stateKey="streetAddress"
              name="user[street_address]"
              required={true}
              onChange={this.setInput.bind(this)}
              placeholder="Parent/Guardian's Address"
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} md={8}>
            <div className={s.formLabel}>Unit</div>
          </Col>
          <Col span={24} md={16}>
            <Input
              ref="unit"
              value={this.state.unit ? this.state.unit : ''}
              stateKey="unit"
              user="user[unit]"
              required={true}
              onChange={this.setInput.bind(this)}
              placeholder="Unit # (if applicable)"
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} md={8}>
            <div className={s.formLabel}>City</div>
          </Col>
          <Col span={24} md={16}>
            <Input
              ref="city"
              name="user[city]"
              value={this.state.city ? this.state.city : ''}
              stateKey="city"
              validate="isAlpha"
              required={true}
              onChange={this.setInput.bind(this)}
              placeholder="City"
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} md={8}>
            <div className={s.formLabel}>Province</div>
          </Col>
          <Col span={24} md={16}>
            <Select
              notFoundContent="Not Found"
              showSearch
              style={{ width: '100%' }}
              placeholder="Province"
              stateKey="province"
              name="user[province]"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={this.state.province ? this.state.province : this.props.provinces[0]}
              onChange={(e) => this.setInput('province', e)}
              optionFilterProp="children"
            >
              {this.props.provinces &&
                this.props.provinces.map((province, index) => {
                  return (
                    <Select.Option key={province} value={province}>
                      {province}
                    </Select.Option>
                  )
                })}
            </Select>
            {this.state.province && <input type="hidden" value={this.state.province} name="user[province]" />}
          </Col>
        </Row>
        <Row>
          <Col span={24} md={8}>
            <div className={s.formLabel}>Postal Code</div>
          </Col>
          <Col span={24} md={16}>
            <Input
              ref="postalCode"
              value={this.state.postalCode ? this.state.postalCode : ''}
              stateKey="postalCode"
              name="user[postal_code]"
              required={true}
              onChange={this.setInput.bind(this)}
              placeholder="Parent/Guardian's Postal Code"
            />
          </Col>
        </Row>
      </div>
    )
  }
}

export default SignupForm
