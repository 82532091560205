import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import Logo from './../Logo/'
import s from './style.module.scss'

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className={s.root}>
        <div className="container">
          <Row gutter={40}>
            <Col span={24} sm={5}>
              <h3>Quick Links</h3>
              <ul className={s.siteLinks}>
                {this.props.siteLinks.map((link, index) => {
                  return (
                    <li key={index}>
                      <a href={link.url}>{link.title}</a>
                    </li>
                  )
                })}
              </ul>
            </Col>
            <Col span={24} sm={5}>
              <h3>Search Talent</h3>
              <ul className={s.siteLinks}>
                {this.props.siteSearches.map((link, index) => {
                  return (
                    <li key={index}>
                      <a href={link.url}>{link.title}</a>
                    </li>
                  )
                })}
              </ul>
            </Col>
            <Col span={24} sm={5}>
              <h3>Contact</h3>
              <div className={s.contactInfo}>
                <h4>416-405-8755</h4>
                <p>
                  20 Bay St, 11th Floor, <br />
                  Toronto, ON, M5J 2N8
                </p>
                <a href="https://www.facebook.com/minordetailsagency/" className="f-facebook-icon" target="_blank">
                  f
                </a>
              </div>
            </Col>
            <Col span={24} sm={9}>
              <div className={s.logoholder}>
                <Logo className={s.logo} />
                Copyright © {new Date().getFullYear()} Minor Details Model and Talent <br />
                All Rights Reserved <br /> <br />
                All components of this website are and remain
                <br />
                the exclusive property of Minor Details Model
                <br />
                and Talent, and are protected under applicable
                <br />
                Canadian Federal law
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

Footer.defaultProps = {
  siteLinks: [
    {
      title: 'A Title',
      url: '/path',
    },
  ],
}

Footer.propTypes = {
  siteLinks: PropTypes.array,
}

export default Footer
