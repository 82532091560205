import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import s from './style.module.scss'

class PersonCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div className={this.props.narrow ? cx(s.root, s.narrow) : s.root}>
        <div className={s.name}>{this.props.name}</div>
        <div className={this.props.noBorder ? cx(s.title, s.noBorder) : s.title}>{this.props.title}</div>
        {this.props.image && this.props.image.length > 0 && (
          <div className={s.imageHolder}>
            <img src={this.props.image[0].url} />
          </div>
        )}
        {this.props.text && <div className={s.text} dangerouslySetInnerHTML={{ __html: this.props.text }} />}
      </div>
    )
  }
}

PersonCard.defaultProps = {
  title: 'PersonCarding default title',
}

PersonCard.propTypes = {
  title: PropTypes.string,
}

export default PersonCard
