import React, { useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import __ from './../components/Common'
import DataTable from './../components/DataTable'

import { notification, Button, Icon } from 'antd'
import { ContextWrapper } from '../ContextWrapper'

export default ({ updates: rawUpdates, showingAll }) => {
  const [updates, setUpdates] = useState(rawUpdates)

  const markViewed = async (updateId) => {
    try {
      const { data } = await axios.post(
        `/admins/realfamily_update_mark_viewed.json`,
        { id: updateId },
        { headers: __.getHeaders() }
      )
      setUpdates(
        updates.map((update) => {
          if (update.id === updateId) {
            return {
              ...update,
              viewed_at: data.viewed_at,
              viewed_by_id: data.viewed_by_id,
            }
          }
          return update
        })
      )
    } catch (err) {
      notification.open({
        message: 'Error',
        description: 'Opps something went wrong. Please try again in a bit.',
        icon: <Icon type="warning" style={{ color: 'red' }} />,
      })
      throw new Error(err.message)
    }
  }

  return (
    <ContextWrapper>
      <>
        <p style={{ margin: '20px 0' }}>
          {showingAll ? (
            <a href="/admins/realfamily_updates">Show New Only</a>
          ) : (
            <>
              By default, we only show notices that have not been marked as 'viewed'.{' '}
              <a href="/admins/realfamily_updates?show_all=true">Show All</a>
            </>
          )}
        </p>
        <DataTable
          defaultPageSize={15}
          defaultSorted={[
            {
              id: 'created_at',
              desc: true,
            },
          ]}
          columns={[
            {
              Header: 'Family Name',
              accessor: 'user',
              width: 200,
              Cell: (row) => (
                <a
                  className="admin-link"
                  target="_blank"
                  href={`/admins/realfamily?filters=true&name=${row.value.family_name}`}
                >
                  {row.value.family_name} Family
                </a>
              ),
            },
            {
              Header: 'Child(s) Linked',
              accessor: 'user',
              width: 150,
              Cell: (row) => (
                <>
                  {row.value.profiles.map((profile, i) => (
                    <span key={i}>
                      <a className="admin-link" target="_blank" href={`/profiles/${profile.id}`}>
                        {profile.id}
                      </a>
                      {i < row.value.profiles.length - 1 && ', '}
                    </span>
                  ))}
                </>
              ),
            },
            {
              Header: 'Parent ID',
              accessor: 'user.id',
              width: 75,
            },

            {
              Header: 'Updates',
              accessor: 'updates',
              Cell: (row) => (
                <div>
                  {row.value.image ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <img style={{ width: '100px' }} src={row.value.image[0]} />
                      <Icon type="right" />
                      <img style={{ width: '100px' }} src={row.value.image[1]} />
                    </div>
                  ) : (
                    JSON.stringify(row.value)
                  )}
                </div>
              ),
            },
            {
              Header: 'Time',
              accessor: 'created_at',
              width: 150,
              Cell: (row) => moment(row.value).format('MMM DD, YYYY h:mm a'),
            },
            {
              Header: 'Received',
              accessor: 'viewed_at',
              width: 100,
              Cell: (row) => (
                <div>
                  {row.value ? (
                    moment(row.value).format('MMM DD, YYYY h:mm a')
                  ) : (
                    <Button type="primary" onClick={() => markViewed(row.original.id)} icon="check"></Button>
                  )}
                </div>
              ),
            },
          ]}
          rows={updates}
        />
      </>
    </ContextWrapper>
  )
}
