import React from 'react'
import ParallaxCrayon from './../components/ParallaxCrayon'

class ParallaxContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  acceptable() {
    let { currentPath, controllerName, actionName } = this.props
    //  console.log('currentPath: ', currentPath);
    //  console.log('controllerName: ', controllerName);
    //  console.log('actionName: ', actionName);
    if (controllerName == 'home' && actionName == 'index') {
      return true
    } else if (controllerName == 'pages' && actionName == 'show') {
      if (currentPath == '/faqs') {
        return true
      }
    } else if (controllerName == 'sessions' && actionName == 'new') {
      return true
    }
    return false
  }

  render() {
    let display = this.acceptable()
    // console.log('display: ', display)
    return <div>{display ? <ParallaxCrayon currentPath={this.props.currentPath} /> : null}</div>
  }
}

ParallaxContainer.defaultProps = {}

ParallaxContainer.propTypes = {}

export default ParallaxContainer
