import { Col, Row } from 'antd'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import s from './style.module.scss'

class ListIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.generateListRow = this.generateListRow.bind(this)
  }

  render() {
    let rows = this.generateListRow()

    return (
      <div className={s.root}>
        <div className="item-title">{this.props.title}</div>
        {rows}
      </div>
    )
  }

  generateListRow() {
    let arr = this.props.list.map((item, index) => {
      const isDisabled = this.props.closed && item.links?.length === 2
      return (
        <Row key={`row-${index}`} style={{ marginBottom: '24px' }}>
          <Col span={12}>
            <div
              style={{ fontSize: '18px', color: '#5E5E5E', fontWeight: 900 }}
              dangerouslySetInnerHTML={{ __html: item.name }}
            ></div>
            {item.subtext && (
              <span className={s.subText}>
                <i className={cx('icon-info-triangle', s.listTriangle)}></i>
                {item.subtext}
              </span>
            )}
          </Col>

          {item.links &&
            item.links.map((link, index) => {
              return (
                <Col key={`col-${index}`} span={4}>
                  {link.callback ? (
                    <a disabled={isDisabled} onClick={() => link.callback(item.id)}>
                      {link.name}
                    </a>
                  ) : (
                    <a disabled={isDisabled} href={link.path}>
                      {link.name}
                    </a>
                  )}
                </Col>
              )
            })}
        </Row>
      )
    })
    return arr
  }
}

ListIndex.defaultProps = {
  title: 'Profile',
}

ListIndex.propTypes = {
  items: PropTypes.array,
}

export default ListIndex
