import React from 'react'
import Notice from './../components/Notice/'

class Notification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return <Notice {...this.props} />
  }
}

export default Notification
