import { Input, Radio } from 'antd'
import CardDisplay from './../CardDisplay/'
import FAQItem from './../FAQItem/'
import FeaturedImage from './../FeaturedImage/'
import FileUploader from './../FileUploader'
import Heading from './../Heading/'
import ImageGroup from './../ImageGroup/'
import MapItem from './../MapItem/'
import PersonWithSlider from './../PersonWithSlider/'
import RichText from './../RichText/'
import RtaParallax from './../RtaParallax/'
import Separator from './../Separator/'
import Showoff from './../Showoff/'
import SliderWithContent from './../SliderWithContent/'
import ThreePersonCard from './../ThreePersonCard/'

const List = {
  RtaParallax,
  Heading,
  FAQItem,
  Showoff,
  CardDisplay,
  ThreePersonCard,
  PersonWithSlider,
  Separator,
  SliderWithContent,
  FeaturedImage,
  ImageGroup,
  MapItem,
}

const Fields = {
  MapItem: [
    { name: 'address', field: 'Input', type: 'text' },
    { name: 'city', field: 'Input', type: 'text' },
    { name: 'province', field: 'Input', type: 'text' },
    { name: 'country', field: 'Input', type: 'text' },
    { name: 'postalCode', field: 'Input', type: 'text' },
    { name: 'email', field: 'Input', type: 'text' },
    { name: 'phone', field: 'Input', type: 'text' },
    { name: 'businessHours', field: 'Input', type: 'text' },
    { name: 'mapLatitude', field: 'Input', type: 'number' },
    { name: 'mapLongitude', field: 'Input', type: 'number' },
  ],
  ImageGroup: [
    { name: 'image_1', field: 'FileUploader', type: 'single' },
    { name: 'onHover_1', field: 'Input', type: 'text' },

    { name: 'image_2', field: 'FileUploader', type: 'single' },
    { name: 'onHover_2', field: 'Input', type: 'text' },

    { name: 'image_3', field: 'FileUploader', type: 'single' },
    { name: 'onHover_3', field: 'Input', type: 'text' },

    { name: 'image_4', field: 'FileUploader', type: 'single' },
    { name: 'onHover_4', field: 'Input', type: 'text' },

    { name: 'image_5', field: 'FileUploader', type: 'single' },
    { name: 'onHover_5', field: 'Input', type: 'text' },

    { name: 'image_6', field: 'FileUploader', type: 'single' },
    { name: 'onHover_6', field: 'Input', type: 'text' },

    { name: 'image_7', field: 'FileUploader', type: 'single' },
    { name: 'onHover_7', field: 'Input', type: 'text' },

    { name: 'image_8', field: 'FileUploader', type: 'single' },
    { name: 'onHover_8', field: 'Input', type: 'text' },

    { name: 'image_9', field: 'FileUploader', type: 'single' },
    { name: 'onHover_9', field: 'Input', type: 'text' },

    { name: 'image_10', field: 'FileUploader', type: 'single' },
    { name: 'onHover_10', field: 'Input', type: 'text' },

    { name: 'image_11', field: 'FileUploader', type: 'single' },
    { name: 'onHover_11', field: 'Input', type: 'text' },

    { name: 'image_12', field: 'FileUploader', type: 'single' },
    { name: 'onHover_12', field: 'Input', type: 'text' },

    { name: 'image_13', field: 'FileUploader', type: 'single' },
    { name: 'onHover_13', field: 'Input', type: 'text' },

    { name: 'image_14', field: 'FileUploader', type: 'single' },
    { name: 'onHover_14', field: 'Input', type: 'text' },

    { name: 'image_15', field: 'FileUploader', type: 'single' },
    { name: 'onHover_15', field: 'Input', type: 'text' },

    { name: 'image_16', field: 'FileUploader', type: 'single' },
    { name: 'onHover_16', field: 'Input', type: 'text' },

    { name: 'image_17', field: 'FileUploader', type: 'single' },
    { name: 'onHover_17', field: 'Input', type: 'text' },

    { name: 'image_18', field: 'FileUploader', type: 'single' },
    { name: 'onHover_18', field: 'Input', type: 'text' },

    { name: 'image_19', field: 'FileUploader', type: 'single' },
    { name: 'onHover_19', field: 'Input', type: 'text' },

    { name: 'image_20', field: 'FileUploader', type: 'single' },
    { name: 'onHover_20', field: 'Input', type: 'text' },
  ],
  FeaturedImage: [
    { name: 'title', field: 'Input', type: 'text' },
    { name: 'subtitle', field: 'Input', type: 'text' },
    { name: 'text', field: 'RichText' },
    { name: 'image', field: 'FileUploader', type: 'single' },
    { name: 'btnText', field: 'Input', type: 'text' },
    { name: 'btnLink', field: 'Input', type: 'text' },
  ],
  RtaParallax: [
    { name: 'title', field: 'Input', type: 'text' },
    { name: 'text', field: 'RichText' },
    { name: 'url', field: 'Input', type: 'text' },
    { name: 'openInNewTab', field: 'Radio', options: ['no', 'yes'] },
    { name: 'buttonText', field: 'Input', type: 'text' },
    { name: 'image', field: 'FileUploader', type: 'single' },
  ],
  Heading: [
    { name: 'headingLevel', field: 'Radio', options: ['h1', 'h2', 'h3', 'h4', 'h5'] },
    { name: 'title', field: 'Input', type: 'text' },
  ],
  FAQItem: [
    { name: 'title', field: 'Input', type: 'text' },
    { name: 'answer', field: 'RichText' },
  ],
  Showoff: [
    { name: 'centerImage', field: 'FileUploader', type: 'single' },
    { name: 'title', field: 'Input', type: 'text' },
    { name: 'centerText', field: 'RichText' },
    { name: 'leftImage', field: 'FileUploader', type: 'single' },
    { name: 'leftText', field: 'RichText' },
    { name: 'leftFBUrl', field: 'Input' },
    { name: 'rightImage', field: 'FileUploader', type: 'single' },
    { name: 'rightText', field: 'RichText' },
    { name: 'rightFBUrl', field: 'Input' },
  ],
  CardDisplay: [
    { name: 'title', field: 'Input', type: 'text' },
    { name: 'subtitle', field: 'Input', type: 'text' },
    { name: 'titleSize', field: 'Radio', options: ['h1', 'h2', 'h3', 'h4', 'h5'] },
    { name: 'card1Title', field: 'RichText', type: 'text' },
    { name: 'card1Img', field: 'FileUploader', type: 'single' },
    { name: 'card1BgImage', field: 'FileUploader', type: 'single' },
    { name: 'card1Text', field: 'RichText', type: 'text' },
    { name: 'card1BtnTxt', field: 'Input', type: 'text' },
    { name: 'card1BtnColour', field: 'Radio', options: ['pink', 'green'] },
    { name: 'card1BtnLink', field: 'Input', type: 'text' },
    { name: 'card1BtnOpenInNewTab', field: 'Radio', options: ['true', 'false'] },
    { name: 'card2Title', field: 'RichText', type: 'text' },
    { name: 'card2Img', field: 'FileUploader', type: 'single' },
    { name: 'card2BgImage', field: 'FileUploader', type: 'single' },
    { name: 'card2Text', field: 'RichText', type: 'text' },
    { name: 'card2BtnTxt', field: 'Input', type: 'text' },
    { name: 'card2BtnColour', field: 'Radio', options: ['pink', 'green'] },
    { name: 'card2BtnLink', field: 'Input', type: 'text' },
    { name: 'card2BtnOpenInNewTab', field: 'Radio', options: ['true', 'false'] },
    { name: 'card3Title', field: 'RichText', type: 'text' },
    { name: 'card3Img', field: 'FileUploader', type: 'single' },
    { name: 'card3BgImage', field: 'FileUploader', type: 'single' },
    { name: 'card3Text', field: 'RichText', type: 'text' },
    { name: 'card3BtnTxt', field: 'Input', type: 'text' },
    { name: 'card3BtnColour', field: 'Radio', options: ['pink', 'green'] },
    { name: 'card3BtnLink', field: 'Input', type: 'text' },
    { name: 'card3BtnOpenInNewTab', field: 'Radio', options: ['true', 'false'] },
  ],
  ThreePersonCard: [
    { name: 'name1', field: 'Input', type: 'text' },
    { name: 'title1', field: 'Input', type: 'text' },
    { name: 'image1', field: 'FileUploader', type: 'single' },
    { name: 'text1', field: 'RichText' },
    { name: 'name2', field: 'Input', type: 'text' },
    { name: 'title2', field: 'Input', type: 'text' },
    { name: 'image2', field: 'FileUploader', type: 'single' },
    { name: 'text2', field: 'RichText' },
    { name: 'name3', field: 'Input', type: 'text' },
    { name: 'title3', field: 'Input', type: 'text' },
    { name: 'image3', field: 'FileUploader', type: 'single' },
    { name: 'text3', field: 'RichText' },
  ],
  PersonWithSlider: [
    { name: 'name1', field: 'Input', type: 'text' },
    { name: 'title', field: 'Input', type: 'text' },
    { name: 'text', field: 'RichText' },
    { name: 'images', field: 'FileUploader', type: 'multiple' },
  ],
  Separator: [{ name: 'margin', field: 'Radio', options: ['large', 'medium', 'small', 'none'] }],
  SliderWithContent: [
    { name: 'image1', field: 'FileUploader', type: 'multi' },
    { name: 'title1', field: 'Input', type: 'text' },
    { name: 'link1', field: 'Input', type: 'text' },
    { name: 'button1', field: 'Input', type: 'text' },
    { name: 'image2', field: 'FileUploader', type: 'single' },
    { name: 'title2', field: 'Input', type: 'text' },
    { name: 'link2', field: 'Input', type: 'text' },
    { name: 'button2', field: 'Input', type: 'text' },
    { name: 'image3', field: 'FileUploader', type: 'single' },
    { name: 'title3', field: 'Input', type: 'text' },
    { name: 'link3', field: 'Input', type: 'text' },
    { name: 'button3', field: 'Input', type: 'text' },
    { name: 'image4', field: 'FileUploader', type: 'single' },
    { name: 'title4', field: 'Input', type: 'text' },
    { name: 'link4', field: 'Input', type: 'text' },
    { name: 'button4', field: 'Input', type: 'text' },
    { name: 'image5', field: 'FileUploader', type: 'single' },
    { name: 'title5', field: 'Input', type: 'text' },
    { name: 'link5', field: 'Input', type: 'text' },
    { name: 'button5', field: 'Input', type: 'text' },
    { name: 'image6', field: 'FileUploader', type: 'single' },
    { name: 'title6', field: 'Input', type: 'text' },
    { name: 'link6', field: 'Input', type: 'text' },
    { name: 'button6', field: 'Input', type: 'text' },
    { name: 'image7', field: 'FileUploader', type: 'single' },
    { name: 'title7', field: 'Input', type: 'text' },
    { name: 'link7', field: 'Input', type: 'text' },
    { name: 'button7', field: 'Input', type: 'text' },
  ],
}

const Form = {
  Radio,
  Input,
  FileUploader,
  RichText,
}

const Exposer = {
  List,
  Fields,
  Form,
}

export default Exposer
