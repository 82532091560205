import { Checkbox, Col, DatePicker, Icon, Modal, notification, Row, Select, Tooltip, Button } from 'antd'
import axios from 'axios'
import moment from 'moment'
import cx from 'classnames'
import $ from 'jquery'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import Input from '../Input'
import Loader from '../Loader'
import s from './style.module.scss'

function stringtodate(date) {
  const nd = date.split('-').join('/')
  return new Date(nd)
}

function monthDiff(dateFrom, dateTo) {
  return dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear())
}

const FamilyNameField = ({ initialValue, guardianId }) => {
  const [familyName, setFamilyName] = useState(initialValue)
  const [editing, setEditing] = useState(false)

  const save = () => {
    axios
      .post(
        `/update_family_name/${guardianId}`,
        { family_name: familyName },
        {
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(() => setEditing(false))
      .catch(function (err) {
        notification.open({
          message: 'There was an error changing your name. Please refresh the page and try again.',
        })
      })
  }

  return (
    <div className={s.familyNameField}>
      <div className={s.familyNameInput}>
        {editing ? (
          <Input value={familyName} onChange={(key, val) => setFamilyName(val)} placeholder="Family Name" />
        ) : (
          familyName
        )}
      </div>{' '}
      Family
      {editing ? (
        <Button className={s.familyNameToggle} type={'primary'} shape="circle" onClick={() => save()} icon={'check'} />
      ) : (
        <Tooltip title="Click to change your family name">
          <Button
            className={s.familyNameToggle}
            type={'default'}
            shape="circle"
            onClick={() => setEditing(true)}
            icon={'edit'}
          />
        </Tooltip>
      )}
    </div>
  )
}
class SingleFamilyMember extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      relationship: '',
      relationshipOther: false,
      firstName: '',
      lastName: props.familyName,
      dateOfBirth: false,
      actraPresent: false,
      agency: false,
      agencyName: '',
    }
  }

  addFamily() {
    this.props.addFamilyDetail(this.state)
  }

  handleCancel() {
    this.props.cancel()
  }

  render() {
    return (
      <Modal title={null} visible={true} onCancel={() => this.handleCancel()} footer={null}>
        <div className="singleFamily">
          <div className={cx(s.formTitle)}>Relationship</div>
          <Select
            onSelect={(value) => {
              this.setState({ relationship: value })
            }}
            style={{ width: '100%' }}
            value={this.state.relationship}
            className={s.formField}
            placeholder="Relationship"
          >
            <Select.Option value={'mother'}>Mother</Select.Option>
            <Select.Option value={'father'}>Father</Select.Option>
            <Select.Option value={'son'}>Son</Select.Option>
            <Select.Option value={'daughter'}>Daughter</Select.Option>
            <Select.Option value={'grandmother'}>Grandmother</Select.Option>
            <Select.Option value={'grandfather'}>Grandfather</Select.Option>
            <Select.Option value={'cat'}>Cat</Select.Option>
            <Select.Option value={'dog'}>Dog</Select.Option>
          </Select>
          <div className={cx(s.formTitle)}>First Name</div>
          <Input
            ref="firstName"
            value={this.state.firstName}
            stateKey="firstName"
            required={true}
            className={s.formField}
            onChange={(key, val) => this.setState({ firstName: val })}
            placeholder="First Name"
          />
          <div className={cx(s.formTitle)}>Last Name</div>
          <Input
            ref="lastName"
            value={this.state.lastName}
            stateKey="lastName"
            required={true}
            className={s.formField}
            onChange={(key, val) => this.setState({ lastName: val })}
            placeholder="Last Name"
          />
          <div className={cx(s.formTitle)}>Date of Birth</div>
          <DatePicker
            placeholder="Date of Birth"
            className={s.formField}
            onChange={(date, dateS) => {
              this.setState({ dateOfBirth: dateS })
            }}
          />

          <div className={cx(s.formTitle)}>Actra</div>
          <Checkbox
            ref="primary"
            checked={this.state.actraPresent}
            className={s.formField}
            onChange={(val) => {
              this.setState({ actraPresent: !this.state.actraPresent })
            }}
          >
            Is this family member part of Actra?
          </Checkbox>
          <div className={cx(s.formTitle)}>Representation</div>
          <Checkbox
            ref="primary"
            checked={this.state.agency}
            className={s.formField}
            onChange={(val) => {
              this.setState({ agency: !this.state.agency })
            }}
          >
            Is this family member represented by another Agency
          </Checkbox>
          {this.state.agency && (
            <div>
              <h5>Agency Name</h5>
              <Input
                ref="firstName"
                value={this.state.agencyName}
                stateKey="agencyName"
                required={true}
                className={s.formField}
                onChange={(key, val) => this.setState({ agencyName: val })}
                placeholder="Agency Name"
              />
            </div>
          )}
          <a
            onClick={(e) => {
              e.preventDefault()
              this.addFamily(this.state)
            }}
            className="button addFamily"
          >
            Add This Family Member
          </a>
        </div>
      </Modal>
    )
  }
}

class FamilySection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasFamily: !!this.props.guardian.familyimage,
      photo: !!this.props.guardian.familyimage ? this.props.guardian.familyimage : false,
      addDetail: false,
      singleParent: false,
      sameSex: !!this.props.guardian.samesexfamily ? !!this.props.guardian.samesexfamily : false,
      familyDetails: [],
      familyProfiles: [],
      isLoading: false,
    }
    this.getFamilyMembers = this.getFamilyMembers.bind(this)
  }
  componentDidMount() {
    this.getFamilyMembers()
  }

  toggleSameSex() {
    this.setState({ sameSex: !this.state.sameSex })
  }

  deleteFamilyMember(id) {
    let csrf = document.querySelector('meta[name="csrf-token"]')
    const config = {
      headers: {
        'X-CSRF-Token': csrf.content,
        'Content-Type': 'application/json',
      },
    }
    const _this = this
    axios.delete(`/familydetails/${id}.json`, config).then(function () {
      console.log('delete worked')
      let _familyMembers = _.cloneDeep(_this.state.familyDetails).filter((f) => f.id !== id)
      _this
        .setState({
          familyDetails: _familyMembers,
        })
        .catch((e) => {
          _this.getFamilyMembers()
        })
    })
  }

  getFamilyMembers() {
    const _this = this
    axios.get(`/familydetailsbyparent/${this.props.guardian.id}`).then(function (resp) {
      _this.setState({
        familyDetails: resp.data.details,
        familyProfiles: resp.data.profiles,
      })
    })
  }

  onFamilyDrop(files) {
    this.setState({ isLoading: true })
    let csrf = document.querySelector('meta[name="csrf-token"]')
    let _state = _.cloneDeep(this.state)
    let formData = new FormData()
    formData.append('image_upload[items]', files[0])
    formData.append('image_upload[parent_uuid]', this.props.uuid)
    formData.append('image_upload[upload_type]', 'family')
    this.setState({ familyimageLoading: true })
    $.ajax({
      url: `${this.props.paths.imageUpload}.json`,
      method: 'POST',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', csrf.content)
      },
      contentType: false,
      processData: false,
      data: formData,
      xhr: () => {
        var xhr = new window.XMLHttpRequest()
        var percentComplete = 0
        xhr.upload.addEventListener(
          'progress',
          function (evt) {
            if (evt.lengthComputable) {
              percentComplete = evt.loaded / evt.total
              percentComplete = parseInt(percentComplete * 100)
              console.log(percentComplete)
            }
          },
          false
        )
        return xhr
      },
      error: (err) => {
        _state.familyimageLoading = false
        this.setState(_state)
        notification.open({ message: 'Upload Failed.' })
        throw new Error(err.statusText)
      },
      success: (responsedata) => {
        if (responsedata.id && !!responsedata.items && !!responsedata.items.url) {
          // associate family image url to current user
          let assocformData = new FormData()
          assocformData.append('image_url', responsedata.items.url)
          $.ajax({
            url: `/uploadfamilyimage/${this.props.guardian.id}`,
            method: 'POST',
            beforeSend: function (xhr) {
              xhr.setRequestHeader('X-CSRF-TOKEN', csrf.content)
            },
            processData: false,
            contentType: false,
            data: assocformData,
            error: (err) => {
              notification.open({
                message: 'Upload Failed, please try again',
              })
              throw new Error(err.statusText)
            },
            success: (familyresponseassociation) => {
              let _state = _.cloneDeep(this.state)
              _state.photo = familyresponseassociation.familyimage
              _state.familyimageLoading = false
              _state.isLoading = false
              this.setState(_state)
              notification.open({
                message: 'Upload Success',
                // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
              })
            },
          })
        } else {
          _state.familyimageLoading = false
          this.setState(_state)
          this.displayUploadError(responsedata.items)
        }
      },
    })
  }

  displayUploadError(arr, string) {
    let content
    if (arr) {
      content = arr.map((item, index) => {
        return <div key={index}>{item}</div>
      })
    } else if (string) {
      content = string
    } else {
      content = ''
    }
    Modal.info({
      title: 'Error',
      okText: 'Okay',
      content: content,
    })
  }

  addFamilyDetail(detail) {
    let csrf = document.querySelector('meta[name="csrf-token"]')
    const config = {
      headers: {
        'X-CSRF-Token': csrf.content,
        'Content-Type': 'application/json',
      },
    }
    const body = {
      familydetail: {
        relationship: detail.relationship,
        relationshipOther: detail.relationshipOther,
        firstName: detail.firstName,
        lastName: detail.lastName,
        dateOfBirth: stringtodate(detail.dateOfBirth),
        actraPresent: detail.actraPresent,
        agency: detail.agency,
        agencyName: detail.agencyName,
        user_id: this.props.guardian.id,
      },
    }
    let _this = this
    axios
      .post(`/familydetails.json`, body, config)
      .then(function (resp) {
        let details = _.cloneDeep(_this.state.familyDetails)
        details.push(resp.data)
        _this.setState({
          familyDetails: details,
          addDetail: false,
        })
        notification.open({
          message: 'Added Family memeber',
        })
      })
      .catch(function (err) {
        notification.open({
          message: 'There was an error adding this family member.',
        })
      })
  }

  handleCancelDetail() {
    this.setState({
      addDetail: false,
    })
  }

  removeFamilyPhoto() {
    axios
      .delete(`/delete_family_image/${this.props.guardian.id}`, {
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Content-Type': 'application/json',
        },
      })
      .then(() => this.setState({ photo: null }))
      .catch(function (err) {
        notification.open({
          message: 'There was an error removing the photo. Please refresh the page and try again.',
        })
      })
  }

  render() {
    var today = new Date()
    if (!!this.props.guardian) {
      return (
        <div className={s.root}>
          <br />
          <br />
          <div className={cx(s.formTitle)}>
            Family Photo:{' '}
            <FamilyNameField initialValue={this.props.guardian.family_name} guardianId={this.props.guardian.id} />
          </div>
          <div className="disclaimer">
            <p>
              Clients often request ‘real families’ for castings. This is so they can capture an authentic family feel.
              If you would like to submit your family for these opportunities, please submit a photo of your entire
              family.{' '}
            </p>
            <br />
            <p>
              Please ensure the photo is a nice clear image of all your family members. This can even include
              grandparents and family pets, if you choose. Please have no filters, hats or sunglasses in the photo.
              Everyone must be fully dressed, and completely in frame from the hip up. Please do not submit photos of
              just your families faces. Lighting is important, so natural light is encouraged.
            </p>
            <br />
            <p>
              If you choose to submit a family photo, it will not be visible on the front end of the website, and will
              only be used to submit directly to clients. By attaching a photo, you are acknowledging that you would
              like to be submitted for these types of castings.
            </p>
            <br />
            <p>
              You will also be asked for the name, age and their relationship to the child. It also will ask for each
              members agency, if they are already represented elsewhere.
              <br />
              <br />
            </p>
            <Checkbox
              ref="primary"
              checked={this.state.hasFamily}
              onChange={(val) => {
                this.setState({ hasFamily: !this.state.hasFamily })
              }}
            >
              Do you have a family photo? <br />
              <br />
            </Checkbox>
          </div>
          {this.state.hasFamily && (
            <div className="sameSex">
              <Checkbox
                ref="samesex"
                checked={this.state.sameSex}
                onChange={(val) => {
                  this.toggleSameSex()
                }}
              >
                Same sex couple <br />
                <br />
              </Checkbox>
              <div className={cx(s.formTitle)}>Family Photo</div>
            </div>
          )}
          {this.state.hasFamily && (
            <>
              {this.state.isLoading ? (
                <Loader />
              ) : (
                !!this.state.photo && (
                  <div className={s.familyPhotoContainer}>
                    <img
                      style={{
                        width: '500px',
                        height: '300px',
                        maxWidth: '100%',
                        objectFit: 'contain',
                        backgroundColor: '#F2F0EC',
                      }}
                      src={this.state.photo}
                    />
                    <Button
                      className={s.deletePhotoBtn}
                      type="danger"
                      icon="close"
                      shape="circle"
                      onClick={() => this.removeFamilyPhoto()}
                    />
                  </div>
                )
              )}
            </>
          )}
          {this.state.hasFamily && (
            <div className="familyUploader">
              <Dropzone className={s.dropZone} onDrop={this.onFamilyDrop.bind(this)} ref="dropZone-audio">
                <p className={s.dropZoneText}>
                  Drag file here or <span className={s.dropZoneBrowse}>browse</span>
                  {this.state.photo && ' to replace'}
                </p>
              </Dropzone>
              <br />
              <br />
              <a
                onClick={(e) => {
                  e.preventDefault()
                  this.setState({ addDetail: true })
                }}
                className="addDetail button inline"
              >
                ADD FAMILY MEMBER
              </a>
            </div>
          )}
          <br />
          {this.state.hasFamily && (
            <div className={s.familyTable} style={{ width: '100%' }}>
              {this.state.familyDetails.map((fam) => {
                let dob = new Date(fam.dateOfBirth)
                let ageInMonths = monthDiff(dob, today)
                let age
                if (ageInMonths >= 36) {
                  age = Math.floor(ageInMonths / 12) + ' Years Old'
                } else {
                  age = ageInMonths + ' Months Old'
                }
                return (
                  <Row gutter={16} key={fam.id} className={s.singleFamily}>
                    <Col span={24} md={3}>
                      <p className={s.famlabel}>Relationship</p>
                      <div className={s.famField}>{fam.relationship.toUpperCase()}</div>
                    </Col>
                    <Col span={24} md={5}>
                      <p className={s.famlabel}>Name</p>
                      <div className={s.famField}>
                        {fam.firstName} {fam.lastName}
                      </div>
                    </Col>
                    <Col span={24} md={this.props.isAdmin ? 4 : 7}>
                      <p className={s.famlabel}>Age</p>
                      <div className={s.famField}>{age}</div>
                    </Col>
                    {this.props.isAdmin && (
                      <Col span={24} md={3}>
                        <p className={s.famlabel}>Birthdate</p>
                        <div className={s.famField}>{moment(dob).format('MM/DD/YYYY')}</div>
                      </Col>
                    )}
                    <Col span={24} md={2}>
                      <p className={s.famlabel}>Actra?</p>
                      <div className={s.famField}>{!!fam.actraPresent ? 'Yes' : '-'}</div>
                    </Col>
                    <Col span={24} md={5}>
                      <p className={s.famlabel}>Agency</p>
                      <div className={s.famField}>{!!fam.agencyName ? fam.agencyName : '-'}</div>
                    </Col>
                    <Col span={24} md={2}>
                      <p className={s.famlabel}>&nbsp; &nbsp;</p>
                      <div
                        style={{ padding: '0px', marginBottom: '27px', cursor: 'pointer' }}
                        onClick={(e) => {
                          e.preventDefault()
                          if (window.confirm(`Are you sure you want to delete ${fam.firstName} family member?`)) {
                            this.deleteFamilyMember(fam.id)
                          }
                        }}
                      >
                        <Tooltip title="Click to remove">
                          <Icon
                            onClick={() => this.removeThisField('experience', index)}
                            className={s.remove}
                            type="close"
                          />
                        </Tooltip>
                      </div>
                    </Col>
                  </Row>
                )
              })}

              {this.state.familyProfiles.map((prof) => {
                let dob = new Date(prof.date_of_birth)
                let ageInMonths = monthDiff(dob, today)
                let age
                if (ageInMonths >= 36) {
                  age = Math.floor(ageInMonths / 12) + ' Years Old'
                } else {
                  age = ageInMonths + ' Months Old'
                }
                return (
                  <Row gutter={16} key={prof.id} className={s.singleFamily}>
                    <Col span={24} md={3}>
                      <p className={s.famlabel}>Relationship</p>
                      <div className={s.famField}>{prof.gender == 'Female' ? 'DAUGHTER' : 'SON'}</div>
                    </Col>
                    <Col span={24} md={5}>
                      <p className={s.famlabel}>Name</p>
                      <div className={s.famField}>
                        {prof.first_name} {prof.last_name}
                      </div>
                    </Col>
                    <Col span={24} md={this.props.isAdmin ? 4 : 7}>
                      <p className={s.famlabel}>Age</p>
                      <div className={s.famField}>{age}</div>
                    </Col>
                    {this.props.isAdmin && (
                      <Col span={24} md={3}>
                        <p className={s.famlabel}>Birthdate</p>
                        <div className={s.famField}>{moment(dob).format('MM/DD/YYYY')}</div>
                      </Col>
                    )}
                    <Col span={24} md={2}>
                      <p className={s.famlabel}>Actra?</p>
                      <div className={s.famField}>{!!prof.actra_present ? 'Yes' : '-'}</div>
                    </Col>
                    <Col span={24} md={5}>
                      <p className={s.famlabel}>Agency</p>
                      <div className={s.famField}>Minor Details ({prof.approved})</div>
                    </Col>
                    <Col span={24} md={2}>
                      <p className={s.famlabel}>&nbsp; &nbsp;</p>
                      <div style={{ padding: '0px', marginBottom: '27px', cursor: 'pointer' }}>
                        <Tooltip title={`This profile's status has been marked "${prof.approved}" by Minor Details.`}>
                          <div
                            className={
                              prof.approved === 'approved'
                                ? s.approval
                                : prof.approved === 'denied'
                                ? s.denied
                                : s.pending
                            }
                          >
                            *
                          </div>
                        </Tooltip>
                      </div>
                    </Col>
                  </Row>
                )
              })}
            </div>
          )}
          <div>
            {!!this.state.addDetail && (
              <SingleFamilyMember
                familyName={this.props.guardian.family_name}
                cancel={this.handleCancelDetail.bind(this)}
                addFamilyDetail={this.addFamilyDetail.bind(this)}
              />
            )}
          </div>
        </div>
      )
    } else {
      return false
    }
  }
}

FamilySection.defaultProps = {
  notice: 'Noticeing default notice',
}

FamilySection.propTypes = {
  notice: PropTypes.string,
}

export default FamilySection
