import React from 'react'
import { ContextWrapper } from '../ContextWrapper'
import Profile from './../components/Profile'

const ChildProfile = (props) => {
  return (
    <ContextWrapper>
      <div>
        <Profile {...props} />
      </div>
    </ContextWrapper>
  )
}

export default ChildProfile
