import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { GoogleMap, Marker, Rectangle, withGoogleMap } from 'react-google-maps'
import Separator from './../Separator'
import mapStyle from './mapstyle.json'
import marker from './marker.png'
import s from './style.module.scss'
let bounds = new google.maps.LatLngBounds(
  new google.maps.LatLng(-84.999999, -179.999999),
  new google.maps.LatLng(84.999999, 179.999999)
)
const GettingStartedGoogleMap = withGoogleMap((props) => (
  <GoogleMap
    ref={props.onMapLoad}
    defaultZoom={15}
    defaultCenter={{ lat: props.lat, lng: props.lang }}
    onClick={props.onMapClick}
    defaultOptions={{
      styles: mapStyle,
      draggable: true,
      scrollwheel: true,
      streetViewControl: false,
      mapTypeControl: false,
      zoomControl: true,
      zoom: 4,
    }}
  >
    <Rectangle
      clickable={false}
      draggable={false}
      editable={false}
      options={{
        fillColor: `#b9efe1`,
        fillOpacity: 0.35,
        strokeColor: `#b9efe1`,
        strokeOpacity: 1,
        strokeWeight: 1,
      }}
      onBoundsChanged={() => {
        console.log('Bounds changed')
      }}
      bounds={bounds}
    />
    <Marker
      onClick={() => console.log('clickd!')}
      icon={{
        url: marker, // pass your image here
        // anchor: iconAnchor
      }}
      position={{
        lat: props.lat,
        lng: props.lang,
      }}
    />
  </GoogleMap>
))

class MapItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rerender: false,
    }
    this.handleMapLoad = this.handleMapLoad.bind(this)
    this.handleMapClick = this.handleMapClick.bind(this)
    this.handleMarkerRightClick = this.handleMarkerRightClick.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.rerender = this.rerender.bind(this)
    window.addEventListener('resize', this.handleResize, true)
  }

  handleMapLoad(map) {
    this._mapComponent = map
    if (map) {
      console.log('there is a map')
    }
  }

  handleMapClick(event) {
    console.log('map click')
  }

  handleResize() {
    this.setState({ rerender: false }, this.rerender())
  }

  rerender() {
    setTimeout(() => {
      this.setState({ rerender: true })
    }, 800)
  }

  handleMarkerRightClick(targetMarker) {
    console.log('marker right click')
  }
  componentDidMount() {
    this.setState({ rerender: true })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    const { address, city, province, country, postalCode, phone, email, mapLatitude, mapLongitude } = this.props
    return (
      <div>
        <div className="hide-on-desktop">
          <div className={cx(s.mobileContainer, 'container')}>
            <div className={cx(s.addressDisplay)}>
              <div className={s.addressContent}>
                <h4 className={s.addressTitle}>Address</h4>
                <a href={`http://maps.google.com/?q=${address}, ${city}, ${province}`} target="_blank">
                  {address} <br />
                  {city}, {province}, {postalCode}
                </a>
                <br />
                <em>By Appointment Only</em>
              </div>
              {this.props.businessHours && (
                <div>
                  <Separator />
                  <div className={s.addressContent}>
                    <h4 className={s.addressTitle}>Bussiness Hours</h4>
                    {this.props.businessHours}
                  </div>
                </div>
              )}
              <Separator />
              <div className={s.addressContent}>
                <h4 className={s.addressTitle}>Phone</h4>
                <a href={`tel:${phone}`}>{phone}</a>
              </div>
              <Separator />
              <div className={s.addressContent}>
                <h4 className={s.addressTitle}>Email</h4>
                {email &&
                  email.split(',').map((emailItem, index) => {
                    return (
                      <div key={`emailItem-${index}`}>
                        {index == 0 ? '' : 'or'}
                        {index == 0 ? '' : <br />}
                        <a href={`mailto:${emailItem.trim()}`}>{emailItem.trim()}</a>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>

        <div className={s.root}>
          <div className={cx(s.container, 'container')}>
            <div className={cx(s.addressDisplay, 'hide-on-mobile')}>
              <div className={s.addressContent}>
                <h4 className={s.addressTitle}>Address</h4>
                <a href={`http://maps.google.com/?q=${address}, ${city}, ${province}`} target="_blank">
                  {address} <br />
                  {city}, {province}, {postalCode}
                </a>
                <br />
                <em>By Appointment Only</em>
              </div>
              {this.props.businessHours && (
                <div>
                  <Separator />
                  <div className={s.addressContent}>
                    <h4 className={s.addressTitle}>Business Hours</h4>
                    {this.props.businessHours}
                  </div>
                </div>
              )}
              <Separator />
              <div className={s.addressContent}>
                <h4 className={s.addressTitle}>Phone</h4>
                <a href={`tel:${phone}`}>{phone}</a>
              </div>
              <Separator />
              <div className={s.addressContent}>
                <h4 className={s.addressTitle}>Email</h4>
                {email &&
                  email.split(',').map((emailItem, index) => {
                    return (
                      <div style={{ display: 'inline' }} key={`emailItem-${index}`}>
                        {index == 0 ? '' : ' or '}
                        <a href={`mailto:${emailItem.trim()}`}>{emailItem.trim()}</a>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>

          {this.state.rerender && (
            <GettingStartedGoogleMap
              containerElement={<div style={{ height: `600px`, transform: `translateY(-100%)` }} />}
              mapElement={<div style={{ height: `100%`, position: 'inherit' }} />}
              onMapLoad={this.handleMapLoad}
              lat={Number(mapLatitude)}
              lang={Number(mapLongitude)}
              onMapClick={this.handleMapClick}
            />
          )}
          {/* <div className={s.mapOverlay}>
          <div className={s.midMarker} onClick={() => {}}>
          <Logo />
        </div>
      </div> */}
        </div>
      </div>
    )
  }
}

// MapItem.defaultProps = {
//   title: 'Feature Image'
// }

MapItem.propTypes = {
  address: PropTypes.string,
  city: PropTypes.string,
  province: PropTypes.string,
  country: PropTypes.string,
  postalCode: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  mapLatitude: PropTypes.string,
  mapLongitude: PropTypes.string,
}

export default MapItem
