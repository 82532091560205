import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import PersonCard from './../PersonCard'
import s from './style.module.scss'

class ThreePersonCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div className={s.root}>
        <div className="container">
          <Row>
            <Col span={24} sm={8}>
              <PersonCard
                narrow={true}
                name={this.props.name1}
                title={this.props.title1}
                image={this.props.image1}
                text={this.props.text1}
              />
            </Col>
            <Col span={24} sm={8}>
              <PersonCard
                narrow={true}
                name={this.props.name2}
                title={this.props.title2}
                image={this.props.image2}
                text={this.props.text2}
              />
            </Col>
            <Col span={24} sm={8}>
              <PersonCard
                narrow={true}
                name={this.props.name3}
                title={this.props.title3}
                image={this.props.image3}
                text={this.props.text3}
              />
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

ThreePersonCard.defaultProps = {
  title: 'ThreePersonCardiing default title',
}

ThreePersonCard.propTypes = {
  title: PropTypes.string,
}

export default ThreePersonCard
