import { Col, Row } from 'antd'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import s from './style.module.scss'

const DisplayCard = ({ title, img, text, btn, bgImage }) => {
  const buttonstyle = btn.colour === 'green' ? 'button' : 'button secondary'
  const cardBackgroundStyle = btn.colour === 'green' ? s.yellowBack : s.greenBack
  return (
    <div className={cx(s.displayCard, cardBackgroundStyle)}>
      {img && <img src={img[0].url} alt="display card icon" />}
      {bgImage && <img className={s.bgImg} src={bgImage[0].url} />}
      {title && <div className={s.displayCardTitle} dangerouslySetInnerHTML={{ __html: title }} />}
      {text && <div className={s.displayCardText} dangerouslySetInnerHTML={{ __html: text }} />}
      {btn && (
        <a
          href={btn.link}
          target={`${btn.newTab == 'true' ? '_blank' : ''}`}
          className={cx([buttonstyle, s.displayCardBtn])}
        >
          {btn.text}
        </a>
      )}
    </div>
  )
}

class CardDisplay extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      card1BgImage,
      card2BgImage,
      card3BgImage,
      title,
      titleSize,
      subtitle,
      card1Title,
      card1Img,
      card1Text,
      card1BtnTxt,
      card1BtnColour,
      card1BtnLink,
      card2Title,
      card2Img,
      card2Text,
      card2BtnTxt,
      card2BtnColour,
      card2BtnLink,
      card3Title,
      card3Img,
      card3Text,
      card3BtnTxt,
      card3BtnColour,
      card1BtnOpenInNewTab,
      card2BtnOpenInNewTab,
      card3BtnOpenInNewTab,
      card3BtnLink,
    } = this.props

    return (
      <div className={cx(s.root)}>
        <div className="container">
          <Row type="flex" justify="center">
            <Col xs={24} md={10}>
              <div className={s.cardDisplayTitleContainer}>
                <this.props.titleSize>{title}</this.props.titleSize>
              </div>
            </Col>
            <Col xs={24}>
              <div className={s.cardDisplayParagraph}>{subtitle}</div>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            {card1Title && (
              <DisplayCard
                img={card1Img}
                title={card1Title}
                text={card1Text}
                btn={{ link: card1BtnLink, text: card1BtnTxt, colour: card1BtnColour, newTab: card1BtnOpenInNewTab }}
                bgImage={card1BgImage}
              />
            )}
            {card2Title && (
              <DisplayCard
                img={card2Img}
                title={card2Title}
                text={card2Text}
                btn={{ link: card2BtnLink, text: card2BtnTxt, colour: card2BtnColour, newTab: card2BtnOpenInNewTab }}
                bgImage={card2BgImage}
              />
            )}
            {card3Title && (
              <DisplayCard
                img={card3Img}
                title={card3Title}
                text={card3Text}
                btn={{ link: card3BtnLink, text: card3BtnTxt, colour: card3BtnColour, newTab: card3BtnOpenInNewTab }}
                bgImage={card3BgImage}
              />
            )}
          </Row>
        </div>
      </div>
    )
  }
}

CardDisplay.propTypes = {
  titleSize: PropTypes.string,
  card1Title: PropTypes.string,
  card1Img: PropTypes.array,
  card1Text: PropTypes.string,
  card1BtnTxt: PropTypes.string,
  card1BtnColour: PropTypes.string,
  card1BtnLink: PropTypes.string,
  card2Title: PropTypes.string,
  card2Img: PropTypes.array,
  card2Text: PropTypes.string,
  card2BtnTxt: PropTypes.string,
  card2BtnColour: PropTypes.string,
  card2BtnLink: PropTypes.string,
  card3Title: PropTypes.string,
  card3Img: PropTypes.array,
  card3Text: PropTypes.string,
  card3BtnTxt: PropTypes.string,
  card3BtnColour: PropTypes.string,
  card3BtnLink: PropTypes.string,
}

export default CardDisplay
