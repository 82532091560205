import PropTypes from 'prop-types'
import React from 'react'
import ReactQuill from 'react-quill'
import s from './style.module.scss'

class RichText extends React.Component {
  constructor(props) {
    super(props)
    this.state = { text: '' }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    console.log(value)
    this.props.handleInputChange &&
      this.props.handleInputChange(value, this.props.updatekey, this.props.componentName, this.props.position)
    // this.setState({ text: value })
  }

  render() {
    return (
      <div className={s.root}>
        <ReactQuill value={this.props.value} onChange={this.handleChange} theme="snow" />
      </div>
    )
  }
}

RichText.defaultProps = {
  title: 'RichTextiing default title',
}

RichText.propTypes = {
  title: PropTypes.string,
}

export default RichText
