import { Tooltip } from 'antd'
import cx from 'classnames'
import _ from 'lodash'
import React from 'react'
import __ from './../Common'
import actra from './actra.png'
import specialNeeds from './special-needs.png'
import s from './style.module.scss'

function checkBookout(bookout) {
  var now = new Date()
  var start = new Date(bookout.date_start)
  var end = new Date(bookout.date_end)
  var nextweek = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7)
  if (now > end) {
    return false
  }
  if (now >= start && now <= end) {
    return {
      bookedOutCurrently: true,
      bookOutUpcoming: false,
      start: start,
      end: end,
    }
  }
  if (nextweek >= start) {
    return {
      bookedOutCurrently: false,
      bookOutUpcoming: true,
      start: start,
      end: end,
    }
  }
}

class ResultCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      current: [],
      profileInSubmissions: false,
    }
    this.removeProfile = this.removeProfile.bind(this)
  }
  addToSubmission(profile) {
    let current = JSON.parse(sessionStorage.getItem('minor_details_profiles'))
    if (current == null || current == undefined) {
      // SET THE SESSION STORAGE IF EMPTY
      sessionStorage.setItem('minor_details_profiles', JSON.stringify([profile]))
      this.setState({ profileInSubmissions: true })
    } else {
      // APPEND THE NEW PROFILE IF IT IS IN THE SESSION
      this.isInProfile = current.map((p) => {
        return p.id === profile.id
      })

      if (this.isInProfile.indexOf(true) > -1) {
        alert('This profile is already in your submissions')
      } else {
        console.log('adding profile')
        current.push(profile)
        // RESET THE SESSION STORAGE
        sessionStorage.removeItem('minor_details_profiles')
        sessionStorage.setItem('minor_details_profiles', JSON.stringify(current))
        this.setState({ profileInSubmissions: true })
      }
    }
    events.publish('profile_added', { detail: current })
  }
  removeFromSubmissions(profile) {
    let current = JSON.parse(sessionStorage.getItem('minor_details_profiles'))
    let badIndexes = []
    for (let index = 0; index < current.length; index++) {
      const p = current[index]
      if (p.id === profile.id) {
        badIndexes.push(index)
      }
    }
    for (let index = 0; index < badIndexes.length; index++) {
      const i = badIndexes[index]
      current.splice(i, 1)
    }

    sessionStorage.removeItem('minor_details_profiles')
    sessionStorage.setItem('minor_details_profiles', JSON.stringify(current))
    this.setState({ profileInSubmissions: false }, () => {
      events.publish('profile_added', { detail: current })
      events.publish('profile_removed', { detail: profile.id })
    })
  }
  linkToProfile(id) {
    window.location = `/profiles/${id}`
  }

  isProfileInSubmissions() {
    const current = JSON.parse(sessionStorage.getItem('minor_details_profiles'))
    if (current) {
      this.isInProfile = current.map((p) => {
        return p.id === this.props.profile.id
      })
    } else {
      this.isInProfile = [false]
    }
    return this.isInProfile.indexOf(true) > -1
  }

  removeProfile(e) {
    if (e.detail == this.props.profile.id) {
      this.setState({
        profileInSubmissions: false,
      })
    }
    // console.log('remove profile > e: ', e)
  }

  componentDidMount() {
    this.isProfileInSubmissions()
      ? this.setState({ profileInSubmissions: true })
      : this.setState({ profileInSubmissions: false })
    window.subscriptions[`${this.props.profile.id}`] = events.subscribe('profile_removed', (obj) => {
      this.removeProfile(obj)
    })
    window.subscriptions[`${this.props.profile.id}`] = events.subscribe('cart_cleared', () => {
      this.setState({
        profileInSubmissions: false,
      })
    })
  }
  componentWillUnmount() {
    if (window.subscriptions[`${this.props.profile.id}`]) {
      window.subscriptions[`${this.props.profile.id}`].remove()
    }
  }
  render() {
    const { profile, size, hover } = this.props
    const {
      first_name,
      last_name,
      images,
      actra_apprentice_number,
      nick_name,
      primary,
      actra_apprentice_full_number,
      special_needs,
    } = profile
    const image = images && __.parseIfString(images).length > 0 ? __.parseIfString(images)[0].url : false
    const name = nick_name && primary === '1' ? nick_name : first_name
    let bookouts =
      !!profile.bookouts && profile.bookouts.length > 0
        ? profile.bookouts.map((b) => checkBookout(b)).filter(Boolean)
        : false
    bookouts = bookouts && bookouts.length === 0 ? false : bookouts
    let hasCurrentBookout = bookouts && bookouts.filter((b) => b.bookedOutCurrently).length > 0
    let hasUpcomingBookout = bookouts && bookouts.filter((b) => b.bookOutUpcoming).length > 0
    let dob = new Date(profile.date_of_birth)
    const card = (
      <div className={cx(s.root, s[size])}>
        {bookouts && (
          <div className={s.bookoutSummary}>
            {hasCurrentBookout && 'BOOKED OUT'} {!hasCurrentBookout && hasUpcomingBookout && 'BOOKOUT UPCOMING'}{' '}
            {bookouts &&
              bookouts.map((b) => (
                <div className={s.singlebookout}>
                  {b.start.toLocaleDateString()} - {b.end.toLocaleDateString()}
                </div>
              ))}
          </div>
        )}
        <div onClick={(e) => this.linkToProfile(profile.id)} className={cx(s.imageHolder, s[size])}>
          {image && <img className={s.image} src={image} alt="" />}
          {special_needs && _.isEmpty(__.parseIfString(special_needs)) == false && (
            <img className={s.specialNeeds} src={specialNeeds} alt="" />
          )}
          {image && (actra_apprentice_number.length > 0 || actra_apprentice_full_number.length > 0) && (
            <img src={actra} className={s.actra} alt="" />
          )}
          {hover && (
            <div className={s.cardData}>
              <ul>
                <li>Age: {__.displayFullAge(profile.date_of_birth)}</li>
                <li>
                  Height: {profile.stats.height.ft} ft. {profile.stats.height.inches} in.
                </li>
                {profile.stats.weight && __.ageUnderThree(dob.getFullYear(), dob.getMonth() + 1, dob.getDate()) && (
                  <li>Weight: {profile.stats.weight} lbs.</li>
                )}
                {profile.stats.size && <li>Size: {profile.stats.size}</li>}
              </ul>
            </div>
          )}
        </div>
        <div className={s.nameAndAction}>
          <div className={s.nameHolder}>
            <div onClick={(e) => this.linkToProfile(profile.id)} className={s.name}>
              {name} {last_name && `${last_name.charAt(0)}.`}
            </div>
          </div>
          <Tooltip title={`${this.isProfileInSubmissions() ? 'Remove' : 'Add'} ${first_name} to your submissions`}>
            <div
              onClick={(e) =>
                this.isProfileInSubmissions() ? this.removeFromSubmissions(profile) : this.addToSubmission(profile)
              }
              className={cx(s.addToSubmission, s.submissionCard)}
            >
              {`${this.isProfileInSubmissions() ? '-' : '+'}`}
            </div>
          </Tooltip>
        </div>
      </div>
    )
    if (this.props.button === true) {
      return (
        <Tooltip title={`${this.isProfileInSubmissions() ? 'Remove' : 'Add'} ${first_name} to your submissions`}>
          <div
            onClick={(e) =>
              this.isProfileInSubmissions() || this.props.explicitRemove
                ? this.removeFromSubmissions(profile)
                : this.addToSubmission(profile)
            }
          >
            {this.isProfileInSubmissions() || this.props.explicitRemove ? (
              <div className={cx('button small', s.submissions)}>
                {this.props.removeText} <span className={s.plus}>-</span>
              </div>
            ) : (
              <div className={cx('button small', s.submissions)}>
                {this.props.addText} <span className={s.plus}>+</span>
              </div>
            )}
          </div>
        </Tooltip>
      )
    } else {
      return card
    }
  }
}

ResultCard.defaultProps = {}

ResultCard.propTypes = {}

export default ResultCard
