import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import _ from 'lodash'
import __ from './../Common/'
import 'react-table/react-table.css'

class DataTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id
    switch (filter.id) {
      case 'date_of_birth':
        let divText =
          row[id] && row[id].props && row[id].props.children && row[id].props.children[1]
            ? row[id].props.children[1]
            : false
        if (divText) {
          return String(divText.toLowerCase()).includes(filter.value.toLowerCase())
        } else {
          return true
        }
      case 'ethnicity':
      case 'family_origin':
        if (filter.value.includes(',')) {
          let filterArr = []
          let rowArr = row[id].split(',').map((item, index) => {
            return item.trim().toLowerCase()
          })
          filter.value.split(',').forEach((item, index) => {
            if (!_.isEmpty(item.trim())) {
              filterArr.push(item.trim().toLowerCase())
            }
          })
          return __.arrayContainsArray(rowArr, filterArr)
        } else {
          return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true
        }
      case 'notes':
        return row[id].props.children[0].props.value.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
      case 'connections':
        const searchItems = filter.value
          .split(',')
          .map((word) => word.trim())
          .filter(Boolean)
        const rowItems = row[id]?.map((id) => `${id}`).join(',') ?? ''
        return searchItems.some((item) => rowItems.includes(item))
      default:
        if (row[id] !== null)
          return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
    }
  }

  render() {
    return (
      <div>
        <ReactTable
          data={this.props.rows}
          columns={this.props.columns}
          filterable={this.props.filterable}
          defaultFilterMethod={this.filterCaseInsensitive}
          className="-striped -highlight"
          {...this.props}
        />
      </div>
    )
  }
}

DataTable.defaultProps = {}

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      accessor: PropTypes.string.isRequired,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
}

export default DataTable
