import { Col, Icon, notification, Row } from 'antd'
import axios from 'axios'
import cx from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import Input from '../Input'
import __ from './../Common'
import s from './style.module.scss'

class VideoForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      csrf: null,
      name: null,
      description: null,
      url_id: null,
      position: -1,
      submitting: false,
    }

    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.postBodyGenerator = this.postBodyGenerator.bind(this)
    this.setBaseState = this.setBaseState.bind(this)
  }

  componentDidMount() {
    this.setBaseState()
    this.setVideoFromStorage(this.props.video)
  }


  /************************
    UPDATE & API CALLS
  *************************/

  handleFormSubmit() {
    let submitting = true
    if (this.state.submitting) return // ALready submitting, prevent multiple submissions
    this.setState({
      submitting: true,
    })
    let body = this.postBodyGenerator()
    let meta = document.querySelector('meta[name="csrf-token"]')
    const config = {
      headers: {
        'X-CSRF-Token': meta.content,
        'Content-Type': 'application/json',
      },
    }
    let _this = this
    // determine whether to post to new video path or edit video path:
    const postPath = this.props.video ? `/admin_videos/${this.props.video.id}` : '/admin_videos'
    const method = this.props.video ? 'put' : 'post'
      axios[method](`${postPath}.json`, body, config)
        .then(
          function (response) {
            console.log('rails response', response)
            notification.open({
              message: 'Success',
              description: _this.props.newVideo ? 'Video created successfully' : 'Video saved successfully!',
            })
            const redirectPath = response.data.redirectPath
            if (typeof window !== 'undefined' && redirectPath) {
              window.location.replace(redirectPath)
            }
          },
          function (error) {
            _this.setState({ submitting: false })
            throw new Error(error.message)
          }
        )
        .catch(function (error) {
          submitting = false
          _this.setState({ submitting: false }, () => {
            notification.open({
              message: 'Error',
              description: 'Opps something went wrong. Please try again in a bit.',
              icon: <Icon type="warning" style={{ color: 'red' }} />,
            })
          })
          throw new Error(error.message)
        })
  }

  postBodyGenerator() {
    let s = this.state

    return {
      video: {
        name: s.name,
        description: s.description,
        url_id: s.url_id,
        position: s.position,
      },
    }
  }

  /************************
    STATE-SETTING FUNCTIONS
  *************************/


  setBaseState() {
    const meta = document.querySelector('meta[name="csrf-token"]')
    this.setState({
      csrf: meta.content,
      position: this.props.maxPosition,
    })
  }

  setInput(key, value) {
    let _state = _.cloneDeep(this.state)
    _.set(_state, key, value)
    this.setState(_state)
  }

  setVideoFromStorage(video) {
    if (video) {
      this.setState({
        name: video.name ? video.name : null,
        description: video.description ? video.description : null,
        url_id: video.url_id ? video.url_id : null,
        position: video.position ? video.position : this.props.maxPosition,
      })
    }
  }

  /****************
    RENDER METHODS
  ****************/

  render() {
    return (
      <div className={s.root}>
        <div className={cx('container', s.headerContainer)}>
          <Row type="flex" align="middle">
            <Col span={24} sm={16}>
              <Row type="flex" align="bottom">
                <Col span={24} sm={16}>
                  { this.props.newVideo ? 
                    <h1 className={s.registrationHeader}> New Video</h1> :
                    <h1 className={s.registrationHeader}> Edit Video </h1>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <p className={s.labelText}>
          Video Title
        </p>
        <Input 
          stateKey='name'
          value={this.state.name ? this.state.name : ''}
          onChange={this.setInput.bind(this)}
          placeholder='Video Title'
        />
        <p className={s.labelText}>
          Video Description
        </p>
        <Input
          stateKey='description'
          type='textarea'
          value={this.state.description ? this.state.description : ''}
          onChange={this.setInput.bind(this)}
          placeholder="Video Description"
        />
        <p className={s.labelText}>
          Video ID (From Vimeo)
        </p>
        <Input
          stateKey='url_id'
          value={this.state.url_id ? this.state.url_id : ''}
          onChange={this.setInput.bind(this)}
          placeholder="0123456789"
        />
        <p className={s.labelText}>
          Position in List (max {this.props.maxPosition})
        </p>
        <Input
          stateKey='position'
          value={this.state.position ? this.state.position : ''}
          validate="isNumeric"
          type="number"
          onChange={this.setInput.bind(this)}
        />

        <div className="container">
          <hr className={s.btnDivider} />
          <div className={cx(s.actionholders, 'cf')}>
            <Row type="flex" justify="space-between">
              
              <Col span={24} sm={4}>
                  <a
                    className={cx(s.button, 'button')}
                    disabled={false}
                    onClick={this.handleFormSubmit}
                    type="default"
                    icon="right"
                  >
                    submit
                  </a>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  }
}

VideoForm.propTypes = {
  position: PropTypes.number,
}

export default VideoForm
