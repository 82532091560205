import { Button, Checkbox, Col, Icon, Row, Select, Tooltip } from 'antd'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import Input from './../Input'
import s from './style.module.scss'
const Asterix = () => {
  return <div className={s.required}>*</div>
}

const MultiParentForm = (props) => {
  return (
    <div className={s.multiInputRowClass}>
      <Row gutter={16}>
        <Col span={24} md={12}>
          <div className={s.formTitle}>Parent's Name</div>
          <Row gutter={16}>
            <Col span={24} md={8}>
              <Asterix />
              <Input
                onChange={props.setInput}
                stateKey={`${props.arrayPoint}.firstName`}
                className={props.badFields.includes('otherParents[0].firstName') ? 'error-field' : ''}
                value={props.firstName}
                placeholder="First"
                required={true}
              />
            </Col>
            <Col span={24} md={8}>
              <Asterix />
              <Input
                onChange={props.setInput}
                stateKey={`${props.arrayPoint}.lastName`}
                value={props.lastName}
                className={props.badFields.includes('otherParents[0].lastName') ? 'error-field' : ''}
                placeholder="Last"
                required={true}
              />
            </Col>
            <Col span={24} md={8}>
              <Select
                notFoundContent="Not Found"
                showSearch
                onSelect={(value) => {
                  props.setInput(`${props.arrayPoint}.relationship`, value)
                }}
                style={{ width: '100%' }}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={props.relationship.length > 1 ? props.relationship : 'Relationship'}
                placeholder="Relationship"
                optionFilterProp="children"
              >
                <Select.Option value={'father'}>Father</Select.Option>
                <Select.Option value={'mother'}>Mother</Select.Option>
                <Select.Option value={'other'}>Other</Select.Option>
              </Select>
              {props.relationship === 'other' && (
                <div>
                  <Asterix />
                  <Input
                    onChange={props.setInput}
                    stateKey={`${props.arrayPoint}.relationType`}
                    value={props.relationType}
                    placeholder="Relationship Type"
                    required={true}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={24} md={12}>
          <div className={s.formTitle}>Contact</div>
          <Row gutter={16}>
            <Col span={24} md={16}>
              <Asterix />
              <Input
                type="tel"
                mask="+1(999)999-9999"
                stateKey={`${props.arrayPoint}.primaryPhone`}
                value={props.primaryPhone}
                onChange={props.setInput}
                placeholder="Primary Phone ex: +1 (555) 555-5555"
                required={true}
                validate="isNumeric"
              />
            </Col>
            <Col span={24} md={8}>
              <Asterix />
              <Input
                onChange={props.setInput}
                stateKey={`${props.arrayPoint}.email`}
                className={props.badFields.includes('otherParents[0].email') ? 'error-field' : ''}
                value={props.email}
                placeholder="Email"
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} md={12}>
              <Input
                type="tel"
                mask="+1(999)999-9999"
                stateKey={`${props.arrayPoint}.secondaryPhone`}
                value={props.secondaryPhone}
                onChange={props.setInput}
                placeholder="Secondary Phone"
              />
            </Col>
            <Col span={24} md={12}>
              <Input
                type="tel"
                mask="+1(999)999-9999"
                onChange={props.setInput}
                stateKey={`${props.arrayPoint}.tertiaryPhone`}
                value={props.tertiaryPhone}
                placeholder="Tertiary Phone"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} md={14}>
          <Row>
            <div className={s.formTitle}>Address</div>
          </Row>
          <Row gutter={16}>
            <Col span={24} md={24}>
              <Asterix />
              <Input
                onChange={props.setInput}
                stateKey={`${props.arrayPoint}.address`}
                value={props.address}
                placeholder="Address"
                required={true}
              />
            </Col>
            <Col span={24} md={24}>
              <Input
                onChange={props.setInput}
                stateKey={`${props.arrayPoint}.secondaryAddress`}
                value={props.secondaryAddress}
                placeholder="Unit Number (if applicable)"
              />
            </Col>
            <Col span={24} md={8}>
              <Asterix />
              <Input
                mask="a9a 9a9"
                stateKey={`${props.arrayPoint}.postalCode`}
                onChange={(key, value) => props.setInput(key, value.toUpperCase())}
                value={props.postalCode}
                className={props.badFields.includes('otherParents[0].postalCode') ? 'error-field' : ''}
                placeholder="Postal Code"
                required={true}
                validate="matches"
                validateArgs={[/^[A-Z][0-9][A-Z] [0-9][A-Z][0-9]$/]}
              />
            </Col>
            <Col span={24} md={8}>
              <Asterix />
              <Input
                onChange={props.setInput}
                stateKey={`${props.arrayPoint}.city`}
                value={props.city}
                placeholder="City"
                required={true}
              />
            </Col>
            <Col span={24} md={8}>
              <Asterix />
              <Select
                notFoundContent="Not Found"
                showSearch
                onSelect={(value) => {
                  props.setInput(`${props.arrayPoint}.province`, value)
                }}
                style={{ width: '100%' }}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={props.province && props.province.length > 1 ? props.province : 'Province'}
                placeholder="Province"
                optionFilterProp="children"
              >
                {props.provinces &&
                  props.provinces.map((province, index) => {
                    return (
                      <Select.Option key={`${province}`} value={province}>
                        {province}
                      </Select.Option>
                    )
                  })}
              </Select>
              {/* <Input onChange={props.setInput}
                  stateKey={`${props.arrayPoint}.province`}
                  value={props.province}
                  placeholder="Province" /> */}
            </Col>
          </Row>
        </Col>
        <Col span={24} md={{ span: 8, offset: 2 }}>
          <div className="hide-on-mobile">
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
          <Row type="flex" align="middle">
            <Col span={6} md={12}>
              <Checkbox
                onChange={(e) => {
                  props.setInput(`${props.arrayPoint}.preferred`, e.target.checked)
                }}
                checked={props.preferred}
              >
                Preferred Parent
              </Checkbox>
              <br />
              <br />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title="Click to remove">
                  <Icon onClick={(e) => props.removeParent(props.arrayPoint)} className={s.removeParent} type="close" />
                </Tooltip>{' '}
                <span style={{ fontSize: '14px', marginLeft: '4px' }}> Remove this parent</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
      <br />
    </div>
  )
}

const AwardForm = (props) => {
  return (
    <div className={s.root}>
      <Row>
        <Col span={8}>
          <Row gutter={16}>
            <Col span={16}>
              <Input
                placeholder={this.props.firstInput.placeholder}
                value={this.state.inputFirst ? this.state.inputFirst : ''}
                onChange={(e) => this.handleInputChange('first', e)}
              />
            </Col>
            <Col span={4}>
              <Input
                placeholder={this.props.secondInput.placeholder}
                value={this.state.inputSecond ? this.state.inputSecond : ''}
                onChange={(e) => this.handleInputChange('second', e)}
              />
            </Col>
            <Col span={4}>
              <Button type="primary" shape="circle" icon="plus" onClick={this.handleBtnClick} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

class MultiParent extends React.Component {
  constructor(props) {
    super(props)
    this.addParent = this.addParent.bind(this)
    this.setInput = this.setInput.bind(this)
    this.removeParent = this.removeParent.bind(this)
  }
  componentDidMount() {}

  setInput(arrayPoint, value) {
    const otherParents = _.cloneDeep(this.props.otherParents)
    if (_.includes(arrayPoint, 'preferred') && value === true) {
      otherParents.forEach((parent, index) => {
        if (index !== Number(arrayPoint.charAt(0))) {
          parent.preferred = false
        }
      })
    }
    _.set(otherParents, arrayPoint, value)
    this.props.setInput('otherParents', otherParents)
  }

  removeParent(arrayPoint) {
    let otherParents = _.cloneDeep(this.props.otherParents)
    otherParents.splice(arrayPoint, 1)
    this.props.setInput('otherParents', otherParents)
  }
  addParent() {
    let otherParents = _.cloneDeep(this.props.otherParents)
    otherParents.push({
      firstName: '',
      lastName: '',
      relationship: '',
      primaryPhone: '',
      address: '',
      secondaryAddress: '',
      city: '',
      postalCode: '',
      province: '',
      secondaryPhone: '',
      tertiaryPhone: '',
      preferred: false,
    })
    this.props.setInput('otherParents', otherParents)
  }
  render() {
    return (
      <div>
        {this.props.otherParents.length >= 1 &&
          this.props.otherParents.map((parent, index) => {
            return (
              <MultiParentForm
                provinces={this.props.provinces}
                removeParent={this.removeParent}
                setInput={this.setInput}
                badFields={this.props.badFields}
                arrayPoint={index}
                key={index}
                {...parent}
              />
            )
          })}
        <Row gutter={16}>
          <Col span={24} sm={5} md={4}>
            <a onClick={this.addParent} className="button secondary" type="default">
              + Add Parent
            </a>
          </Col>
        </Row>
      </div>
    )
  }
}

class Double extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inputFirst: null,
      inputSecond: null,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleBtnClick = this.handleBtnClick.bind(this)
  }
  handleInputChange() {}
  handleBtnClick() {}
  render() {
    return (
      <div className={s.root}>
        <Row>
          <Col span={this.props.colSpan}>
            <Row gutter={16}>
              <Col span={16}>
                <Input
                  placeholder={this.props.firstInput.placeholder}
                  value={this.state.inputFirst ? this.state.inputFirst : ''}
                  onChange={(e) => this.handleInputChange('first', e)}
                />
              </Col>
              <Col span={4}>
                <Input
                  placeholder={this.props.secondInput.placeholder}
                  value={this.state.inputSecond ? this.state.inputSecond : ''}
                  onChange={(e) => this.handleInputChange('second', e)}
                />
              </Col>
              <Col span={4}>
                <Button type="primary" shape="circle" icon="plus" onClick={this.handleBtnClick} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
  handleInputChange(type, event) {
    let update = event.target.value
    console.log(update)
    if (type === 'first') {
      this.setState({
        inputFirst: update,
      })
    } else {
      this.setState({
        inputSecond: update,
      })
    }
  }

  handleBtnClick() {
    if (this.state.inputFirst) {
      this.props.btnCb(this.state.inputFirst, this.state.inputSecond)
    }
  }
}

Double.propTypes = {
  btnCb: PropTypes.func,
  colSpan: PropTypes.number,
}

const MultiInput = {
  Double,
  MultiParent,
}

export default MultiInput
