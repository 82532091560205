import { Col, Row } from 'antd'
import _ from 'lodash'
import React from 'react'
import { ContextWrapper } from '../ContextWrapper'
import __ from './../components/Common'
import DataTable from './../components/DataTable'



class VideosAdmin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cols: ["id", "name", "description", "url_id", "position", "modUser"],
    }
    this.generateVideoTableData = this.generateVideoTableData.bind(this)
  }


  /***********
  TABLE METHODS:
  ***********/

  generateVideoTableData(index) {
    // loop over selected columns to construct table

    let newColumns = this.state.cols.map((item, index) => {
      switch (item) {
        case 'id':
          return {
            Header: 'ID:',
            accessor: item,
            width: 50,
            Cell: (row) => (
              <a className="admin-link" href={`/admin_videos/${row.value}/edit`}>
                {row.value}
              </a>
            ),
            sortMethod: (a, b) => {
              return b > a ? 1 : -1
            },
          }
        case 'name':
          return {
            Header: 'Video Title:',
            accessor: item
          }
        case 'description':
          return {
            Header: 'Video description:',
            accessor: item,
          }
        case 'url':
          return {
            Header: "Video URL",
            accessor: item,
          }
        case 'position':
          return {
            Header: "Position",
            accessor: item,
            width: 75,
            sortMethod: (a, b) => {
              return b > a ? 1 : -1
            },
          }
        case 'modUser':
          return {
            Header: '',
            accessor: 'profile_no',
            width: 120,
            Cell: (row) => (
              <div>
                <span>
                <a
                  onClick={(e) =>
                    __.handleMethodClick(
                      e,
                      `/admin_videos/${row.value}`,
                      'delete',
                      'Are you sure you want to delete this video?'
                    )
                  }
                >
                  Delete
                </a>&nbsp;&nbsp;
                </span><span>
                <a className="admin-link" href={`/admin_videos/${row.value}/edit`}>
                  Edit
                </a></span>
              </div>
            ),
          }
        default:
          return {
            Header: _.startCase(item),
            accessor: item,
          }
      }
    })

    // loop over profiles to generate rows in table.
    // some rows require data to be formatted in specific ways in switch statement
    let newRows = this.props.videos.map((video, index) => {
      let videoObj = {}
      this.state.cols.forEach((column, index) => {
        switch (column) {
          case 'modUser':
            videoObj['profile_no'] = video.id
            break
          default:
            videoObj[column] = video[column]
            break
        }
      })

      return videoObj
    })
    // console.log('newRows: ', newRows)
    return [newColumns, newRows][index]
  }

  render() {
    const { current_user, videos, title, text, noticeText } = this.props

    let rows = this.generateVideoTableData(1)
    let columns = this.generateVideoTableData(0)
    // console.log(columns)
    return (
      <ContextWrapper>
        <div>
          <div className="currentUserInfo">
            <p>
              <strong>
                Hello {current_user.first_name} {current_user.last_name}!
              </strong>
              <br />
              <br />
              {text}
              <br />
              <br />
              {noticeText && 
              <div>
                <strong> {noticeText} </strong>
              </div>}
            </p>
          </div>
          <br />
          <br />
          {videos && (
            <div>
              <Row type="flex" justify="space-between">
                <Col>
                  <h2 className="admin">{title}</h2>
                </Col>
                <Col>
                  <a style={{ marginRight: '10px' }} href={this.props.paths.newVideo}>
                    New Video
                  </a>
                </Col>
              </Row>
              
              <DataTable
                defaultPageSize={15}
                onSortedChange={(sorted) => console.log(sorted)}
                defaultSorted={[{ id: 'id', desc: true }]}
                columns={columns}
                rows={rows}
                filterable={true}
              />
            </div>
          )}
        </div>
      </ContextWrapper>
    )
  }
}

export default VideosAdmin
