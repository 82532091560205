import { Button, Col, Input, Radio, Row, Spin } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import __ from './../components/Common'
import DataTable from './../components/DataTable'
import WysiwygInput from '../components/WysiwygInput'
import { ContextWrapper } from '../ContextWrapper'
class AdminDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      siteSettings: null,
      settingsUpdating: false,
    }
  }

  generatePageIndexTableData(index) {
    const columns = [
      {
        Header: 'Page Title',
        accessor: 'title',
      },
      {
        Header: 'Page Slug',
        accessor: 'slug',
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: (row) => (
          <div>
            <a href={`pages/${row.value}/edit`} className="admin-link">
              Edit
            </a>{' '}
            •{' '}
            <a href={`/${row.value}`} className="admin-link">
              View
            </a>{' '}
            •{' '}
            <a
              onClick={(e) =>
                __.handleMethodClick(
                  e,
                  `pages/${row.value}.json`,
                  'delete',
                  'Are you sure you want to delete this page?'
                )
              }
            >
              Delete
            </a>
          </div>
        ),
      },
    ]
    const rows = this.props.pages.map((p, index) => {
      let created = new Date(p.created_at)
      return {
        title: p.title,
        slug: p.slug,
        id: p.slug ? p.slug : p.id,
        created_at: created.toDateString(),
      }
    })
    return [columns, rows][index]
  }

  setSiteSetting(key, value) {
    let settings = _.cloneDeep(this.state.siteSettings)
    let toUpdate = settings.find(function (obj) {
      return obj.item === key
    })
    toUpdate.value = value
    this.setState({
      siteSettings: settings,
    })
  }

  handleSettingsUpdate(settingId) {
    this.setState({
      settingsUpdating: true,
    })
    const config = {
      headers: __.getHeaders(),
    }
    const toUpdate = this.state.siteSettings.find(function (obj) {
      return obj.id === settingId
    })

    const body = {
      value: toUpdate.value,
    }
    axios
      .put(`/site_settings/${settingId}.json`, body, config)
      .then((response) => {
        this.setState({
          settingsUpdating: false,
        })
      })
      .catch(function (err) {
        throw new Error(err.message)
        this.setState({
          settingsUpdating: false,
        })
      })
  }

  componentDidMount() {
    const site_settings = _.cloneDeep(this.props.site_settings)
    this.setState({
      siteSettings: site_settings,
    })
  }

  render() {
    const { current_user, pages, users } = this.props
    const orderedSettings = this.state.siteSettings
      ? [
          this.state.siteSettings.find(({ item }) => item === 'registration'),
          this.state.siteSettings.find(({ item }) => item === 'registrationOffText'),
          ...this.state.siteSettings.filter(({ item }) => !['registrationOffText', 'registration'].includes(item)),
        ]
      : []

    return (
      <ContextWrapper>
        <div>
          <div className="currentUserInfo" style={{ marginTop: '100px' }}>
            <p className="admin-helper-text">
              <strong>
                Hello {current_user.first_name} {current_user.last_name}!
              </strong>
              <br />
              <br />
              This is your Admin Dashboard. You can approve drafts, view user info and create and edit pages here.{' '}
              <br />
              You can also turn guardian and profile registration on/off below:
            </p>
          </div>
          <br />
          <br />
          {orderedSettings.length > 0 && (
            <div>
              {this.state.settingsUpdating && (
                <Row type="flex" justify="center">
                  <Col>
                    <Spin />
                  </Col>
                </Row>
              )}
              {orderedSettings.map((setting, index) => {
                if (
                  setting.item === 'registrationOffText' &&
                  orderedSettings.find(({ item }) => item == 'registration').value === 'on'
                )
                  return null
                return (
                  <div key={`setting-${index}`}>
                    <Row gutter={16} type="flex" align="middle" key={`setting-${index}`}>
                      <Col span={4}>
                        <p className="admin-helper-text" style={{ fontSize: '19px' }}>
                          <strong>{_.startCase(setting.item)}:</strong>
                        </p>
                      </Col>
                      <Col span={10}>
                        {setting.item == 'registration' ? (
                          <div>
                            <Radio.Group
                              onChange={(e) => this.setSiteSetting(setting.item, e.target.value)}
                              value={setting.value}
                            >
                              <Radio value={'on'}>on</Radio>
                              <Radio value={'off'}>off</Radio>
                            </Radio.Group>
                          </div>
                        ) : setting.item == 'registrationOffText' ? (
                          <WysiwygInput
                            onChange={(newValue) => this.setSiteSetting(setting.item, newValue)}
                            value={setting.value}
                          />
                        ) : (
                          <Input
                            style={{ marginBottom: '0px' }}
                            value={setting.value}
                            onChange={(e) => this.setSiteSetting(setting.item, e.target.value)}
                          />
                        )}
                      </Col>

                      <Col span={4}>
                        <Button
                          type="primary"
                          onClick={(e) => {
                            this.handleSettingsUpdate(setting.id)
                          }}
                        >
                          Update
                        </Button>
                      </Col>
                    </Row>
                    <br />
                    <br />
                  </div>
                )
              })}
              <br />
              <br />
              <hr />
            </div>
          )}

          {pages && (
            <div>
              <hr />
              <h2 className="admin">Pages</h2>
              <DataTable
                defaultPageSize={10}
                columns={this.generatePageIndexTableData(0)}
                rows={this.generatePageIndexTableData(1)}
                filterable={true}
              />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          )}
        </div>
      </ContextWrapper>
    )
  }
}

export default AdminDashboard
