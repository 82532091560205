import React from 'react'
import { ContextWrapper } from '../ContextWrapper'
import Breadcrumbs from './../components/Breadcrumbs'
import Header from './../components/Header/'

class SiteHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <ContextWrapper>
        <div>
          <Header {...this.props} />
          <Breadcrumbs />
        </div>
      </ContextWrapper>
    )
  }
}

export default SiteHeader
