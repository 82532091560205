import React from 'react'
import { ContextWrapper } from '../ContextWrapper'
import PageEditor from './../components/PageEditor/'

class PageBuilder extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { components, id, title } = this.props
    return (
      <ContextWrapper>
        <div className="PageBuilder">
          <PageEditor pageId={id} title={title} components={components} />
        </div>
      </ContextWrapper>
    )
  }
}

export default PageBuilder
