import { Col, Row } from 'antd'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import backgroundImage from './background.jpg'
import s from './style.module.scss'

class FeaturedImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    // console.log(this.props)
    let img =
      this.props.image && this.props.image.length > 0 && this.props.image[0].url ? this.props.image[0].url : false
    return (
      <div className={s.root} style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="container">
          <Row type="flex" justify="center" align="middle">
            <Col xs={24} sm={10}>
              <div className={s.featuredImageContainer}>
                {img && <img className={s.featuredImg} src={img} alt="" />}
              </div>
            </Col>
            <Col className={s.content} xs={24} sm={14}>
              <h1 className={s.featuredTitle}>{this.props.title}</h1>
              <h3 className={s.featuredSubitle}>{this.props.subtitle}</h3>
              <p className={s.featuredText} dangerouslySetInnerHTML={{ __html: this.props.text }}></p>
              <a className={cx('button secondary', s.featuredBtn)} href={this.props.btnLink}>
                {this.props.btnText}
              </a>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

// FeaturedImage.defaultProps = {
//   title: 'Feature Image'
// }

FeaturedImage.propTypes = {
  title: PropTypes.string,
  image: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    })
  ),
  subtitle: PropTypes.string,
  text: PropTypes.string,
  backgroundImage: PropTypes.string,
  btnText: PropTypes.string,
  btnLink: PropTypes.string,
}

export default FeaturedImage
